/*
* VARIABLES
*/

$white: #ffffff;
$purple01: #564FDB;
$purple02: #6472C7;
$purple03: #A778FF;
$purple04: #D4BDFF;

/*
* MIXINS
*/

@mixin hideAnchorText {
	text-indent: -9999px;
  overflow: hidden;
}

/*
* TYPOGRAPHY
*/

.heading1 {
  font-size: 32px;
  line-height: 52px;
  font-weight: bold;
  &.larger {
    font-size: 42px;
    line-height: 52px;
    font-weight: bold;
  }
}

.heading1-center {
  font-size: 32px;
  line-height: 52px;
  font-weight: bold;
  text-align: center;
  &.larger {
    font-size: 42px;
    line-height: 52px;
    font-weight: bold;
  }
}

.heading2 {
  font-size: 24px;
  line-height: 38px;
  font-weight: bold;
}

.heading3 {
  font-size: 22px;
  line-height: 36px;
  font-weight: bold;
}

.heading4 {
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
}

.heading5 {
  font-size: 16px;
  line-height: 24px;
	font-weight: 600;
	margin-top: 20px;
}

.list-decimal {
	list-style:decimal;
	margin: 0 0 25px 0;
	li {
		list-style:decimal;
	}
}

.box-starting-guide {
	padding:25px 0 0 0;
	border-style:solid;
	border-width:1px;
}

.normal {
	font-weight: normal;
}

.text-white {
	color: $white;
	&:hover, &:focus {
		color: $white;
	}
}

.capitalize {
	text-transform: capitalize;
}
/*
* BASE
*/

.img-responsive {
  margin: 0 auto;
  text-align: center;
}

.no-padding {
  padding: 0;
}

.header {
  margin-top: 24px;
  margin-bottom: 90px;
}

.blue-bg {
  background-color: #4388DD;
}

.grey-bg {
  background-color: #707070;
}

.header #logo {
  color: #FFFFFF;
  float: left;
  /*padding: 15px 0;*/
  margin-left: 20px;
}

.navbar-brand, footer #logo a {
  background: url(../img/public/logo-black.png) no-repeat;
  width: 202px;
  height: 38px;
  text-indent: -9999px;
  display: block;
  opacity: 1;
  filter: alpha(opacity = 100);
  -webkit-transition: opacity 0.25s ease-in-out 0s;
  -moz-transition: opacity 0.25s ease-in-out 0s;
  -o-transition: opacity 0.25s ease-in-out 0s;
  transition: opacity 0.25s ease-in-out 0s;
}
.hide {
	display: none;
}

.whiteFont {
  color: #fff;
}

.uppercase {
	text-transform: uppercase;
}

.bg-white {
	background-color: $white;
}

.bg-gray {
  background-color: #F6F6F6;
}

.color-grey {
	color: #BDBDBD;
}

.underline {
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: #fff;
		text-decoration: underline;
	}
}

.bordered {
	border: 1px solid #AFAFAF;
}

.sm-radius {
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}

.fontS28 {
  font-size: 28px;
}

.fontS20{
	font-size: 20px;
}

.fontS18 {
  font-size: 18px;
}

.fontS17 {
  font-size: 17px;
}

.fontS15 {
  font-size: 15px;
}

.fontS13 {
  font-size: 13px;
}

.allb6 {
  color: #b6b6b6;
}

.posRelative {
  position: relative;
}

.interviewMobile {
  display: none;
}

.full-rounded {
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.medium-rounded {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
}

.small-rounded {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

/*
* BUTTONS
*/

.btn-dev-login {
	padding: 10px 50px;
	color:white;
	background: #5F5F5F;
}

.btn-load-more {
	background: #6472c7;
	color: #fff;
	&:hover {
		background: #5461b6;
    color: #fff;
	}
}

.btn-md-signup {
	border-radius: 1000px;
  background: #6472c7;
  color: $white;
	font-size: 18px;
  padding: 7px 40px;
  line-height: 33px;
	text-transform: uppercase;
  &:hover, &:focus {
    background: #7481d2;
    color: #ffffff;
  }
}

.btn-demo {
	border-radius: 1000px;
	background: transparent;
	color: #333;
	font-size: 18px;
  padding: 7px 40px;
  line-height: 33px;
	border: 1px solid #333;
	text-transform: uppercase;
	&:hover, &:focus {
		background: #333;
		color: $white;
	}
}

/*
* NAVIGATIONS
*/

.navbar-toggle {
  margin-top: 2px;
  margin-bottom: 2px;
}

.nav > li > a {
  &:focus, &:hover {
    background: transparent;
    color: #fff;
  }
}

.navbar-collapse {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.navbar-nav li a.btn-signup {
  border-radius: 1000px;
	line-height: 38px;
  background: #6472c7;
  color: #fff;
  font-size: 13px;
  letter-spacing: 2px;
  padding: 0px 20px;
  font-weight: bold;
	text-transform: uppercase;
  &:active {
    background: #5461b6 !important;
  }
  &:hover {
    background: #7481d2;
    color: #ffffff;
  }
}

#mobile-nav {
  display: none;
}

.menu-nav {
  background: url('../img/public/menu-mobile.png') 0 0 no-repeat;
  width: 16px;
  height: 16px;
  display: block;
  /*margin: 22px 0 0 0;*/
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 250ms ease-out 0s;
  -moz-transition: -moz-transform 250ms ease-out 0s;
  -o-transition: -o-transform 250ms ease-out 0s;
  transition: transform 250ms ease-out 0s;
}

.navbar-toggle .icon-bar {
  background-color: #fff;
}

.nav-tabs.nav-justified.nav-feature {
	margin-bottom: 0px;
	> li > a {
		border: 0;
		background: transparent;
		font-size: 28px;
		text-transform: uppercase;
		line-height: 38px;
		color: inherit;
	}
	> .active > a {
		border: 0;
		color: $white;
		&:hover, &:focus {
			border: 0;
			color: $white;
		}
	}
}

.headContainer {
  background: #212528 url('../img/public/headerBG.png') no-repeat;
  min-height: 335px;
  color: #b6b6b6;
}

.headTeamContainer {
  background: #212528 url('../img/public/headerTeamBG.png') no-repeat;
  min-height: 562px;
}

.headContainerWhite {
	background-color: #FFFFFF;
	li.lang-switcher {
		> a {
			display: inline-block;
			color: #4388DD !important;
			text-decoration: underline;
			margin: 0 5px;
			&.active {
				color: #343434;
				text-decoration: none;
				font-weight: 600;
			}
		}
	}
  .header {
    margin: 24px 0;
  }
}

.headContainer {
	background: #212528;
	&.black {
		padding-bottom: 0;
		min-height: 0;
		.header {
			margin-top: 24px;
			margin-bottom: 0;
		}
	}
}

.headContainerTestPage {
	background: #564FDB 0% 0% no-repeat padding-box;
	box-shadow: 0px 5px 5px #0000000D;
	opacity: 1;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	&.black {
		padding-bottom: 0;
		min-height: 0;
		.header {
			margin-top: 24px;
			margin-bottom: 0;
		}
	}
}

/*
* ELEMENTS
*/

.img-container img {
	max-height: 60px;
	max-width: 160px;
}

.img-container-long img {
	max-height: 124px;
	max-width: 190px;
}

.header-career {
	padding: 20px 0;
	background-color: $purple01;
	color: $white;
	&.bg-repeat-round {
		background-repeat: round !important;
	}
}

.vertical-form {
	label, p {
		display: block;
	}
	label {
		font-size: 12px;
		font-weight: bold;
    color: #999999;
		margin-bottom: 5px;
    b {
      font-weight: bold;
      color: #564FDB;
    }
	}
	input[type="text"], textarea {
		font-size: 16px;
		padding: 13px 15px 15px 15px;
		display: block;
		width: 100%;
    color: #666666;
		border: 1px solid #999999;
    border-radius: 5px;
		&:focus {
			outline: none;
      border: 1px solid #564FDB;
      box-shadow: 0 0 10px #564FDB;
		}
	}
	.form-group {
		margin-bottom: 24px;
	}
}

input[type="text"], textarea::-webkit-input-placeholder,
input[type="text"], textarea::-moz-placeholder,
input[type="text"], textarea:-ms-input-placeholder,
input[type="text"], textarea::-ms-input-placeholder,
input[type="text"], textarea:-moz-placeholder {
  color: #CCCCCC !important;
}

.label-click-continue {
  font-size: 12px;
  color: #666666;
}

.rh5v-Overlay_inner {
  background-color: rgba(86, 79, 219, .75);
  border-radius: 50px;
  height: 100px;
  width: 100px;
}

.registration {
	padding: 20px;
	background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #E5E5E5;
}

.animate-all {
	transition: all 4s linear;
}

/*
* CUSTOMS
*/
.border-left-clear {
	border-left: 0 !important;
}

.border-radius-time {
	border-radius: 4px !important;
	border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-left: 0;
}

.border-radius-field {
	border-radius: 4px !important;
}

.border-right-solid {
	border-right: 1px solid #BDBDBD !important;
}

.menu-text-decoration-dev {
	border-bottom: 1px solid $white;
}

.dev-asrnt-custom {
  background: url('/assets/img/dev-astronaut/developer-astronaut-logo.png') no-repeat;
  width: 211px;
  height: 51px;
}

.test-page-custom-header-logo {
  background: url('/assets/img/astronaut/astronaut-logo-square-white-text.png') no-repeat;
  width: 201px;
	height: 51px;
	position: absolute;
	display: block;
	transform: translate(-50%);
	left: 50%;
}

.public-web-navbar > li > a {
  margin-left: 36px;
}

.dev-astrnt-white-box > li > a {
  color: #333333 !important;
}

.dev-astrnt-white-box > li > a:hover {
  color: #343434 !important;
}

.dev-astrnt-table tr th {
  background-color: #564FDB;
  color: $white;
}

.haveacode {
	border-bottom: 1px solid $white;
}

.noDecoration {
  text-decoration: none;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

.slider {
  width: 100%;
  margin: 0px;
}

.slick-slide {
  margin: 0px 20px;
}

.slider .container {
  text-align: center;
  line-height: 1.5em;
  padding-top: 44px;
  /*padding-bottom: 22px;*/
  font-size: 18px;
  color: #909499;
  &.slick-active {
    color: #222a34 !important;
  }
}

.slick-dots {
  display: block;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 10px;
  padding: 0;
  li {
    display: inline-block;
    margin-right: 7px;
    a {
      color: #bfbfbf;
      text-decoration: none;
    }
    &.slick-active a {
      color: #d6d6d6;
      text-decoration: none;
      &:hover {
        color: #d6d6d6;
        text-decoration: none;
      }
    }
  }
}

.footerSeparator {
  border-top: 1px solid #474747;
  margin: 40px 0 34px;
}

.price {
  color: #222a34;
  font-size: 48px;
}

.month {
  color: #727c88;
}

.gold, .silver, .bronze {
  background: #fff;
  border-radius: 5px;
  margin-right: 5%;
  padding: 35px 20px;
  width: 29.666666%;
  text-align: center;
}

.gold a, .silver a, .bronze a {
  font-weight: 700;
  padding: 16px 28px;
  background: #6472c7;
  border-radius: 1000px;
  color: #fff;
  margin-top: 30px;
  display: inline-block;
  text-decoration: none;
}

.gold a:hover, .silver a:hover, .bronze a:hover {
  text-decoration: none;
}

.packageName {
  color: #222a34;
}

.help-codes {
  margin-bottom: 60px;
}

.company-size {
  color: #9c9c9c;
	option:checked {
		color: #9c9c9c;
	}
}

/*
* PAGES
*/

.faq-page {
  .navbar-brand {
    background: url(../img/public/logo-black.png) no-repeat;
    background-size: 100%;
  }
  .panel-white {
		h4 {
			margin: 0;
			.numbers {
				font-style: normal;
				font-size: 14px;
			}
		}
    ul > li:before {
      content: "-";
      text-indent: -5px;
    }
    ol li {
      list-style: disc;
    }

  }
	.panel-title h4:after {
	    font-family: FontAwesome;
	    content: "\f106";
	    color: grey;
			margin-top: 10px;
			position: absolute;
			top: 50%;
			right: 15px;
			margin-top: -7.5px;
	}
	.panel-title.collapsed h4:after {
	    content: "\f107";
	}
	.panel-group {
		&.faq-accordion {
			.panel-heading {
				border-bottom: 0;
		    position: relative;
		    min-height: 57.33px;
				h4 {
					padding: 10px 15px 0 45px;
					line-height: normal;
					span {
						position: absolute;
						top: 10px;
						left: 15px;
					}
				}
			}
			.panel-heading + .panel-collapse > {
				.panel-body {
					line-height: 2em;
					border-top: 0;
				}
			}
		}
	}
	.faq-note {
		margin: 3em 0 7em;
		font-size: 1.2em;
	}
}

.developer-astronaut {
  color: #343434;
}

.dev-astrnt-font-white {
  color: $white;
}

#greatTool {
  background: #212528;
  color: #b6b6b6;
  padding-top: 48px;
  padding-bottom: 60px;
}

#getInterview {
  color: #4a4a4a;
  background: #fff url('../img/public/basePattern.png') no-repeat center;
  padding: 64px 0;
}

#allplanincluded {
  background: #fff url('../img/public/basePattern.png') no-repeat center;
  padding: 64px 0;
	.checked {
	  background: url('../img/public/checked.png') no-repeat;
	  line-height: 20px;
	  font-size: 15px;
	  color: #565656;
	  padding-left: 30px;
	  margin-bottom: 25px;
	}
}

#beforeFooter {
  background: #6472c7 url('../img/public/beforeFooter.png') no-repeat center;
  min-height: 256px;
  padding-top: 50px;
}

.googleplay {
  background: url('../img/public/googleplay.png') no-repeat;
  width: 125px;
  height: 38px;
  display: inline-block;
  margin-right: 10px;
	@include hideAnchorText;
}

.appstore {
  background: url('../img/public/appstore.png') no-repeat;
  width: 107px;
  height: 38px;
  display: inline-block;
	@include hideAnchorText;
}

footer.developer {
  background: #151b23;
  padding-top: 44px;
  padding-bottom: 36px;
  h3 {
    margin: 0px;
    line-height: 38px;
    font-weight: bold;
    color: #fff;
  }
  .col-md-2 a {
    color: #969a9f;
    text-decoration: none !important;
    font-size: 15px;
    &:hover {
      color: #6472c7;
    }
  }
}

.teamContainer {
	width: 240px;
	position:relative;
	border-radius: 5px;
	color: #7c7c7c;
	text-align: center;
	display: inline-block;
	margin-bottom: 25px;
	overflow: hidden;
	.board-img {
		position: relative;
		display: block;
	}
	.board-name {
		width: 100%;
		position: absolute;
		background: rgba(14, 14, 14, 0.77);
		color: #fff;
		opacity: 0;
	  bottom: -100px;
		-webkit-transition: all 0.8s ease;
	  -moz-transition:    all 0.8s ease;
	  -o-transition:      all 0.8s ease;
	}
	.avatar {
		width: 240px;
		position:absolute;
		-webkit-transition: opacity 0.8s ease-in-out;
		-moz-transition: opacity 0.8s ease-in-out;
		-o-transition: opacity 0.8s ease-in-out;
		transition: opacity 0.8s ease-in-out;
		&.top {
			position: relative;
		}
	}
	.name {
		font-weight: 700;
		font-size: 16px;
	}
	&:hover {
		.avatar.top {
			opacity: 0;
		}
		.board-name {
			opacity: 1;
		  bottom: 0;
		}
	}
}

.teamContainer a {
  display: inline-block;
  margin: 15px 0px;
	&:hover {
		opacity: 0.6;
	}
}

.socialMedia p {
	color: #b6b6b6;
}

.socialMedia a:hover {
  opacity: 0.7;
}

/* FAQ, TOS, PRIVACY POLICY */

.faq-page, .tos-page, .privacy-page {
	background-color: #FFFFFF;
  .navbar-toggle .icon-bar {
    background-color: #8A8A8A;
  }
  .jumbotron {
    padding: 40px 15px;
    h2 {
      color: #FFFFFF;
      line-height: 42px;
      margin: 0;
      font-size: 20pt;
			font-weight: 400;
    }
  }
}

.faq-list h3, .privacy-content h3 {
  font-weight: bold;
  font-size: 22pt;
  line-height: 38px;
  margin-top: 45px;
}

.faq-list ul {
  list-style: none;
  width: 80%;
  margin-top: 30px;
  padding: 0;
}

.privacy-content ul {
  list-style: none;
  width: 80%;
  margin-top: 30px;
  padding: 0;
  width: 100%;
}

.terms-content ul {
  list-style: none;
  margin-top: 30px;
}

.faq-list ul li {
  border-bottom: 1px solid #6361A7;
  line-height: 60px;
  color: #37474f;
  font-size: 17pt;
}

.privacy-content, .terms-content {
  margin-bottom: 65px;
}

.privacy-content ul li, .terms-content ul li {
  border-bottom: 0;
  line-height: 1.57142857;
  color: #37474f;
  font-size: 14pt;
  text-align: justify;
  margin-bottom: 20px;
}

.faq-list p {
  font-size: 14pt;
  color: #37474f;
  margin-top: 45px;
}

.privacy-content p, .terms-content p {
  font-size: 14pt;
  color: #37474f;
  text-align: justify;
  margin-top: 25px;
	line-height: 24px;
}

.privacy-content .main-point {
	padding-left: 1.5em;
}

.privacy-content ol li {
	list-style: disc;
}

.privacy__list li b {
  padding: 15px 0 10px;
  display: block;
}

.terms__list {
  li > {
    b {
      padding: 15px 0 10px;
    }
    p.main-text {
      padding-left: 30px;
    }
  }
  .break-number {
    width: 4%;
  }
  .break-text {
    width: 96%;
  }
}

.faq-feature {
  width: 95%;
  margin: 0 -10px 120px;
}

.faq-page h2 span {
  display: block;
}

.faq-feature {
  .boxes {
    border: 1px solid #6361A7;
    padding: 0 40px;
    width: 48%;
    margin: 10px;
    border-radius: 3px;
    min-height: 226px;
  }
  p {
    color: #37474f;
    font-size: 18px;
  }
  .boxes {
    .flex-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 50%;
    }
    .flex-between {
      height: 100%;
    }
    img {
      padding: 20px;
    }
    p {
      padding: 10px;
    }
  }
}

.wrapper-career-team {
	.boxes-job {
		background: #151b23;
		padding: 25px 40px;
		margin-bottom: 20px;
		min-height: 240px;
		&.full-width {
			padding: 50px 75px 80px;
		}
		h2 {
			color: #fff;
			font-size: 1.6em;
			margin:0 0 35px;
      font-weight: 600 !important;
			small {
				font-size: 12px;
				color: #fff;
			}
		}
	}
	.desc-job {
		min-height: 190px;
		p {
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&.full-width {
			min-height: 100px;
			p {
				margin: 35px 0 0;
				text-align: justify;
			}
		}
		.loadmore {
			width: 100%;
			align-items: flex-end;
		}
	}
	video::-internal-media-controls-download-button {
    display:none;
	}

	video::-webkit-media-controls-enclosure {
	    overflow:hidden;
	}

	video::-webkit-media-controls-panel {
	    width: calc(100% + 30px); /* Adjust as needed */
	}
}

.modal-transparent {
	.modal-content {
		background-color: transparent;
    border: 0;
    box-shadow: none;
		.modal-header, .modal-footer {
			border: 0;
		}
	}
}

.wrapper-joblist {
	margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}

.job-listing {
  width: 100%;
  padding: 15px 0 0;
  hr {
    margin-top: 0;
  }
  .job-detail {
    width: 100%;
    margin-bottom: 40px;
    color: #515353;
    h2 {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 14px;
    }
    .company-detail {
      padding: 10px 30px;
      .logo {
        img {
					text-align: left;
				}
        .company-logo {
          max-height: 75px;
          width: auto;
					margin-left: 0;
        }
      }
    }
    .header-detail {
      padding: 0 30px 20px;
      h1 {
        font-weight: 600;
      }
			p {
				margin-top: 10px;
				b {
					margin-right: 20px;
				}
			}
    }
    .body-detail, .requirement-detail, .other-detail {
      padding: 15px 30px 20px;
    }
    .desc-text {
      white-space: pre-wrap;
    }
    .download-box {
      background-color: #F9F9F4;
      max-width: 355px;
      min-height: 236px;
      color: #3E3E3E;
      border: 2px solid #D8D8D8;
      padding: 25px;
      text-align: center;
      margin: 28px auto;
      border-radius: 10px;
      h4 {
        font-weight: bold;
      }
      img {
        max-height: 40px;
      }
    }
    .border-line {
      border-top: 18px solid #404B73;
    }
    .leftbox {
      background-color: #e4eaec;
    }
  }
  .job-footer {
    padding-top: 40px;
    .copy {
      text-align: right;
      padding: 10px 30px;
      background-color: #282828;
      margin: 0 -15px;
      a {
        color: #fff;
      }
    }
  }
}

.job-detail .download-box {
  b {
    font-size: 18px;
    span {
      color: #F16C6C;
    }
  }
  img {
    max-height: 40px;
  }
  .get {
    margin-top: 30px;
  }
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.job-detail .other-detail {
  h2 {
    margin-bottom: 30px;
  }
  .job-list {
    margin-bottom: 20px;
  }
}

.job-footer p {
  float: left;
  padding: 10px 0 0 10px;
}

.job-opening {
  .wrapper-joblist h1 {
    padding-left: 32px;
  }
  .header-detail h1 {
    padding: 15px 0;
  }
}

.openings {
  flex-wrap: wrap;
  .opening-item {
    width: 31%;
    text-align: left;
    padding: 20px 25px 30px;
    margin: 10px;
    h4 {
      color: #4388DD;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
  h1 {
    padding-left: 32px;
  }
}

.small-title {
  font-size: 20px;
  font-weight: 600;
}

.company-page .pagination > {
  li > {
    a, span {
      border: 0;
      color: #374752;
    }
  }
  .active > {
    a, span, a:hover, span:hover, a:focus {
      background-color: transparent;
      color: #6472c7;
      font-weight: bold;
    }
  }
}

.company-page .pagination > .active > span:focus {
  background-color: transparent;
  color: #6472c7;
  font-weight: bold;
}

.company-page .pagination > li > a {
	background-color: transparent;
	color: #374752;
  &:hover, &:focus {
    background-color: transparent;
    color: #6472c7;
    font-weight: bold;
  }
}

.company-page .pagination > li > span {
	background-color: transparent;
	color: #374752;
	&:hover, &:focus {
		background-color: transparent;
    color: #6472c7;
    font-weight: bold;
	}
}

.companies {
  flex-wrap: wrap;
}

.company-list {
  width: 31%;
  text-align: center;
  padding: 20px 5px 30px;
  margin: 10px;
  background-color: rgba(21, 27, 35, 0.02);
  border-radius: 5px;
  h3 {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    margin-top: 20px;
  }
  .logo img {
    max-height: 100px;
    width: auto;
  }
}

#partners {
	background-color: #fff;
  padding: 90px 0 40px;
  h1 {
    margin: 0 auto 40px;
		text-align: center;
  }
}

.demo-request {
  background-color: #fff;
  padding: 90px 0 40px;
	text-align: center;
	h1 {
		font-size: 32px;
		font-weight: bold;
		line-height: 40px;
		padding-bottom: 30px;
	}
	p {
		margin-bottom: 40px;
	}
	&__form {
		max-width: 800px;
	}
	.form-group {
		text-align: left;
	}
}

.page {
  background: #f0f0f0;
  border: 1px solid black;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.company-list img {
  max-width: 100%;
  height: auto;
}

.company-page {
	background-color: #FFFFFF;
	padding: 0;
	.jumbotron h2 {
	   color: #FFFFFF ;
	   line-height: 42px;
	   margin: 0;
	   font-size: 20pt;
		 font-weight: 400;
	}
}

.job-opening {
	background-color: #FFFFFF;
}

.logo-list {
  width: 31%;
  text-align: center;
  padding: 20px 5px 30px;
  margin: 10px;
  img {
    width: 100%;
    max-height: 100px;
    width: auto;
    -webkit-filter: grayscale(90%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(90%);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    &:hover {
      -webkit-filter: grayscale(0);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(0);
    }
  }
}

footer {
	&.listing-footer {
	  background-color: #282828;
	  text-align: center;
	  padding: 15px;
		color: #ffffff;
		p {
			margin: 0;
			padding: 0;
		}
	}
}

.div-apply-now {
  height: 40px;
  // border-bottom: 1px solid #564FDB;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  p {
    font-size: 22px !important;
    color: #564FDB ;
    font-weight: bold !important;
    margin-bottom: 20px !important;
    margin-left: 20px !important;
  }
  hr {
    border: 0.5px solid #564FDB;
    // width: 493px;
    // margin-left: -20px;
    width: 100%;
    margin: auto;
  }
}

.footer-astrnt{
    background: #FFF;
    padding: 20px 0;
    font-size: 12px;
    line-height: 12px;
    font-family: 'OpenSans', Helvetica, Arial, sans-serif;
}

.footer-astrnt section{
    padding: 0 20px;
    text-align: center;
}

.footer-astrnt span{
    float: right;
    font-size: 26px;
    line-height: 30px;
    font-family: 'MaisonNeueExtend', Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.footer-astrnt a{
    margin: 0 10px;
}

.footer-astrnt a > img{
    height: 30px;
}

.video-box {
	background-color: $white;
	margin-bottom: 24px;
	border-radius: 5px;
	video {
		width: 100%;
		border-radius: 10px;
		margin-bottom: -5px;
	}

	video::-internal-media-controls-download-button {
    display:none;
	}

	video::-webkit-media-controls-enclosure {
	    overflow:hidden;
	}

	video::-webkit-media-controls-panel {
	    width: calc(100% + 30px); /* Adjust as needed */
	}
}

.wrapper-overlay {
  .overlay-content {
    color: #FFFFFF;
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
		height: 60vh;
    h1 {
      font-size: 22px;
      font-weight: bold;
    }
    a {
      color: #FFFFFF;
    }
    p {
      font-size: 15px;
    }
    .video-container {
      margin: 20px 0;
    }
  }
}

.modal-entercode {
  .modal-header {
    border-bottom: 0;
  }
  .modal-content {
    background: url(../img/public/img-cloudy.png) no-repeat;
    max-width: 540px;
		height: 302px;
  }
	.modal-body {
		color: #ffffff;
		padding: 45px;
		text-align: center;
	}
}

.form-entercode {
	max-width: 245px;
	margin: 0 auto;
	border-bottom: 1px solid #FFFFFF;
	.input-group-btn button {
		background: transparent;
		border: 0;
		padding: 0;
	}
	.form-control {
		background: transparent;
		border: 0;
		height: 100%;
		font-size: 26px;
		color: #ffffff;
	}
	input::-webkit-input-placeholder,
	input::-moz-placeholder,
	input:-ms-input-placeholder,
	input:-moz-placeholder,
	input  {
	  color: #FFFFFF;
	}
	input:focus {
		box-shadow: none;
	}
}

.icon-arrow-outline {
	background: url(../img/public/arrow-outline.png) no-repeat;
	width: 40px;
	height: 41px;
	display: block;
}

.blog-wrapper {
	background-color: #fff;
	padding: 60px 0;
	.blog-category {
		font-size: 28px;
		text-align: center;
		margin: 20px auto 40px;
		font-weight: 400;
	}
	.blog-box {
		background-color: #ffffff;
		border-radius: 2px;
		display: inline-block;
		position: relative;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
		width: 100%;
		min-height: 460px;
		&:hover {
			box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
		}
		.blog-image {
			max-width: 100%;
			height: auto;
			vertical-align: middle;
			overflow: hidden;
			max-height: 200px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.blog-text {
			text-align: center;
			padding: 0 15px 10px;
			word-wrap: break-word;
			.blog-title {
				font-size: 21px;
				font-weight: 400;
				color: #333;
				margin-bottom: 25px;
				word-wrap: break-word;
			}
			a {
				color: #333;
			}
		}
	}
}




/*
* REDESIGN
*/

.gradient-line {
	border: 0;
	height: 1px;
	background: $white;
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,hsla(0,0%,100%,0)), color-stop(50%,hsla(0,0%,100%,.75)), color-stop(100%,hsla(0,0%,100%,0)));
	background: -webkit-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,.75) 50%, hsla(0,0%,100%,0) 100%);
	background:    -moz-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,.75) 50%, hsla(0,0%,100%,0) 100%);
	background:     -ms-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,.75) 50%, hsla(0,0%,100%,0) 100%);
	background:      -o-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,.75) 50%, hsla(0,0%,100%,0) 100%);
	background:         linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,.75) 50%, hsla(0,0%,100%,0) 100%);
}

.featured-heading {
	line-height: 65px;
  margin-bottom: 75px;
  font-size: 42px;
	color: $white;
	font-weight: 400;
	text-align: center;
	margin-top: 0;
}

.article-heading {
	font-size: 36px;
	padding-left: 20px;
	margin-top: 0;
	color: $white;
}

.feature-box {
	color: $white;
	text-align: center;
	margin-bottom: 100px;
  padding: 0 105px;
	.feature-img {
		height: 135px;
		margin-bottom: 40px;
	}
	.feature-title {
		font-size: 28px;
		line-height: 49px;
		padding-bottom: 30px;
	}
}

.request {
	background-color: $white;
	padding: 96px 0 63px;
}

.test-page-body-content {
	background-color: #E5E5E5;
	padding: 20px 0 63px;
}

.dev-astrnt-body-content {
	background-color: $white;
	padding: 20px 0 63px;
}

.dev-astrnt-sign-up {
	background-color: #f3f3f3;
  padding: 50px 40px 20px;
  border-radius: 8px;
}

.dev-astrnt-nav-box {
  padding: 30px 0px;
}

.dev-astrnt-white-box {
	background-color: #f8f8f8;
}

.li-left-margin {
  margin-left: 20px;
}

.dev-astrnt-content-box {
  padding: 20px 30px;
}

.dev-astrnt-url-btn {
  color: $white;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-right: 20px;
}

.alert-comming-soon {
  color: white;
  background-color: #6472c7;
  border-color: #6472c7;
  a {
    color: white;
    &:hover, &:focus {
      color: #969a9f;
    }
  }
}

.action-box {
	text-align: center;
  padding: 20px 105px;
	&:first-child {
		border-right: 1px solid #828282;
	}
	.action-box-title {
		font-size: 28px;
		line-height: 36px;
		padding-bottom: 30px;
	}
}

.page-heading {
	font-size: 28px;
	color: $white;
	margin-bottom: 50px;
	text-align: center;
	margin-top: 0;
	line-height: 32px;
}

.page-desc {
	line-height: 32px;
	font-size: 18px;
	text-align: center;
}

/*
* Padding & Margin
*/

.m-t-0 {
  margin-top: 0px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-18 {
  margin-top: 18px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-23 {
  margin-top: 23px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-b-20 {
	margin-bottom: 20px;
}

.m-b-44 {
  margin-bottom: 44px;
}

.m-b-62 {
  margin-bottom: 62px;
}

.m-b-64 {
  margin-bottom: 64px;
}

.m-b-80 {
  margin-bottom: 80px;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-r-21 {
  margin-right: 21px;
}

.m-t-64 {
  margin-top: 64px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-120 {
  margin-top: 120px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-18 {
  padding-top: 18px;
}

.p-t-22 {
  padding-top: 22px;
}

.p-t-24 {
  padding-top: 24px;
}

.p-t-26 {
  padding-top: 26px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-46 {
  padding-left: 46px;
}

.p-b-24 {
  padding-bottom: 24px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-66 {
  padding-bottom: 66px;
}

@media (max-width: 400px) {
  #beforeFooter {
    padding: 6px 0 56px;
  }
  .jobseeker {
    display: inline-block;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .employer {
    display: inline-block;
  }
}

/*
* PARTNERS
*/

.partners {
	@at-root #{&}__header {
		background-color: $white;
		padding: 20px 0;
		.region {
			color: #f42434;
			height: 43px;
			line-height: 55px;
		}
	}
	@at-root #{&}__intro {
		background-color: #f42434;
		color: $white;
		padding: 24px 48px 78px;
		.custom-tweaks {
			h1 {
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 30px;
			}
			p {
				font-size: 20px;
				span {
					display: block;
					margin-bottom: 20px;
				}
			}
			ul {
				font-size: 20px;
				margin-left: 40px;
				margin-bottom: 20px;
			}
		}
	}
	@at-root #{&}__content {
		background-color: $white;
		padding: 40px 0;
		min-height: 100vh;
		height: 100%;
		.react-bs-table-container {
			.react-bs-table table th {
				background-color: #DBDBDB;
				color: #333333;
			}
			.react-bs-table table td {
				background-color: $white;
			}
		  .react-bs-table table td, .react-bs-table table th {
		    white-space: pre-line;
		    text-overflow: unset;
		    overflow-wrap: break-word;
		  }
		}
		.button-red {
			background-color: #F42534;
			&:hover {
				background-color: #de1726;
			}
			&:focus {
				background-color: #F42534;
				border-width: 3px;
				border-color: #F42534;
				border-style: solid;
			}
			&:active {
				background-color: #F42534;
				border-color: #3C00AB;
			}
		}
	}
	@at-root #{&}__body {
		background-color: $white;
    border-radius: 5px;
    padding: 40px;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	}
	@at-root #{&}__form {
		.title {
	    display: inline-block;
	    border-bottom: 5px solid #f42434;
	    padding: 0 60px 10px 0;
			margin: 0;
		}
		.sub-title {
			@extend .heading4;
			@extend .uppercase;
			font-weight: bold;
		}
		label {
			font-weight: normal;
		}
		input[type="radio"], input[type="checkbox"] {
			margin-right: 8px;
		}
		.dropzone-form {
			text-align: center;
			border: 1px solid #ccc;
			width: 100%;
			max-width: 180px;
			padding: 15px;
			border-radius: 5px;
			color: #F42534;
			img {
				max-width: 80px;
				margin-bottom: 15px;
			}
		}
	}
}

.cols-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	div {
		width: 50%;
		&:nth-child(2n) {
			padding: 0 15px;
		}
	}
}

@media (max-width: 420px) {
  footer .col-md-2 {
    width: 50% !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  #beforeFooter {
    padding: 6px 0 56px;
  }
}

@media (max-width: 484px) {
  #beforeFooter p {
    padding-top: 48px;
  }
}

/*hack mobile*/
@media (min-width: 100px) and (max-width: 500px) {
  .areyouhiringMobile {
    display: none !important;
  }
  .areyouhiringGlobal {
    display: block !important;
  }
  .storeMobile {
    text-align: center;
  }
  .iosStoreDownload {
    background-size: 128px;
    width: 128px;
  }
  .googleStoreDownload {
    background-size: 153px;
    width: 153px;
  }
  .applyYourTablet {
    padding: 0;
  }
}

@media (max-width: 400px) {
  .userInfo {
    width: 275px !important;
  }
  #ourUserSay .item .fill {
    width: 275px !important;
    padding: 28px 17px !important;
  }
}

@media (max-width: 560px) {
  .teamContainer {
    float: none;
    margin: 0px auto 22px !important;
  }
  .userInfo, #ourUserSay .item .fill {
    width: 380px;
  }
}

@media (max-width: 768px) {
  .wrapper-career-team {
    .boxes-job.full-width {
      padding: 25px 40px;
    }
  }
  .headContainer {
    background-position: center top;
  }
  .header {
    margin-bottom: 55px;
  }
  .headContainer h1 {
    padding-left: 0px;
    font-size: 26px;
    text-align: center;
  }
  .smartEasyMobile {
    padding: 11px 0px 30px;
    line-height: 22px;
    text-align: center;
  }
  .storeMobile {
    padding: 11px 0px 26px;
    text-align: center;
  }
  .learnMobile {
    text-align: center;
    padding-top: 0px;
    padding-bottom: 37px;
  }
  .videoMobile {
    width: 108px;
    height: 66px;
    margin-bottom: 27px;
  }
  .benefitsMobile {
    text-align: center;
  }
  .benefit {
    img {
      margin-bottom: 27px;
    }
    .whiteFont {
      padding-bottom: 27px;
    }
  }
  .removeFootLogo {
    display: none !important;
  }
  .screenpeopleMobile {
    margin-top: 10px;
  }
  #getInterview {
    padding: 57px 0px;
  }
  .phoneMobile {
    height: 300px;
    overflow: hidden;
  }
  .interviewDescMobile {
    padding: 30px 25px;
    text-align: center;
    color: #565656;
    h1 {
      margin-top: 0;
    }
  }
  .jobsMobile {
		margin-bottom: 40px;
	}
  .jobsDescMobile {
    text-align: center;
    h1 {
      font-size: 24px;
      margin-top: 40px;
    }
    p {
      padding-top: 22px;
      color: #565656;
    }
  }
  #beforeFooter {
    min-height: 238px;
  }
  footer {
    .col-md-2 {
      width: 25%;
      float: left;
    }
    h3 {
      line-height: normal;
      margin-bottom: 32px;
    }
  }
  #beforeFooter {
    p {
      font-size: 21px;
    }
    .iosStoreDownload {
      background-size: 110px;
      width: 110px;
      height: 38px;
    }
    .googleStoreDownload {
      background-size: 128px;
      height: 38px;
      width: 128px;
    }
  }
  .pricingContainer {
    padding: 0px;
  }
  .areyouhiringMobile {
    float: right;
    padding: 0px 22px;
    line-height: 38px;
    background: #6472c7;
    border-radius: 1000px;
    color: #fff;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 13px;
		margin-right: 15px;
  }
  .faq-list {
    ul {
      width: 100%;
    }
    h3 span {
      display: inline;
    }
  }
  .faq-feature {
    width: 100%;
    .boxes {
      padding: 0;
    }
  }
  .faq-page .jumbotron h2 {
    font-size: 20pt;
  }
	.featured-heading {
		line-height: 40px;
	}
	.feature-box {
		padding: 0px 15px;
		margin-bottom: 50px;
	}
	.action-box {
		padding: 20px 15px;
	}
	.navbar-toggle {
			display: block;
	}
	.navbar-collapse {
			border-top: 1px solid transparent
	}
	.navbar-fixed-top {
			top: 0;
			border-width: 0 0 1px;
	}
	.navbar-collapse.collapse {
			display: none!important;
	}
	.navbar-nav {
			margin-top: 7.5px;
	}
	.navbar-nav>li {
			float: none;
	}
	.navbar-header {
		float: none;
	}
	.navbar-left {
		float: none;
	}
	.navbar-faq-left {
		float: left;
	}
	.navbar-faq-right {
		float: right;
		margin: 0;
	}
	.collapse.in{
			display:block !important;
			border: 0;
	}
	.navbar-nav li a.btn-signup {
	  border-radius: 1000px;
	  background: transparent;
	  color: $white;
	  font-size: inherit;
	  letter-spacing: normal;
	  padding: 0;
	  font-weight: normal;
	  &:active {
	    background: transparent !important;
	  }
	  &:hover {
	    background: transparent;
	    color: inherit;
	  }
	}
	.action-box:first-child {
		border-right: 0;
	}
	.haveacode {
		border-bottom: 0;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-nav li a {
    margin-left: 12px;
  }
  .header {
    margin-bottom: 82px;
  }
  .headContainer {
    background-position: center top;
  }
  .applyYourTablet, .applyVideoTablet {
    width: 50%;
    float: left;
    padding-top: 0px;
  }
  .applyYourTablet {
    padding-left: 0px;
    position: relative;
    left: -15px;
  }
  .applyVideoTablet {
    padding-right: 0px;
    position: relative;
    right: -15px;
  }
  .applyYourTablet {
    h1 {
      margin-top: 0px;
      padding-left: 0px;
      font-size: 30px;
      margin-bottom: 30px;
    }
    p {
      padding-left: 0px;
      padding-top: 0;
    }
  }
  .storeTablet {
    padding-left: 0;
  }
  .videoTablet {
    width: 338px;
    height: 210px;
  }
  .benefit {
    text-align: center;
  }
  #getInterview {
    background-position: center top;
    .col-md-5, .col-md-7 {
      float: left;
      width: 50%;
      padding-left: 0;
    }
  }
  .jobsDescMobile h1 {
    margin-top: 0;
  }
  .benefit .col-md-5 {
    float: left;
    width: 50%;
  }
  .gold, .silver, .bronze {
    width: 100%;
    margin-bottom: 30px;
  }
  .removeFootLogo {
    display: none;
  }
  footer {
    .col-md-2 {
      width: 25%;
      float: left;
    }
    h3 {
      margin-bottom: 24px;
      line-height: normal;
    }
  }
  .mobileHack {
    width: 607px;
		margin: 0 auto;
  }
  .teamContainer:nth-child(even) {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
	.interviewGlobal {
		display: none;
	}
	.interviewMobile {
		display: block;
	}
}

@media (min-width: 992px) {
  .applyYourTablet {
    width: 41.66666667%;
  }
  .applyVideoTablet {
    width: 58.33333333%;
  }
}

@media (max-width: 992px) {
  header video {
    width: 100%;
  }
  .faq-feature {
    margin: 0 -10px 60px;
    .flex-container {
      display: block;
    }
    .boxes {
      width: 100%;
      border: 0;
      border-bottom: 2px solid #6361A7;
      min-height: 190px;
      border-radius: 0;
    }
    .last-boxes {
      border-bottom: 0;
    }
    .boxes {
      .flex-item {
        width: 100%;
        text-align: center;
      }
      img, p {
        margin: 0 auto;
      }
    }
  }
  .faq-page h2 span {
    display: inline;
  }
}

.astronaut-web-sdk-event-style {
	background-color: #564FDB;
	width: 100%;
	border: 15px solid #564FDB;
	color: #ffffff;
}

.demo-web-sdk {
	padding: 2%;
	background-color: #e5e5e5;
	width: 48%;
	float: right;
}

.demo-web-sdk-form {
	padding: 2%;
	background-color: #f8f8f8;
	width: 48%;
}

.test-page-astronaut-qna {
	background-color: #FFFFFF;
	width: 100%;
	padding: 3%;
	border-radius: 15px;
}

.test-page-astronaut-container{
	padding-top: 2%;
	padding-right: 12%;
	padding-left: 12%;
	padding-bottom: 5%;
	background-color: #E5E5E5;
}

@media (max-width: 1024px) {
  header .sticky-nav #menu {
    margin-right: 0;
  }
}

@media (min-width: 1366px) {
  .headContainer, .headTeamContainer {
    background-size: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .logo-list {
    min-width: 38%;
  }
}

@media (max-width: 768px) {
  .logo-list {
    margin: 10px;
  }
	.teamContainer {
		.board-name {
			opacity: 1;
			bottom: 0;
		}
	}
	.registration {
		padding: 24px;
	}
	.cols-2 {
		flex-direction: column;
		div {
			width: 100%;
			&:nth-child(2n) {
				padding: 0;
			}
		}
	}
}

@media (max-width: 400px) {
  .company-list, .opening-item {
    min-width: 31%;
  }
	.logo-list {
		width: 100%;
		padding: 0;
	}
}
