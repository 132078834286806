/*
*
* ASTRONAUT WEB APP
* BASE
* VERSION 1.0
*
*/

@import "./variables.scss";
@import "./mixins.scss";

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  background: $light-grey;
	color: $body-text;
}

*, *:hover, *:focus {
  outline: none !important;
}

ul {
  list-style: none;
  padding-left: 0;
	margin: 0;
}

li {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  &:hover, &:focus {
    text-decoration: none;
  }
}

textarea {
    resize: none;
}

.block {
  display: block;
}

.hide {
  display: none;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.center-last {
  text-align-last: center;
}

.left-last {
  text-align-last: left;
}

.hidden {
  display: none;
}

.btn:hover, .btn:focus {
	box-shadow: none;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.inline {
  display: inline;
}

.inline-content {
  display: inline-block;
}

.inline-table {
  display: inline-table;
}

.img-responsive {
  margin: 0 auto;
  text-align: center;
}

.auto-margin {
  margin: 0 auto;
}

.margin-auto {
  margin: 0 auto;
}

.block {
  display: block;
}

.visible {
	visibility: visible;
}

.invisible {
	visibility: hidden;
}

.relative {
  position: relative;
}

.no-border {
	border: 0;
}

.uppercase {
	text-transform: uppercase;
}

.float-align-left {
  float: left;
}

.float-align-right {
  float: right;
}

.break-word {
	word-wrap: break-word;
	word-break: break-word;
	overflow-wrap: break-word;
}

/*
* FLEX CONTAINER
*/

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.flex {
	display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-column {
  flex-flow: column;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.flex-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-items-start {
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
}

.align-items-end {
  -webkit-box-align: flex-end;
      -ms-flex-align: flex-end;
          align-items: flex-end;
}

.vertical-item--center {
	@extend .flex-container;
	@extend .align-items-center;
}

.vertical-item--top {
	@extend .flex-container;
	@extend .align-items-start;
}

.vertical-item--justify {
	@extend .flex-container;
	@extend .flex-start;
}

.horizontal-item--start {
  @extend .flex-container;
  @extend .flex-center;
  @extend .align-items-start;
}

.horizontal-item--around {
	@extend .flex-container;
	@extend .flex-around;
	@extend .align-items-center;
}

.horizontal-item--between {
	@extend .flex-container;
	@extend .flex-between;
	@extend .align-items-center;
}

.horizontal-item--center {
	@extend .flex-container;
	@extend .flex-center;
	@extend .align-items-center;
}

.vertical-item--between {
	@extend .flex;
	@extend .flex-column;
	@extend .flex-between;
}

.align-self-end {
	align-self: flex-end;
}

.align-self-start {
	align-self: flex-start;
}

.align-self-center {
	align-self: center;
}

.flex-initial {
	flex: initial;
}

.flex-1 {
	flex: 1;
}

.flex-inherit {
	flex: inherit;
}

.flex-row {
	flex-flow: row;
}

.flex-grow {
	flex-grow: 1;
	flex: 1;
}

.number {
	font-weight: bold;
	li { list-style: decimal; }
}

.shadow {
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.10);
  @at-root #{&}-md {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12),
          0 2px 4px 0 rgba(0,0,0,0.08);
  }
  @at-root #{&}-lg {
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11),
            0 5px 15px 0 rgba(0,0,0,0.08);
  }
}

.b-l-0 {
	border-left: 0 !important;
}

.b-r-0 {
	border-right: 0 !important;
}

.b-r-10 {
	border-radius: 10px;
}

.b-rad-12 {
	border-radius: 12px;
}

.b-rad-up-0 {
	border-radius: 0 0 8px 8px !important;
	border-top: 0 !important;
}

.b-rad-down-0 {
	border-radius: 8px 8px 0 0 !important;
}

.b-r-up-10 {
	border-radius: 10px 10px 0px 0px;
}

.b-r-down-10 {
	border-radius: 0px 0px 10px 10px;
}

.b-r-left-5 {
	border-radius: 5px 0px 0px 5px !important;
}

.b-r-right-5 {
	border-radius: 0px 5px 5px 0px !important;
}

.border-radius-top-0 {
	border-radius: 0px 0px 4px 4px !important;
}

.max-width-100 {
	max-width: 100% !important;
}

.w-100 {
	width: 100% !important;
}

.w-94 {
	width: 94% !important;
}

/*
* WRAPPER
*/

.wrapper {
  width: 100%;
  overflow: hidden;
	min-height: 100%;
	width: 100%;
	position: relative;
	display: inline-block;
}

.wrapper-action {
  padding: 30px 10px 0;
  .flex-item {
    &:first-child {
      color: inherit;
    }
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.wrapper-inline div{
  display: inline-block;
}


/*
* MARGINS & PADDINGS
*/

@each $widthConfig in '0' '2' '4' '5' '6' '8' '10' '12' '13' '15' '16' '17' '20' '22' '24' '25' '26' '27' '28' '30' '32' '35' '36' '40' '50' '56' '70' '80' {
  .p-#{$widthConfig} {
			padding: #{$widthConfig}px !important;
  }

	.p-t-#{$widthConfig} {
			padding-top: #{$widthConfig}px;
  }

	.p-l-#{$widthConfig} {
			padding-left: #{$widthConfig}px;
  }

	.p-r-#{$widthConfig} {
			padding-right: #{$widthConfig}px;
  }

	.p-b-#{$widthConfig} {
			padding-bottom: #{$widthConfig}px;
  }

	.m-#{$widthConfig} {
			margin: #{$widthConfig}px  !important;
  }

	.m-l-#{$widthConfig} {
			margin-left: #{$widthConfig}px;
  }

  .m-l-important-#{$widthConfig} {
			margin-left: #{$widthConfig}px !important;
  }

	.m-l-n-#{$widthConfig} {
			margin-left: -#{$widthConfig}px;
  }

	.m-t-#{$widthConfig} {
			margin-top: #{$widthConfig}px !important;
  }

	.m-t-n-#{$widthConfig} {
			margin-top: -#{$widthConfig}px;
  }

	.m-r-#{$widthConfig} {
			margin-right: #{$widthConfig}px !important;
  }

	.m-r-n-#{$widthConfig} {
			margin-right: -#{$widthConfig}px;
  }

	.m-b-#{$widthConfig} {
			margin-bottom: #{$widthConfig}px !important;
  }

	.m-b-n-#{$widthConfig} {
			margin-bottom: -#{$widthConfig}px;
  }

}

@each $spaceConfig in '15' '20' '25' '30' {
	.space-#{$spaceConfig} {
		margin: #{$spaceConfig} 0 px;
	}
}

@each $lineHeight in '0' '18' '24' '32' '36' {
	.lh-#{$lineHeight} {
		line-height: #{$lineHeight}px !important;
	}
}


.count-info .label {
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 6px;
  top: 12px;
  border-radius: 50%;
}

.arrow {
  float: right;
}

.fa.arrow:before {
  content: "\f104";
}

.active > a > .fa.arrow:before {
  content: "\f107";
}

.logo-brand {
  // float: left;
  // padding: 15px 15px;
  // font-size: 18px;
  // line-height: 20px;
  // height: 50px;
  float: left;
  padding: 15px 15px;
  height: auto;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: #333333;
  font-weight: bold;
  img {
    height: 24px;
  }
}

.form-search {
  .input-group-btn:first-child > {
    .btn, .btn-group {
      border-radius: 16px 0 0 16px;
    }
  }
  .input-group .form-control:last-child {
    border-radius: 0 16px 16px 0;
    border: 0;
  }
}


.filter-tab {
  padding: 15px 0;
}

.filter-select {
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
  label span {
    font-size: 12px;
    color: #3D3D3D;
    vertical-align: text-top;
    font-weight: 600;
    padding-left: 10px;
  }
  > div {
    margin-right: 15px;
		&:last-child {
			margin-right: 0;
		}
  }
}

.icon-with-text {
  margin-right: 20px;
  a {
    font-size: 12px;
    color: #4B4F85;
    font-weight: 600;
    vertical-align: -webkit-baseline-middle;
    span {
      &:hover, &:focus {
        color: #5272D1;
        text-decoration: underline;
      }
    }
  }
}

.filter-view {
  a {
    cursor: pointer;
    width: 26px;
    height: 24px;
    vertical-align: -webkit-baseline-middle;
    margin-right: 10px;
  }
  div {
    display: inline-block;
    margin-right: 10px;
  }
  i {
    font-size: 30px;
    padding: 0px 2px;
  }
}

.icon-with-text {
  position: relative;
  display: block;
  .icon {
    margin-right: 4px;
  }
}

/*
* CUSTOM SECONDARY HEADER
*/

.second-header {
	background-color: $white;
  box-shadow: 0px 3px 5px #0000004F;
  border-bottom: 1px solid #cccccc;
	position: fixed;
	top: 55px;
	left: 0;
	width: 100%;
  z-index: 1029;
}

.second-header__content {
	padding: 16px 10px 12px;
  h5 {
    font-size: 14px;
    font-weight: bold;
  }
}

/*
* CUSTOM MAIN CONTENT
*/

.main-content {
  width: 100%;
  height: 100%;
	min-height: 100vh;
  background-color: $light-grey;
  padding: 15px;
}

.main-content-report {
  width: 100%;
  height: 100%;
  background-color: $light-grey;
  padding: 15px;
}

.main-content-fluid {
  width: 100%;
  height: 100%;
	min-height: 100vh;
  background-color: $bg-bones;
  padding: 15px;
}

.label-gdpr-text {
  margin-bottom: 10px !important;
}

.main-gdpr-fluid {
  background-color: #999999;
  padding: 20px;
  border-radius: 5px;
  &.is-on {
    background-color: #564FDB
  }
  &.custom-gdpr {
  	.checkbox-container {
    	margin-top: 0px !important;
  	}
  }
  label {
    color: white;
    font-size: 12px;
    font-weight: normal;
  }
  .checkbox-container {
  	min-height: 15px;
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  @media screen and (min-width: 1500px) {
		.checkbox-container {
			margin-top: 15px;
		}
	}
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #FFF;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 10px;
    top: 4px;
    width: 7px;
    height: 15px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.label-desktop-preview {
	font-size: 16px;
	font-weight: normal;
	color: #666666;
}

.whatsapp-box {
  background-color:  #DEDDF8;
  padding: 13px 16px 11px;
  border-radius: 5px;
  margin-top: 8px;
  label {
    color: #564FDB;
    font-size: 12px;
    font-weight: normal;
  }
  .label-gdpr-text {
    color: #7D78E3;
  }
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    border: 2px solid #564FDB;
    background-color: #FFFFFF;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #FFF;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #FFF;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    border-color: #564FDB !important;
    border-style: solid;
    border-image: initial;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 13px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.whatsapp-purple-input {
  color: #564FDB !important;
}

.whatsapp-purple-input::-webkit-input-placeholder {
  color:#564FDB !important;
}

.whatsapp-purple-input::-moz-placeholder {
  color:#564FDB !important;
}

.whatsapp-purple-input:-moz-placeholder {
  color:#564FDB !important;
}

.whatsapp-purple-input::-ms-placeholder {
  color:#564FDB !important;
}

.whatsapp-purple-input:-ms-placeholder {
  color:#564FDB !important;
}

.whatsapp-purple-input::placeholder {
  color:#564FDB !important;
}

.big-panel {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  background-color: $white;
  padding: 15px 20px;
  @include rounded(5px);
  margin-bottom: 15px;
	position: relative;
}

.big-panel2 {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  background-color: $white;
  padding: 15px 210px;
  @include rounded(5px);
  margin-bottom: 15px;
	position: relative;
}

input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
}

input[type="checkbox"]:checked + label::after,
input[type="radio"]:checked + label::after {
  color: #fff;
}

.input-xs-width {
	width: 65px;
}

.input-sm-width {
	width: 100px;
}

.input-md-width {
	width: 150px;
}

.archive-question {
	@include all-transition(0.3s,0s);
}

/*
* CUSTOM TABLE DIV
*/

.custom-table {
  display: table;
}

.table-row {
	display: flex;
	display: -webkit-flex;
  flex-direction: row;
	-webkit-flex-direction: row;
  flex-wrap: no-wrap;
	-webkit-flex-wrap: no-wrap;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.table-head {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	-webkit-flex-direction: row;
	flex-grow: 0;
	-webkit-flex-grow: 0;
	width: 100%;
}

.table-cell {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	-webkit-flex-direction: row;
	flex-grow: 0;
	-webkit-flex-grow: 0;
	width: 100%;
}

.form-control-static {
  padding-top: 0;
}

.flex-container {
	.main {
		flex: 2 0px;
	}
}
.has-success {
	i {
		margin-left: 5px;
		color: #3c763d;
		font-size: 20px;
	}
  .form-control {
    color: #3c763d;
  }
}

.has-warning {
	i {
		margin-left: 5px;
		color: #f8ac59;
		font-size: 20px;
	}
  .form-control {
    color: #f8ac59;
		border-color: #f8ac59;
		&:focus {
		  border-color: #f8ac59;
		  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
		          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
		}
  }
}

.has-error {
	i {
		margin-left: 5px;
		color: #a94442;
		font-size: 20px;
	}
  .form-control {
    color: #a94442;
  }
}

.border-top {
  border-top: 1px solid #d8d8d8;
}

.border-left {
  border-left: 1px solid #d8d8d8;
}

.border-left-dark-grey  {
  border-left: 1px solid #828282 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-right {
  border-right: 1px solid $border-color;
}

.navbar-fixed__content {
  padding-top: 167px;
  &.no-subheader {
    padding-top: 56px;
  }
}
/*
* CUSTOM FLEXBOX MASONRY
*/

.masonry {
	-webkit-columns: 3 300px;
     -moz-columns: 3 300px;
          columns: 3 300px;
  -webkit-column-gap: 2em;
     -moz-column-gap: 2em;
          column-gap: 2em;
	.masonry__item {
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
	}
}

.masonry__full {
	-webkit-columns: 5 300px;
     -moz-columns: 5 300px;
          columns: 5 300px;
  -webkit-column-gap: 2em;
     -moz-column-gap: 2em;
          column-gap: 2em;
	.masonry__item {
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
	}
}

.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-items-start {
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
}

.align-items-end {
  -webkit-box-align: flex-end;
      -ms-flex-align: flex-end;
          align-items: flex-end;
}

.vertical-item--center {
	@extend .flex-container;
	@extend .align-items-center;
}

.vertical-item--top {
	@extend .flex-container;
	@extend .align-items-start;
}

.vertical-item--justify {
	@extend .flex-container;
	@extend .flex-start;
}

.horizontal-item--around {
	@extend .flex-container;
	@extend .flex-around;
	@extend .align-items-center;
}

.horizontal-item--end {
	@extend .flex-container;
	@extend .flex-end;
	@extend .align-items-center;
}

.bg-white {
	background-color: $white;
}

.bg-grey {
	background-color: #E4E4E4 !important;
}

.color-black {
	color: black !important;
}

.color-grey-99 {
	color: #999999;
}

.color-grey-66 {
	color: #666666;
}

.close-cross-grey {
	color: #666666;
	font-size: 28px;
	cursor: pointer;
}

.color-black {
	color: black !important;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-28 {
  font-size: 28px !important;
}

.h-100 {
  height: 100px !important;
}

.color-light-grey {
	color: #666666;
}

.cursorpointer {
	cursor: pointer;
}

input[type="checkbox"]:checked + label::before, input[type="radio"]:checked + label::before {
  background-color: transparent;
  border-color: transparent;
}

.bordered {
	border: 1px solid $border-color;
}

.border-bottom {
	border-bottom: 1px solid $border-color;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.valign-middle {
  vertical-align: middle;
}

.valign-bottom {
  vertical-align: bottom;
}

.float-right {
	position: absolute;
	right: 0;
}

.selectable {
	.grid__row {
		cursor: pointer;
	}
}

label.inline-content {
  input {
    vertical-align: top;
  }
	.btn-checkbox {
		vertical-align: text-bottom;
	}
}

.full-width {
  width: 100% !important;
}

#public {
	a {
		color: $purple01;
		&:hover, &:focus {
			color: $purple02;
		}
	}
}
