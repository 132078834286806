/*
*
* ASTRONAUT WEB APP
* VARIABLES
* VERSION 1.0
*
*/

$bg-black: #151b23;
$bg-blueblack: #090E27;
$bg-bones: #F0F0F0;

$white: #ffffff;
$light-grey: #f0f0f0;

$purple: #6472C7;
$dark-purple: #465190;
$navy: #367C9D;
$dark-navy: #295C75;
$turqoise: #31ABB0;
$dark-turqoise: #207B7F;
$grey: #BDBDBD;
$dark-grey: #797979;
$red: #E36B6B;
$light-green: #31B06D;
$dark-green: #207046;
$dark-blue: #337ab7;
$light-blue: #60B3DF;

$btn-rounded: 37px;
$large-rounded: 10px !important;
$medium-rounded: 5px !important;
$small-rounded: 3px;
$full-rounded: 50%;
$none-rounded: 0;
$dropdown-rounded: 0 0 5px 5px;

$body-text: #333333;
$text-color: #071522;
$text-selected: #62BD95;
$error: #EC3333;
$success: #31B06D;
$border-color: #BDBDBD;
$border-color-lighter: #BDBDBD;

$form-error-field: #FF0000;
$form-active-field: #AB47BC;
$form-basic-field: #BDBDBD;

$action-sprite: url('../img/actions.png');
$notif-sprite: url('../img/notifications.png');
$social-sprite: url('../img/socials.png');
$page-sprite: url('../img/pages.png');
$thumbs-sprite: url('../img/thumbs.png');
$thumbs2-sprite: url('../img/thumbs2.png');
$reports-sprite: url('../img/reports.png');
$add-candidate-sprite: url('../img/add-candidate.png');
$stars-sprite: url('../img/star-rating.png');
$sprite-svg: url('../img/sprite.svg');

$switcher-enabled-bg: #564FDB;
$switcher-enabled-color: #FFFFFF;
$switcher-enabled-tab-bg: #FFFFFF;

$switcher-disabled-bg: #564FDB;
$switcher-disabled-color: #FFFFFF;
$switcher-disabled-tab-bg: #FFFFFF;

// Basic Colors
$purple: #6472C7;       // Primary color
$light-purple: #564FDB;
$gray: #828282;  // Default color
$blue: #2F80ED;       // Success color
$light-gray: #828282;      // Info color
$red: #EA4D4D;        // Danger color
$white: #ffffff;
$black: #000000;
$yellow: #FFAD13;
$bg-red: #EB5757;

// Various colors
$text-color: #333333;   // Body text

// Spiner color and margin
$spin-dark-color: $purple;
$spin-light-color: $white;
$spin-margin: 0 auto;

//Border radius for buttons
$btn-border-radius: 6px;

// UI Colors
$purple01: #564FDB;
$purple02: #8B4DFF;
$purple03: #A778FF;
$purple04: #D4BDFF;

$dark01: #333333;
$dark02: #4F4F4F;
$dark03: #828282;
$dark04: #BDBDBD;

$red01: #EA4D4D;
$red02: #F06262;

.bg-white {
  background-color: $white;
}
