/*
*
* ASTRONAUT WEB APP
* PAGES
* VERSION 1.0
*
*/

/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 17, 2019
UPDATE DATE: Oct 17, 2019
REVISION: 1
NAME: astronaut.pricing-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#pricing {
background-color: #F0F0F0;
box-shadow: none;
color: #666666;
.price-tit{
    text-align: center;
    font-size: 30px;
    line-height: 38px;
    margin: 0 0 40px 0;
}

ul.the-price{
    overflow: auto;
    margin: -20px -20px -40px -20px;
    text-align: center;
}

ul.the-price > li{
    // float: left;
    display: inline-block;
    width: 33.33%;
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;
}

ul.the-price > li .box-price{
    background: #FFF;
    padding: 20px 50px 32px;
    border-radius: 20px;
    border: 2px solid #FFF;
    box-shadow: 0px 10px 20px #0000001A;
}

ul.the-price > li .box-price-covid{
    background: #E7F3FE;
    padding: 20px 50px 32px;
    border-radius: 20px;
    border: 2px solid #E7F3FE;
    box-shadow: 0px 10px 20px #0000001A;
}

ul.the-price > li:last-child .box-price{
    border: 2px solid #564FDB;
    box-shadow: 0px 10px 20px #564FDB80;
}

ul.the-price > li .tit-price,
ul.the-price > li .desc-price{
    text-align: center;
    margin: 0 0 20px 0;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
}

ul.the-price > li .tit-price-covid{
    text-align: left;
    margin: 0;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
}

ul.the-price > li .desc-price{
    margin: 20px 0 0 0;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
}

ul.the-price > li .desc-price-covid{
		text-align: left;
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}

ul.the-price > li .box-price > ul{
    margin: -5px;
}

ul.the-price > li .box-price > ul > li{
    position: relative;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    padding: 5px 5px 5px 25px;
}

ul.the-price > li .box-price > ul > li > i{
    position: absolute;
    left: 0;
    top: 5px;
    background: rgba(86, 79, 219, 1);
    color: #FFF;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 22px;
    text-align: center;
}

ul.the-price > li .box-price-covid > ul{
    margin: -5px;
}

ul.the-price > li .box-price-covid > ul > li{
    position: relative;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    padding: 5px 5px 5px 25px;
}

ul.the-price > li .box-price-covid > ul > li > i{
    position: absolute;
    left: 0;
    top: 5px;
    background: rgba(86, 79, 219, 1);
    color: #FFF;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 22px;
    text-align: center;
}

ul.the-price > li a{
    display: block;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    text-transform: uppercase;
    padding: 0 13px;
    border-radius: 100px;
    margin: 20px 0 0 0;
    background: rgba(86, 79, 219, .1);
    color: #564FDB;
    border: 2px solid #564FDB;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

ul.the-price > li .box-price-covid a{
    display: block;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    text-transform: uppercase;
    padding: 10px 0 5px;
    border-radius: 100px;
    margin: 11px 0 0 0;
    background: #D8E0FB;
    color: #564FDB;
    border: 2px solid #564FDB;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    div {
    	display: inline-block;
    	line-height: 16px;
    	text-align: right;
    	span {
				font-size: 12px;
				font-weight: bold;
    	}
    	img {
	    	width: auto;
	    	height: 30px;
	    	margin-left: 5px;
	    	margin-top: -15px;
	    }
    }
}

ul.the-price > li a:hover{
    color: #FFF;
    background: rgba(86, 79, 219, 1);
}

ul.the-price > li .box-price-covid a:hover{
    color: #564FDB;
    background: #FFF;
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}
