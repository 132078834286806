/*
*
* ASTRONAUT WEB APP
* PAGES
* VERSION 1.0
*
*/
@import "./variables.scss";
@import "./mixins.scss";
@import "./icons.scss";
@import "./base.scss";
@import "./animations.scss";

/*
* HOME
*/
.label-score-section{
	font-size: 12px;
	font-weight: normal;
	color:#666666;
	line-height: 15px;
}

.wyswig-email-container{
	img{
		max-width:100%;max-height:100%;
	}
}

.swal2-popup #swal2-content {
	font-family: 'Open Sans', sans-serif !important;
	font-size: 16px !important;
}

.swal2-popup .swal2-title {
	font-family: 'Open Sans', sans-serif !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
	font-family: 'Open Sans', sans-serif !important;
}

.swal2-popup {
	font-size: 1em;
}

.sweetalert2-container-class {
	color: #666666;
	.popup-class {
		width: 540px;
		height: 310px;
	}
	.title-class {
		font-size: 22px;
		margin-bottom: 30px;
	}
	span {
		color: #564FDB;
		font-weight: bold;
		&.failed {
			color: #FF0000;
			font-weight: normal;
		}
	}
	.actions-class {
		margin-top: 50px;
	}
	.content-class {
		font-weight: normal;
	}
	.confirm-button-class {
		background-color: #564FDB;
		border-radius: 5px;
		width: 116px;
		height: 41px;
		border: 0;
		margin: 10px;
		color: white;
	}
	.cancel-button-class {
		background-color: white;
		border-radius: 5px;
		width: 116px;
		height: 41px;
		border: 1px solid #564FDB;
		margin: 10px;
		color: #564FDB;
	}
}

.title-heading {
	font-size: 14px;
	font-weight: bold;
	color: $text-color;
	padding-bottom: 10px;
	text-transform: uppercase;
	border-bottom: 0;
}

h5.title-heading {
  @extend .title-heading;
	text-transform: uppercase;
}

h5.title-heading-unborder {
	font-size: 14px;
	font-weight: bold;
	color: #666666;
	// padding-bottom: 10px;
	// text-transform: uppercase;
	border-bottom: 0;
}

h5.title-heading-unborder-question {
	margin-left: -10px;
	font-size: 14px;
	font-weight: bold;
	color: $text-color;
	padding-bottom: 10px;
	text-transform: uppercase;
	border-bottom: 0;
}

.forum-detail {
	padding: 10px 20px;
}

.forum-info {
	text-align: center;
	border-left: 1px solid $border-color;
	padding: 7px 14px;
}

.forum-icon {
	float: left;
	width: 30px;
	margin-right: 20px;
	text-align: center;
	height: 60px;
	padding: 22px 20px;
}

.forum-status {
	margin-right: 20px;
	.label {
		font-size: 13px;
		vertical-align: middle;
		display: inline-block;
		min-width: 110px;
		line-height: 22px;
	}
}

.forum-item {
	border: 1px solid $border-color;
	border-bottom: 0;
	.btn-checkbox {
		opacity: 1;
		margin-top: 7px;
	}
	&.show-boxes {
		.btn-checkbox {
			opacity: 1;
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
	&:hover, &:focus {
		.btn-checkbox {
			opacity: 1;
		}
	}
}

.forum-item2 {
	border-bottom: 1px solid $border-color;
	.btn-checkbox {
		opacity: 1;
		margin-top: 7px;
	}
	&.show-boxes {
		.btn-checkbox {
			opacity: 1;
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
	&:hover, &:focus {
		.btn-checkbox {
			opacity: 1;
		}
	}
}

.views-number {
	font-size: 24px;
	font-weight: 400;
	color: #909090;
	padding-top: 5px;
	cursor: pointer;
}

.forum-item {
	small {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 14px;
		&.sourced {
			color: #2A3788;
		}
		&.applied {
			color: $purple;
		}
		&.shortlist {
			color: #58A5C9;
		}
		&.offer {
			color: #70C780;
		}
		&.disqualified {
			color: $red;
		}
	}
	.forum-sub-title {
		color: #999;
		margin-top: -7px;
	}
}

.forum-item2 {
	small {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 14px;
		&.sourced {
			color: #2A3788;
		}
		&.applied {
			color: $purple;
		}
		&.shortlist {
			color: #58A5C9;
		}
		&.offer {
			color: #70C780;
		}
		&.disqualified {
			color: $red;
		}
	}
	.forum-sub-title {
		color: #999;
		margin-top: -7px;
	}
}

.forum-title {
	margin: 15px 0 15px 0;
}

.forum-desc {
	color: #999;
}

a.forum-item-title {
	color: $text-color;
	display: block;
	font-size: 20px;
	font-weight: 600;
	display: inline-block;
	@include ellipsis(550px);
	&:hover {
		color: $purple;
	}
}

.forum-icon .fa {
	font-size: 30px;
	margin-top: 8px;
	color: #9b9b9b;
}

.forum-item.active {
	.fa {
		color: #1ab394;
	}
	a.forum-item-title {
		padding-bottom: 12px;
	}
}

.flex-container.forum-sub-title div {
	margin-right: 14px;
}

.forum-date {
	color: #4D4D4D;
	p {
		//border-right: 1px solid $border-color;
		padding-right: 10px;
		margin-bottom: 0;
		display: inline-block;
	}
}

.forum-expired {
	color: #BE5757;
	font-weight: 600;
}

.forum-place {
	color: #757575;
	font-weight: bold;
}

.forum-checker input {
	width: 15px;
	height: 15px;
	cursor: pointer;
}

/*
* CANDIDATE LIST VIEW
*/

.hlist-detail {
	height: 59px;
}

.hlist-detail-list-view {
	height: 45px;
}

.hlist-info {
	text-align: center;
	padding: 12px 20px;
}

.rating-profile, .hlist-info, .votes-profile .votes,
.notif-profile, .hlist-info, .votes-profile .notifs {
	.message-info, .rating-info, .share-info, .question-info {
		padding: 0 3px;
		position: relative;
		display: block;
	}
}

.rating-profile, .hlist-info, .votes-profile, .notif-profile {
	.count-info .label {
		line-height: 12px;
		padding: 2px 5px;
		position: absolute;
		right: 0;
		top: -6px;
		border-radius: 50%;
	}
}

.rating-profile {
	position: absolute;
	bottom: 20px;
	right: 10px;
}

.star-rating-profile {
	position: absolute;
	bottom: 20px;
	left: 10px;
}

.votes-profile {
	position: relative;
	z-index: 99;
}

.notif-profile {
	position: absolute;
	bottom: 20px;
	left: 50px;
}

.hlist-icon {
	float: left;
	width: 30px;
	margin-right: 0px;
	text-align: center;
	height: 45px;
	// padding: 13px 0px;
	border-right: 1px solid #dbdbdb;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.hlist-item {
	border-bottom: 1px solid $border-color;
	margin: 0 -20px;
	&.select-boxes {
		.btn-checkbox {
			opacity: 1;
		}
		.check-profile {
			z-index: 99;
			position: absolute;
			right: 10px;
			top: 15px;
		}
		&.show-boxes {
			.btn-checkbox {
				opacity: 1;
			}
		}
		&:hover, &:focus {
			.btn-checkbox {
				opacity: 1;
			}
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
}

.candidates-list-container-table {
	border: 1px solid #CCCCCC;
	border-radius: 5px;
}

.display-label {
	margin-right: 10px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.hlist-item2 {
	border-bottom: 1px solid $border-color;
	&.select-boxes {
		.btn-checkbox {
			opacity: 1;
		}
		.check-profile {
			z-index: 99;
			position: absolute;
			right: 10px;
			top: 15px;
		}
		&.show-boxes {
			.btn-checkbox {
				opacity: 1;
			}
		}
		&:hover, &:focus {
			.btn-checkbox {
				opacity: 1;
			}
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
}

.hlist-item3 {
	border-bottom: 1px solid $border-color;
	font-size: 14px;
	&.select-boxes {
		.btn-checkbox {
			opacity: 1;
		}
		.check-profile {
			z-index: 99;
			position: absolute;
			right: 10px;
			top: 15px;
		}
		&.show-boxes {
			.btn-checkbox {
				opacity: 1;
			}
		}
		&:hover, &:focus {
			.btn-checkbox {
				opacity: 1;
			}
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
}

.hlist-desc {
	color: #3D3D3D;
	-webkit-box-align: center;
			-ms-flex-align: center;
					align-items: center;
	height: 100%;
	div:nth-child(2) {
		border-left: 0;
	}
}

.flex-container.hlist-sub-title div {
	margin-right: 14px;
}

.hlist-name {
	font-weight: bold;
	padding: 0 10px;
	border-right: 1px solid #dbdbdb;
	color: #3D3D3D;
	width: 80%;
	p {
		margin: 0;
	}
}

.hlist-name-list-view {
	font-weight: bold;
	padding: 0 10px;
	border-right: thin solid #dbdbdb;
	color: #3D3D3D;
	// width: 135px;
	width: 10%;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: left; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	p {
		margin: 0;
	}
}

.ellipsis-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.hlist-email {
	color: #666666;
	padding: 0 10px;
	border-right: 1px solid #dbdbdb;
	height: 100%;
	// width: 120px;
	width: 10%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: left; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.hlist-phone {
	color: #666666;
	padding: 0 8px;
	border-right: 1px solid #dbdbdb;
	height: 100%;
	// width: 115px;
	width: 9%;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: left; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.hlist-job-title {
	padding: 0 10px;
	border-right: 1px solid #dbdbdb;
	color: #4B4F85;
	font-weight: 600;
	width: 115px;
	text-align: center;
}

.hlist-job-title-list-view {
	padding: 0 10px;
	// border-right: 1px solid #dbdbdb;
	color: #2A3788;
	font-weight: 600;
	// width: 115px;
	width: 10%;
	text-align: center;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.hlist-stats {
	color: #564FDB;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 10px;
	padding-right: 0;
	border-right: 1px solid #dbdbdb;
	height: 100%;
	// width: 185px;
	width: 12%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: left; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.hlist-score {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 10px;
	padding-right: 0;
	border-right: 1px solid #dbdbdb;
	height: 100%;
	// width: 111px;
	width: 12%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: left; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	.score-info {
		line-height: 15px;
		margin-left: 5px;
		p {
			margin-bottom: 0;
			font-size: 12px;
		}
	}
	&.cursorpointer {
		cursor: pointer;
	}
}

.hlist-rating {
	padding: 0;
	border-left: 1px solid #dbdbdb;
	height: 100%;
	// width: 155px;
	width: 12.5%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	.count-info {
		padding: 0 6px;
		position: relative;
		cursor: inherit;
	}
	&.cursorpointer {
		cursor: pointer;
	}
}

.hlist-star-rating {
	padding: 0 0px;
	height: 100%;
	// width: 150px;
	width: 11%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	h2 {
		margin: 0;
		padding: 0;
		font-size: 21px;
		font-weight: 600;
	}
	&.cursorpointer {
		cursor: pointer;
	}
}

.hlist-additional {
	color: #666666;
	padding: 0 5px;
	border-left: 1px solid #dbdbdb;
	&:first-of-type {
		border-left: 0;
	}
	height: 100%;
	// width: 112px;
	width: 12%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center; /* align horizontal */
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	&.additionalfield {
		white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;
	}
}

.candidates-list-container-table-new {
	.hlist-name-list-view, .hlist-email, .hlist-phone, .hlist-job-title-list-view, .hlist-additional {
		width: 12.5%;
		display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	}
	.hlist-label-status-rating {
		-webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
	}
}

.profile-title {
	width: 55%
}

.score-test {
	i {
		font-style: normal;
		font-size: 12px;
	}
	&.right {
		line-height: 7px;
		p {
				font-size: 12px !important;
				margin-bottom: 0px;
			}
		}
		.score-info {
			margin-right: 0px;
			text-align: right;
			font-size: 12px;
			b {
				font-size: 14px;
			}
	}
}

.score-test-table {
	i {
		font-style: normal;
		font-size: 14px;
	}
	&.right {
		line-height: 7px;
		p {
				font-size: 15px !important;
			}
		}
		.score-info {
			margin-right: 0px;
			text-align: right;
	}
}

.score-test-candidate-list {
	width: 45%;
	display: flex;
  flex-direction: column-reverse;
	i {
		font-style: normal;
		font-size: 18px;
	}
	&.right {
		line-height: 7px;
		p {
				font-size: 15px !important;
			}
		}
		.score-info {
			margin-right: 0px;
			text-align: right;
	}
}


/*
* CANDIDATE BOX VIEW
*/

.wrapper-box-profile {
	min-width: 226px;
	max-width: 226px;
	padding: 10px 0 25px;
	margin: 0 15px;
	overflow: hidden;
	min-height: 347px;
	.img-profile {
		min-height: 224px;
		background-color: #f7f7f7;
			img {
				width: 100%;
			}
		}
	.profile-detail {
		font-size: 12px;
		.profile-name {
			@include ellipsis(145px);
			a {
				color: #6B97E2;
				font-weight: bold;
				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}
		.job-title-profile {
			margin-top: 5px;
			@include ellipsis(145px);
			a {
				color: #3B3B3B;
				font-weight: normal;
				cursor: default;
			}
		}
		.job-title-profile a {
			color: #3B3B3B;
			font-weight: normal;
			cursor: default;
		}
		.question-wrap {
			flex-grow: 2;
			padding: 3px 0;
		}
		.rating-wrap {
			flex-grow: 1;
			padding: 0 10px;
			border-left: 1px solid $border-color;
			.count-info {
				padding: 0 3px;
				position: relative;
				display: block;
				.label {
					right: -6px;
					top: -6px;
				}
			}
		}
	}
	.btn.box-profile {
		color: #fff;
		white-space: inherit;
		padding: 10px 0;
		@include rounded($none-rounded);
		position: relative;
		box-shadow: none;
		width: 100%;
		&.select-boxes {
			.btn-checkbox {
				opacity: 1;
			}
			.check-profile {
				position: absolute;
				right: 10px;
				top: 15px;
				z-index: 0;
			}
			&.show-boxes {
				.btn-checkbox {
					opacity: 1;
				}
			}
			&:hover, &:focus {
				.btn-checkbox {
					border: 1px solid #465190;
				}
			}
		}
		.img-profile {
			z-index: 98;
		}
	}
	&.active {
		border-color: #476881;
	}
}

.wrapper-vote {
	justify-content: space-between !important;
	width: 100%;
	div {
		padding-right: 5px;
		font-size: 12px;
		b {
			display: contents;
			font-size: 14px;
		}
	}
	.btn {
		text-align: center;
		margin: 0 auto;
	}
}

/*
* CUSTOM FORM - CREATE JOB
*/

.form-custom {
	label {
	font-size: 16px;
	font-weight: bold;
	}
	.form-group {
		margin-bottom: 30px;
	}
	.form-control {
		@include rounded($none-rounded);
	}
	textarea:first-child {
		height: 200px;
	}
}

.wrapper-barcode {
	div {
		margin: 10px 0;
	}
	b {
		color: #050505;
	}
	.code-here {
		color: #F16C6C;
		font-weight: bold;
		margin-right: 10px;
	}
	input[type="text"] {
		vertical-align: middle;
		margin: 0 5px;
		display: inline-block;
		height: 34px;
		width: 158px;
		&::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
			color: #A1A1A1;
		}
		&:-moz-placeholder {
			opacity: 1;
		}
	}
}

/*
* CUSTOM BREADCRUMBS
*/

.wrapper-breadcrumb {
	max-width: 59%;
	.breadcrumb {
		background-color: transparent;
		margin: 0;
		padding: 0;
		> li {
			&.active {
				a {
					color: $purple;
					font-weight: 600;
					word-break: break-word;
				}
			}
			> a {
				color: #444444;
				word-break: break-word;
			}
		}
		> li + li:before {
			font-family: FontAwesome;
			font-weight: bold;
			font-style: normal;
			content: "\f105";
			color: #444444;
			padding: 0 10px;
		}
	}
}

.custom-modal .modal-content  {
	border-radius: 10px;
}

.wrapper-modal-addbranding {
	&.modal-dialog {
		width: 628px;
	}
	.modal-header {
		padding: 10px 35px 10px;
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	h4 {
		margin: 0;
		font-size: 14px;
		color: #454545;
		&.modal-title {
			margin-right: 20px;
		}
	}
	.modal-body {
		padding: 20px;
		h4 {
			margin-bottom: 15px;
			text-align: left;
		}
		label {
			font-weight: bold;
		}
	}
	.modal-footer {
		border-top: 0;
	}
}

.wrapper-modal-percandidate {
	label {
		display: none;
	}
	&.modal-dialog {
		width: 824px;
	}
	.modal-header {
		padding: 10px 35px 10px;
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	h4 {
		margin: 0;
		font-size: 14px;
		color: #454545;
		&.modal-title {
			margin-right: 20px;
		}
	}
	.modal-body {
		padding: 20px;
		h4 {
			margin-bottom: 15px;
			text-align: left;
		}
		label {
			font-weight: bold;
		}
		.table-percandidate {
			margin-top: 10px;
		}
	}
	.modal-footer {
		border-top: 0;
	}
}

.wrapper-modal-share {
	&.modal-dialog {
		width: 628px;
	}
	.modal-header {
		padding: 10px 35px 10px;
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	h4 {
		margin: 0;
		font-size: 14px;
		color: #454545;
		font-weight: bold;
		&.modal-title {
			margin-right: 20px;
		}
	}
	.modal-body {
		padding: 20px;
		h4 {
			margin-bottom: 15px;
			text-align: left;
		}
		label {
			font-weight: 600;
			margin-bottom: 15px;
		}
	}
	.modal-footer {
		border-top: 0;
	}
}

.wrapper-modal-candidatelist {
	.modal-header {
		border-bottom: 0;
	}
	.modal-body {
		td {
			word-wrap: break-word;
		}
	}
}


/*
* CUSTOM TABS AND MODAL
*/

.full-width-tabs > ul.nav.nav-tabs {
		display: table;
		width: 100%;
		table-layout: fixed;
		> li {
			float: none;
			display: table-cell;
			> a {
					text-align: center;
			}
			&.wrap-space{
					width:100%;
			}
		}
}

.wrapper-modal-import {
	&.modal-dialog {
		min-width: 824px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		h4 {
			font-size: 16px;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		padding: 0;
		.uploads {
			.panel-body {
				padding: 15px 40px;
			}
		}
	}
}

.wrapper-modal-addcandidate {
	&.modal-dialog {
		width: 562px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		padding: 0;
		.import-input,.manual-input {
			width: 50%;
			text-align: center;
			padding: 40px 0 20px;
		}
		.manual-input img {
			padding: 13px 0;
		}
		.import-input {
			border-right: 1px solid $border-color;
		}
		p {
			color: $purple;
			font-size: 12px;
			padding-top: 15px;
			font-weight: bold;
		}
	}
}


#modalReportNotif {
	background-color: rgba(0,0,0,.5);
}

.wrapper-modal-reportnotif {
	&.modal-dialog {
		width: 400px;
		margin: 10% auto;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		padding: 40px;
		.import-input,.manual-input {
			width: 50%;
			text-align: center;
			padding: 40px 0 20px;
		}
		.manual-input img {
			padding: 13px 0;
		}
		.import-input {
			border-right: 1px solid $border-color;
		}
		h4 {
			font-size: 22px;
			line-height: 15px;
			font-weight: 600;
			margin-top: 20px;
		}
		p {
			color: #666666;
			font-size: 16px;
			line-height: 22px;
			font-weight: normal;
			margin: 20px 0;
		}
	}
}

.tabs-add-candidates {
	ul.nav.nav-tabs > li {
		background-color: #030A10;
		&.active {
			border: 0;
			background: #3B7895;
			> a {
				background-color: #3B7895;
				color: $white;
				&:hover,&:focus {
					background-color: #3B7895;
					border: 0;
				}
			}
		}
		> a {
			@include rounded($none-rounded);
			border: 0;
			background-color: #030A10;
			font-size: 14px;
			font-weight: 600;
			color: $white;
			&:hover,&:focus {
				background-color: #3B7895;
				border: 0;
			}
		}
	}
}

.wrapper-modal-question {
	&.modal-dialog {
		width: 645px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 0px solid $border-color;
		h4 {
			color: #666666;
			font-size: 24px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		padding: 10px 35px 20px;
		.fa-info-circle {
	    font-size: 13px;
    	color: #333333;
		}
		label {
			font-weight: bold;
			color: #666666;
			font-size: 12px;
		}
		.time .input-group-addon {
			padding-left: 10px;
			padding-right: 0;
		}
		.time-picker {
			border-left: 0;
			padding: 6px 10px;
			font-size: 12px;
		}
		textarea {
			@include rounded($medium-rounded);
			font-size: 12px;
		}
		.select2 .Select-control {
			width: 100%;
			.Select-placeholder {
				font-size: 12px;
			}
		}
		.time-input {
			border: thin solid #828282;
			border-radius: 5px;
			.input-group-addon {
				border: 0;
			}
			.rc-time-picker-input {
				border: 0 !important;
			}
			.time-picker {
				border: 0 !important;
			}
		}
		.file-loading-container {
			padding: 80px 0;
			.fa-spinner {
				font-size: 110px;
			}
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		margin: 0 auto;
		padding-bottom: 25px;
	}
  .checkbox-container {
  	color: #333333 !important;
    display: block;
    position: relative;
    padding-left: 24px;
    padding-top: 2px;
    cursor: pointer;
    font-size: 13px;
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #FFF;
    border: 1px solid #828282;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #564FDB;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #564FDB;
	}

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
	}

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.wrapper-modal-share-candidate {
	&.modal-dialog {
		width: 700px;
		max-height: 663px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 0px solid $border-color;
		h4 {
			color: #666666;
			font-size: 24px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		padding: 20px;
		.switcher-container {
			p {
				margin-left: 10px;
				margin-bottom: 0;
				display: inline;
				color: #707070;
				font-size: 12px;
				font-weight: normal;
			}
		}
		.form-container {
			margin-top: 20px;
			label {
				font-weight: 600;
				font-size: 12px;
				color: #707070;
				margin-bottom: 0;
			}
			.form-control {
				padding: 8px 10px;
				font-size: 12px;
				font-weight: normal;
				line-height: 17px;
				color: #666666;
				border-color: #C1C7CD;
			}
			textarea {
				height: 75px;
			}
			.react-tagsinput {
				min-height: 40px;
				max-height: 66px;
				border-radius: 5px;
				border: 1px solid #C1C7CD;
				overflow-y: auto;
				margin-top: 5px;
				.react-tagsinput-input {
					width: auto;
				}
				.react-tagsinput-tag {
					border: 1px solid #564FDB;
					background-color: transparent;
					border-radius: 5px;
					color: #666666;
					padding: 0 5px;
					font-size: 14px;
					font-family: inherit;
					font-weight: normal;
			    display: inline-flex;
			    line-height: 19px;
				}
				.react-tagsinput-remove {
					border-left: 1px solid #564FDB;
					padding: 0 2px 0 7px;
					margin-left: 5px;
				}
				.react-tagsinput-tag a::before {
					font-family: FontAwesome;
				  content: "\F00D";
				  font-size: 16px;
					color: #999999;
				}
			}
			.react-tagsinput--focused {
				border: 1px solid #C1C7CD;
			}
			.option-container {
				height: auto;
				width: 100%;
				min-height: 40px;
				max-height: 73px;
				border: 1px solid #C1C7CD;
				border-radius: 5px;
				padding: 5px 5px 0;
				word-wrap: break-word;
				word-break: break-word;
				overflow-wrap: break-word;
				overflow-y: auto;
				.option-div {
					display: -webkit-inline-flex;
					display: -ms-inline-flexbox;
					display: inline-flex;
					margin-bottom: 10px;
					margin-right: 10px;
					border: 1px solid #564FDB;
					border-radius: 5px;
					padding: 0;
					width: auto;
					.option-div-text {
						display: -webkit-box;
					  display: -ms-flexbox;
					  display: flex;
					  -webkit-box-orient: horizontal;
					  -webkit-box-direction: normal;
					      -ms-flex-flow: row wrap;
		          flex-flow: row wrap;
						padding: 0 5px;
						font-size: 14px;
						color: #666666;
					}
					.option-div-close {
						display: -webkit-box;
					  display: -ms-flexbox;
					  display: flex;
					  -webkit-box-orient: horizontal;
					  -webkit-box-direction: normal;
					      -ms-flex-flow: row wrap;
					          flex-flow: row wrap;
						align-items:center;
						padding: 0 7px;
						text-align: center;
						cursor: pointer;
						border-left: 1px solid #564FDB;
						i {
							font-size: 18px;
							color: #999999;
						}
					}
				}
			}
		}
		.list-container {
			.list-item-container {
				overflow-y: auto;
				height: 250px;
			}
			.input-group {
				border-bottom: 1px solid #DDDDDD;
				margin-top: 20px;
				margin-bottom: 5px;
				p {
					font-size: 12px;
					font-weight: 600;
					color: #707070;
					margin-bottom: 0;
					margin-left: 15px;
				}
			}
			.panel-group {
				margin-bottom: 0;
				border-bottom: 1px solid #DDDDDD;
				padding: 5px 0;
			}
			.panel {
				border: 0;
				border-radius: 0;
				-webkit-box-shadow: none;
				box-shadow: none;
				.panel-heading, .panel-body, .panel-footer {
					background-color: transparent;
					border: 0;
					padding: 0;
				}
				.panel-body {
					margin-left: 30px;
					border-top: 1px solid #DDDDDD;
					padding-top: 10px;
					.div-toggle-video {
						margin-left: 12px;
						a {
							font-weight: bold;
							color: #564FDB;
							font-size: 12px;
							i {
								color: #564FDB;
								font-size: 16px;
							}
						}
						.div-video {
							margin-left: 15px;
							.box-video {
								width: 180px;
								height: 180px;
								.rh5v-Overlay_inner {
									width: 50px;
									height: 50px;
									margin-left: -24px;
								}
								.rh5v-DefaultPlayer_component, .rh5v-DefaultPlayer_video {
									border-radius: 5px;
									width: 180px;
									height: auto;
								}
								.rh5v-DefaultPlayer_controls {border-radius: 0 0 5px 5px}
							}
						}
					}
				}
				.selected-container {
					font-size: 12px;
					font-weight: bold;
					color: #707070;
					i {
						color: #564FDB;
						font-size: 20px;
					}
				}
				.selected-container[aria-expanded='true'] > i {
					transform: rotate(-180deg) !important;
				}

				a[aria-expanded='true'] > i {
					transform: rotate(-180deg) !important;
				}

				.checkbox-addon-desc {
					margin-top: -6px;
				}
				.desc-question {
					// width: 415px;
					width: 94%;
				}
				.input-group {
					border-bottom: 0;
					margin-top: 0;
					margin-bottom: 0;
				}
				a {
					font-size: 12px;
					font-weight: 600;
					color: #707070;
					margin-bottom: 0;
					margin-left: 15px;
				}
			}
		}
		.fa-info-circle {
	    font-size: 13px;
    	color: #333333;
		}
		p {
			font-weight: bold;
			color: #666666;
			font-size: 12px;
		}
		.time .input-group-addon {
			padding-left: 10px;
			padding-right: 0;
		}
		.time-picker {
			border-left: 0;
			padding: 6px 10px;
			font-size: 12px;
		}
		textarea {
			@include rounded($medium-rounded);
			font-size: 12px;
		}
		.select2 .Select-control {
			width: 100%;
			.Select-placeholder {
				font-size: 12px;
			}
		}
		.time-input {
			border: thin solid #828282;
			border-radius: 5px;
			.input-group-addon {
				border: 0;
			}
			.rc-time-picker-input {
				border: 0 !important;
			}
			.time-picker {
				border: 0 !important;
			}
		}
		.file-loading-container {
			padding: 80px 0;
			.fa-spinner {
				font-size: 110px;
			}
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		margin: 0 auto;
		padding-bottom: 25px;
	}
	.checkbox-addon {
    border: 0;
    padding: 4px 12px 4px 0 !important;
	}
	.checkbox-container {
    display: inline;
    position: relative;
    padding-left: 15px;
    padding-top: 4px;
    cursor: pointer;
    font-size: 13px;
    line-height: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #FFF;
    border: 1px solid #828282;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container .checked ~ .checkmark {
    background-color: #564FDB;
	}

	.checkbox-container:hover .checked ~ .checkmark {
    background-color: #564FDB;
	}

	.checkbox-container .some-checked ~ .checkmark {
		opacity: 0.65;
	}

	.checkbox-container:hover .some-checked ~ .checkmark {
		opacity: 0.65;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container .checked ~ .checkmark:after {
    display: block;
	}

  /* Show the checkmark when checked */
  .checkbox-container .some-checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .button-save-changes {
  	width: 156px;
  }
}

.wrapper-modal-proctoring-report {
	&.modal-dialog {
		width: 800px;
		max-height: 650px;
	}
	.modal-header {
		padding: 18px 16px;
		border-bottom: 0px solid $border-color;
		h4 {
			color: #666666;
			font-size: 24px;
			font-weight: 400;
			display: inline-block;
		}
		.close-modal {
			display: inline-block;
			margin-right: 5px;
			cursor: pointer;
			i {
				font-size: 29px;
				color: #666666;
			}
		}
	}
	.modal-body {
		padding: 20px 16px 20px 16px;
		height: 580px;
		overflow-y: auto;
		table {
			width: 100%;
			thead {
				background-color: #D3D3D3;
				th {
					font-size: 14px;
					font-weight: bold;
					line-height: 16px;
					text-align: center;
					padding: 8px;
					color: #666666;
				}
			}
			tbody {
				td {
					font-size: 14px;
					font-weight: 400;
					color: #666666;
					line-height: 16px;
					text-align: center;
					padding: 10px;
					&.sus {
						font-weight: bold;
						&.red {
							color: #E83139;
						}
					}
					button {
						padding: 4px 7px;
						font-size: 12px;
						background-color: #564fdb;
						border-radius: 2px;
						color: white;
						i {
							margin-right: 7px;
							font-size: 14px;
						}
					}
				}
			}
			// tbody {
			//   display: block;
			//   max-height: 490px;
			//   overflow-y: auto;
			// }
			// thead, tbody tr {
			//   display: table;
			//   width: 100%;
			//   table-layout: fixed;
			// }
			// thead th, tbody td {
			//   &:first-child {
			//   	width: 6%;
			//   }
			// }
			// thead {
			//   width: calc( 100% - 1.1em );
			// }
		}
	}
}

.wrapper-modal-duplicate {
	&.modal-dialog {
		width: 645px;
		position: absolute !important;
		top: 45% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 0px solid $border-color;
		h4 {
			margin: 0;
			font-size: 18px;
			color: #000000;
			&.modal-title {
				margin-right: 20px;
				font-family: "Open Sans", Helvetica;
				font-style: normal;
				font-weight: bold;
				line-height: normal;
				padding-top: 25px;
			}
		}
		.close-modal {
			position: absolute;
			right: 18px;
			top: 10px;
		}
	}
	.modal-body {
		padding: 20px 35px 20px;
		label {
			font-weight: 600;
		}
		.form-control {
			@include rounded($none-rounded);
		}
		.select2 .Select-control {
			width: 100%;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		margin: 0 auto;
		padding-bottom: 25px;
	}
}

.wrapper-candidate-detail {
	.nav-candidate-detail {
		height: 20px;
		.nav-list {
			width: calc(100% - 50px);
			button {
				width: 50%;
				border-radius: 8px 0 0 0;
				border-right: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
			}
		}
	}
	.close-modal {
		width: 20px;
		height: 20px;
		right: 13px;
		top: 13px;
		i {
			font-size: 28px;
			color: #666666;
		}
	}
	.modal-header {
		border-bottom: 0px solid #B9B9B9;
		padding: 7px 15px 2px 15px;
	}
	.modal-content {
		background-color: #FFFFFF;
	}
	.modal-body {
		padding: 0;
	}
	.detail-header {
		position: relative;
		padding: 5px 5px 5px 20px;
		line-height: 20px;
		font-weight: 600;
		h4 {
			font-size: 24px;
			display: inline-block;
			margin-right: 15px;
			line-height: 1;
			a {
				cursor: default;
				color: #666666;
				font-weight: bold;
				@include ellipsis(350px);
				display: inline-block;
				vertical-align: bottom;
			}
			small {
				font-size: 14px;
				margin-left: 15px;
				display: inline-block;
				vertical-align: bottom;
				line-height: normal;
			}
		}
		.star-summary {
			vertical-align: text-top;
			border-radius: 10px;
			padding: 2px 10px;
			&.abu {
				background-color: #CCCCCC;
			}
		}
		p {
			margin-bottom: 0;
			color: #666666;
			font-weight: normal;
			line-height: 25px;
			visibility: visible;
			&:first-child {
				margin-bottom: 5px;
			}
		}
		.box-action {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
	.detail-actions {
		border-bottom: 1px solid #B9B9B9;
		.detail-job-name {
			border-right: 0px solid #B9B9B9;
			padding: 5px 38px;
		}
		.detail-tabs {
			padding: 0;
			.btn-image {
			}
			.nav > li {
				border-right: 0px solid #ddd;
				&:first-child {
					border-left: 0;
				}
				&:last-child {
					border-right: 0;
				}
				&.active, &:hover, &:focus {
					border-left: 0;
					border-bottom: 5px solid #564FDB;
					a {
						color: #564FDB;
						font-weight: bold;
					}
				}
				> a {
					padding: 9px 5px;
					border-bottom: 0;
					background: #FFFFFF;
					color: inherit;
					border: 0;
				}
			}
			// .nav > li:before {
			//   content : "";
			//   position: absolute;
			//   left    : 0;
			//   right: 0;
			//   margin: auto;
			//   bottom  : 0;
			//   height  : 1px;
			//   width   : 50%;  /* or 100px */
			//   border-bottom:10px solid red;
			// }
		}
	}
	.detail-body {
		padding: 0 15px;
		.box-detail-top {
			.tabs {
				padding: 20px 0;
			}
			.rating {
				font-size: 21px;
			}
			.tab-content .box-panel{
				&:not(:first-child) {
					.box-header {
						border-top: 0px solid #B9B9B9;
						padding-bottom: 1px;
					}
				}
			}
		}
		h4 {
			font-weight: bold;
		}
		.box-detail-bottom {
			border-top: 0px solid $border-color;
			padding: 25px 15px;
		}
	}
	/* width */
	::-webkit-scrollbar {
	  width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: #EEEEEE;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #999999;
	  border-radius: 3px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #999999;
	}
	.track-vertical {
    position: absolute;
    width: 4px !important;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
	}
	.thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: #999999;
    height: 107px;
    transform: translateY(174px);
	}
}

.wrapper-modal-customcode {
	&.modal-dialog {
		width: 377px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px;
		margin: 0 auto;
		text-align: center;
		.preview-code {
			width: 300px;
			background-color: $light-grey;
			padding: 5px 10px;
			text-align: left;
			margin: 0 auto;
			small {
				color: #A1A1A1;
			}
			h1 {
				font-size: 24px;
				font-weight: bold;
				color: $purple;
				margin: 8px 0 5px;
				text-align: center;
			}
		}
		h4 {
			margin-bottom: 25px;
		}
		input[type="text"] {
			width: 300px;
			@include rounded($none-rounded);
			margin: 0 auto;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
}

.wrapper-modal-advanced-filter {
	&.modal-dialog {
		width: 367px;
	}
	.modal-header {
		padding: 16px 24px 0px;
		border-bottom: 0;
		label {
			font-weight: bold;
			font-size: 24px;
			color: black;
		}
	}
	.modal-body {
		position: relative;
		padding: 16px 24px;
		margin: 0 auto;
		text-align: left;
		.input-container {margin-bottom: 16px;}
		label {display: block;}
		.input-company {
			width: 100%;
			background: #FFFFFF;
			border: 2px solid #E8E8E8;
			border-radius: 6px;
			color: #404040;
			font-size: 12px;
			padding: 5px 10px;
		}
		.Select-control {
			border: 2px solid #E8E8E8;
			border-radius: 6px;
			color: #404040;
			font-size: 12px;
		}
		.Select.is-focused:not(.is-open) > .Select-control {
	    border-color: #E8E8E8;
	    box-shadow: none;
	    background: #fff;
		}
		label {
			font-weight: normal;
			font-size: 12px;
			color: black;
		}
		.toggle-container {
			border-radius: 6px;
			border: 1px solid #CFCFCF;
		}
		.btn-status-toggle {
			color: #404040;
			width: 50%;
			text-align: center;
			padding: 14px 0;
			border: 0;
			&:first-child {
				border-radius: 6px 0 0 6px;
				border-right: 1px solid #CFCFCF;
			}
			&:last-child {
				border-radius: 0 6px 6px 0;
			}
			&.on {
				font-weight: bold;
				background: #E8E8E8;
			}
			&.off {
				font-weight: normal;
				background: #FFFFFF;
			}
		}
		.datepicker-container {
			.react-datepicker-wrapper {
				color: #404040;
				margin-left: 8px;
				input {
					background: #FFFFFF;
					border: 2px solid #E8E8E8;
					border-radius: 6px;
					width: 116px;
					height: 32px;
					color: #404040;
					font-size: 12px;
					padding: 3px 6px;
				}
			}
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: right;
		padding: 16px 24px;
		background: #F5F5F5;
		border-radius: 0px 0px 10px 10px;
		button {
			padding: 15px 30px;
			color: #404040;
			font-size: 14px;
			font-weight: bold;
		}
		.button-cancel {
			border: 1px solid #CFCFCF;
			border-radius: 6px;
			background: transparent;
		}
		.button-apply {
			background: #E8E8E8;
			border-radius: 6px;
			border: 0;
		}
	}
}

.wrapper-modal-download-all {
	&.modal-dialog {
		width: 480px;
		position: absolute !important;
    top: 45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
	}
	.modal-header {
		padding: 16px;
		border-bottom: 0;
		label {
			font-weight: 700;
			font-size: 18px;
			line-height: 27px;
			color: #333333;
			margin: 0;
		}
	}
	.modal-body {
		position: relative;
		padding: 20px 16px 16px;
		margin: 0 auto;
		text-align: left;
		border-top: 1px solid #E8E8E8;
		label {display: block;}
		.input-container {margin-bottom: 16px;}
		.input-container:last-child {margin-bottom: 0;}
		.input-company {
			width: 100%;
			background: #FFFFFF;
			border: 2px solid #E8E8E8;
			border-radius: 6px;
			color: #404040;
			font-size: 12px;
			padding: 5px 10px;
		}
		label {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #333333;
			margin-bottom: 8px;
		}
		.datepicker-container {
			.react-datepicker-wrapper {
				color: #404040;
				margin-left: 8px;
				input {
					background: #FFFFFF;
					border: 2px solid #E8E8E8;
					border-radius: 6px;
					width: 116px;
					height: 32px;
					color: #404040;
					font-size: 12px;
					padding: 3px 6px;
				}
			}
		}
		.input-container > input {
			border: 2px solid #E8E8E8;
			border-radius: 6px;
			padding: 12px 16px;
			width: 100%;
		}
		.input-container > p {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #404040;
			margin: 0;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: right;
		padding: 16px 24px;
		background: #F7F7FC;
		border-radius: 0px 0px 10px 10px;
		button {
			padding: 12px 32px;
			color: #404040;
			font-size: 14px;
			font-weight: bold;
		}
		.button-cancel {
			border: 2px solid #E8E8E8;
			border-radius: 6px;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			color: #564FDB;
			background: transparent;
		}
		.button-download {
			background: #564FDB;
			border-radius: 6px;
			border: 0;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
			min-width: 144px;
		}
		.button-save {
			background: #564FDB;
			border-radius: 6px;
			border: 0;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
		}
	}
}

.wrapper-modal-record-video {
	&.modal-dialog {
		width: 828px;
		margin: 15px auto 0;
	}
	.modal-header {
		padding: 15px 35px 10px 20px;
		border-bottom: none;
		i {
			font-size: 23px;
			color: #666666;
		}
		.dot-circle {
			padding: 0px 2px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: thin solid;
			border-radius: 15px;
		}
		h4 {
			font-size: 24px;
			font-weight: bold;
			color: #666666;
			margin-left: 10px;
		}
		.close-modal {
			position: absolute;
			right: 20px;
			top: 13px;
			i {
				font-size: 30px;
			}
		}
	}
	.modal-body {
		position: relative;
		padding: 20px;
		padding-top: 0;
		margin: 0 auto;
		text-align: center;
		.header-record {
			background-color: #343A40;
			padding: 18px 20px 14px 20px;
			border-radius: 5px 5px 0px 0px;
			.header-left {
				text-align: left;
				i {
					color: #CCCCCC;
					font-size: 13px;
				}
				span {
					color: white;
					font-size: 12px;
					margin-left: 10px;
					font-weight: 600;
				}
				p {
					color: white;
					font-size: 16px;
					margin-bottom: 0;
					margin-top: 6px;
					font-weight: 600;
				}
			}
			.header-volume {
				.volumes {
				  position: relative;
				  background: #333333;
				  display: inline-block;
				}
				.volume-bar {
				  transition: all 1s;
				  position: absolute;
				  left: 0;
				  right: 0;
				  bottom: 0;
				  height: 0;
				  background: #3DB223;
				}
				.volume-bar-container {
				  background: rgba(219,219,219,0.3);
				  margin: 0 auto;
				  position: relative;
				  height: 50px;
				  width: 10px;
				  &:after {
				    position: absolute;
				    top: 0;
				    bottom: 0;
				    right: 0;
				    left: 0;
				    content: " ";
				    background: linear-gradient(to top,
				      transparent 0, transparent 7%,
				      #343A40 7%,#343A40 10%,
				      transparent 10%, transparent 17%,
				      #343A40 17%, #343A40 20%,
				      transparent 20%, transparent 27%,
				      #343A40 27%,#343A40 30%,
				      transparent 30%, transparent 37%,
				      #343A40 37%,#343A40 40%,
				      transparent 40%, transparent 47%,
				      #343A40 47%,#343A40 50%,
				      transparent 50%, transparent 57%,
				      #343A40 57%,#343A40 60%,
				      transparent 60%, transparent 67%,
				      #343A40 67%,#343A40 70%,
				      transparent 70%, transparent 77%,
				      #343A40 77%,#343A40 80%,
				      transparent 80%, transparent 87%,
				      #343A40 87%,#343A40 90%,
				      transparent 90%, transparent 100%
				    );
				  }
				}
			}
		}
		.record-body {
			background-color: black;
			height: 70vh;
			max-height: 591px;
			border-radius: 0px 0px 5px 5px;
			position: relative;
			.video-player {
				height: 100%;
				max-width: 100%;
				border-radius: 0px 0px 5px 5px;
			}
			.div-control {
				width: 100%;
				background: rgba(52, 58, 64,0.75);
				border-radius: 0px 0px 5px 5px;
				padding: 10px 0;
				position: absolute;
				bottom: 0;
				span {
					font-size: 11px;
					font-weight: 600;
					color: white;
				}
				.button-retake {
					background-color: #E33434;
					width: 36px;
					height: 36px;
					border: 3px solid #FFFFFF;
					border-radius: 36px;
					text-align: center;
					margin-bottom: -3px;
					i {
						color: white;
					}
				}
				.button-done {
					background-color: #564FDB;
					width: 36px;
					height: 36px;
					border: 3px solid #FFFFFF;
					border-radius: 36px;
					text-align: center;
					margin-bottom: -3px;
					i {
						color: white;
					}
				}
				.button-start {
					background-color: transparent;
					border: 3.5px solid #FFFFFF;
					border-radius: 60px;
					height: 60px;
					width: 60px;
					font-size: 12px;
			    font-weight: bold;
			    color: white;
			    text-align: center;
			    i {
			    	font-size: 22px;
			    }
				}
				.button-start-now {
					background-color: transparent;
					border: 3.5px solid #FFFFFF;
					border-radius: 40px;
					height: 60px;
					width: 150px;
					font-size: 12px;
			    font-weight: bold;
			    color: white;
			    text-align: center;
			    i {
			    	font-size: 22px;
			    }
				}
			}
		}
	}
}

.CircularProgressbar {
  width: 100%;
  .CircularProgressbar-path {
    stroke: #3e98c7;
    stroke-linecap: square;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  .CircularProgressbar-trail {
    stroke: #d6d6d6;
  }
  .CircularProgressbar-text {
    fill: #3e98c7;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }
  .CircularProgressbar-background {
    fill: #d6d6d6;
  }
  &.circular-counter {
    .CircularProgressbar-trail {
      stroke: $white;
    }
    .CircularProgressbar-background {
      fill: $red01;
    }
    .CircularProgressbar-path {
      stroke: $red01;
    }
    .CircularProgressbar-text {
      fill: $white;
      font-size: 42px;
      font-family: 'Open Sans';
    }
    &.start {
      .CircularProgressbar-background {
        fill: transparent;
      }
      .CircularProgressbar-text {
        font-size: 24px;
      }
      .CircularProgressbar-path {
        stroke: $white;
      }
    }
    &.after-three {
        .CircularProgressbar-background {
          fill: transparent;
        }
        .CircularProgressbar-text {
          font-size: 24px;
        }
        .CircularProgressbar-path {
          stroke: $red01;
        }
      }
    &.finish {
      .CircularProgressbar-background {
        fill: $red01;
      }
      .CircularProgressbar-text {
        font-size: 24px;
      }
      .CircularProgressbar-path {
        stroke: $red01;
      }
    }
  }
}

.transparent-fill {
  fill: transparent;
}

.wrapper-saving-video {
	&.modal-dialog {
		width: 481px;
		margin: 30vh auto 0;
	}
	.modal-content {
		box-shadow: none;
		border: none;
	}
	.modal-body {
		position: relative;
		padding: 40px 30px;
		margin: 0 auto;
		text-align: center;
		span {
			color: #666666;
			font-size: 22px;
			font-weight: 600;
		}
		.fa-spinner {
			color: #564FDB;
			font-size: 60px;
		}
		.label-spinner {
			margin-top: 13px;
			p {
				color: #666666;
				font-size: 14px;
				margin-bottom: 0;
			}
		}
		.progress-bar-video {
			background-color: #DBDBDB;
			border-radius: 10px;
			height: 4px;
			margin-top: 32px;
			.progress-green {
				background-color: #30A54A;
				border-radius: 10px;
				height: 100%;
				width: 5%;
				&.finish {
					background-color: #2F80ED;
				}
			}
		}
	}
}

.wrapper-modal-introvideo {
	&.modal-dialog {
  	width: 720px;
  	height: 480px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px;
		margin: 0 auto;
		text-align: center;
		h4 {
			margin-bottom: 25px;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
	.videoModal {
		width: 100%;
	}
}

.wrapper-modal-welcomevideo {
	&.modal-dialog {
  	width: 720px;
  	height: 480px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px;
		margin: 0 auto;
		text-align: center;
		h4 {
			margin-bottom: 25px;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
	.videoModal {
		width: 100%;
	}
}

.wrapper-modal-addinfo {
	&.modal-dialog {
		width: 377px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px 25px;
		margin: 0 auto;
		text-align: center;
		.instruction {
			font-size: 12px;
			padding-right: 32px;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
}

.wrapper-profile {
	// background-color: $white;
	padding: 0px 15px;
	.form-group {
			margin-bottom: 0px;
		}
}

.username {
	display: inline-block;
	padding: 0px 5px;
	max-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: relative;
	top: 4px;
	text-transform: uppercase;
	@include ellipsis(120px);
}

/*
* CUSTOM INVITES
*/

.invite-list-container {
	.invite-list {
		padding: 10px 5px 0 0;
		border-bottom: 1px solid $border-color;
		label {
			width: 100%;
			cursor: pointer;
		}
		.dividing {
			border-bottom: 1px solid $border-color;
			display: inline-block;
			padding: 0 10px;
		}
		h4 {
			color: #555555;
			font-size: 13px;
			display: inline-block;
			padding-left: 10px;
			font-weight: normal;
			margin: 0;
			vertical-align: text-top;
		}
		.invite-stats {
			padding: 15px 0 5px 30px;
			-webkit-box-align: center;
					-ms-flex-align: center;
							align-items: center;
			font-weight: 600;
			> div {
				margin-right: 20px;
			}
		}
	}
}

/*
* CUSTOM ADDQUESTION
*/

.loadmore {
	margin-top: 20px;
}

.header-folder {
	margin: 0px 10px;
	.nav-folder {
		padding: 10px 0;
	}
}

.question-folder-library {
	border: 1px solid $border-color;
	@include rounded(8px);
	border-top-right-radius: 0;
	display: flex;
}

.wrapper-question {
	.folder-question {
		background: $white;
		border: 2px solid rgba(216, 216, 216,0.8);
		width: 180px;
		height: 100%;
		border-radius: 6px;
		color: #929292;
		margin: 10px 5px;
		box-shadow: 0 3px 2px rgba(0, 0, 0, 0.05);
		position: relative;
		.sticky {
			width: 100%;
			position: absolute;
			top: 0;
			z-index: 99;
			visibility: hidden;
		}
		h3 {
			margin: 0;
			padding: 10px 15px;
			font-size: 12px;
			font-weight: normal;
			line-height: 18px;
			position: relative;
			@include ellipsis(160px);
			.btn-folder {
				@extend .icon-i-folder;
			}
		}
		&:hover, &:focus, &.selected {
			border: 2px solid $purple;
			background: $purple;
			.sticky {
				z-index: 99;
				visibility: visible;
			}
			h3 {
				color: $white;
				.btn-folder {
					@extend .icon-i-folder-active;
				}
			}
		}
		&.active {
			border-color: $purple;
		}
	}
}

.img-container {
	margin: 10px 0;
	.img-preview-sm {
		position: relative;
		overflow: hidden;
		text-align: center;
		height: auto;
		max-width: 272px;
		img {
			max-width: 100%;
			max-height: 152px;
		}
	}
}

.img-container-ftq {
	margin: 10px 0;
	.img-preview-ftq {
		position: relative;
		overflow: hidden;
		text-align: center;
		height: 150px;
		width: 150px;
		img {
			max-width: 150px;
			max-height: 150px
		}
	}
}

/*
* CUSTOM WIZARD
*/

.wrapper-tabs {
	margin: 20px 0;
}
.wrapper-wizard {
	.heading-wizard {
		position: relative;
		padding: 15px 0 0;
		margin: 0 -20px !important;
		border-bottom: 4px solid $border-color;
		text-align: center;
		margin: 0 auto;
		ul.list-wizard > li {
			display: inline-block;
			&:not(:last-child) {
				&:after {
					font-family: 'fontAwesome';
					content: "\f178";
					font-size: 20px;
					padding: 0 20px;
					color: #909090;
				}
			}
			> a {
				font-size: 12px;
				color: #ADADAD;
				font-weight: bold;
				vertical-align: text-top;
				&.active, &:hover, &:focus {
					color: #5689D0;
				}
			}
		}
		.question-mode {
			border-top: 1px solid #d8d8d8;
			margin: 0 auto;
			background: #d8d8d8;
			h4 {
				color: #343434;
				margin: 0;
				padding: 10px 0;
				b {
					color: #5689D0;
				}
			}
		}
		.question-switcher {
			position: absolute;
			width: 130px;
			margin-left: 20px;
			padding: 10px 0;
			p {
				padding: 2px 0;
			}
		}
		.question-title {
			position: absolute;
			text-align: left;
			left: 20px;
			p {
				margin: 0;
				font-weight: 600;
				font-size: 13px;
			}
		}
	}
	.wrapper-subject-email {
		.left-div, .middle-div, .right-div {
			display: inline-block;
		}
		.middle-div {
			margin: 0 10px;
		}
		.right-div {
			p {
				font-weight: bold;
			}
		}
		p {
			display: block;
			color: #666666;
			font-size: 14px;
		}
	}
	.body-wizard {
		padding: 40px 0 20px;
		.tabs-wizard {
			padding: 30px 0 20px;
			ul > li {
				display: inline-block;
				> a {
					font-size: 14px;
					color: #a4a4a4;
					font-weight: bold;
					padding: 10px 15px;
					border-right: 1px solid $border-color;
					i {
						height: 16px;
					}
					&:hover, &:focus, &.active {
						color: #45667F;
					}
				}
			}
			.btn:first-child {
				margin-right: 15px;
			}
		}
	}
	.footer-wizard {
		text-align: right;
	}
}

.company-assess-swal {
	h2, p {
		color: #666666;
	}
	p {font-weight: 400;}
	.sa-button-container {
		button {
	    font-size: 12px;
	    padding: 8px 10px;
		}
	}
}

/*
* CUSTOM INVITE FORM
*/

.invite-form {
	margin: -30px 0 40px;
	.box-left {
		padding: 0;
		border: 1px solid $border-color;
		.text-from {
			border-radius: 5px 5px 0 0;
			background: #F2F2F2;
			color: #424242;
		}
		.text-subject {
			@include rounded($dropdown-rounded);
			border-top: 0;
			background: #F2F2F2;
			color: #424242;
		}
		.textarea-content {
			background: #F2F2F2;
			color: #424242;
			overflow: auto;
		}
	}
	.box-right {
		padding: 14px 20px;
		border: 1px solid $border-color;
		border-left: 0;
		h4 {
			text-align: center;
			font-size: 18px;
			color: #5B5B5B;
			margin-bottom: 20px;
		}
		&.preview {
			border-left: 1px solid $border-color;
		}
	}
}

.invite-form-new {
	margin: -30px 0 40px;
	.box-left {
		padding: 0;
		border: 1px solid #999;
		.text-from {
			border-radius: 5px 5px 0 0;
			background: #F2F2F2;
			color: #424242;
		}
		.text-subject {
			@include rounded($dropdown-rounded);
			border-top: 0;
			background: #F2F2F2;
			color: #424242;
		}
		.textarea-content {
			background: #F2F2F2;
			color: #424242;
			overflow: auto;
		}
	}
	.box-right {
		padding: 14px 20px;
		border-left: 0;
		h4 {
			text-align: center;
			font-size: 18px;
			color: #5B5B5B;
			margin-bottom: 20px;
		}
	}
}

.invite-form-new-send {
	margin: -30px 0 40px;
	.box-left {
		padding: 0;
		border: 1px solid #999;
		.text-from {
			border-radius: 5px 5px 0 0;
			background: #F2F2F2;
			color: #424242;
		}
		.text-subject {
			@include rounded($dropdown-rounded);
			border-top: 0;
			background: #F2F2F2;
			color: #424242;
		}
		.textarea-content {
			background: #F2F2F2;
			color: #424242;
			overflow: auto;
		}
	}
	.box-right {
		padding: 14px 20px;
		border-left: 0;
		h4 {
			text-align: center;
			font-size: 18px;
			color: #5B5B5B;
			margin-bottom: 20px;
		}
	}
}

.wrapper-question-preview {
	height: 781px;
	position: relative;
	padding: 10px;
	border: 1px solid $border-color;
	border-radius: 5px;
	margin-right: 15px;
	.footer-preview {
		position: absolute;
		bottom: -76px;
		text-align: right;
		width: 100%;
		padding: 21px 15px;
		border-left: 1px solid $border-color;
		height: 75px;
		margin-left: -11px;
	}
}

.question-preview {
	height: 700px;
	position: relative;
	border: 1px solid $border-color;
	@include rounded(5px);
	.footer-preview {
		text-align: center;
		margin:0 auto;
		padding: 6px 20px;
		flex: initial;
	}
}

.box-completion-message {
	height: 150px;
	position: relative;
	padding: 10px;
	border: 1px solid $border-color;
	@include rounded(5px);
	textarea {
		border: 1px solid #DDDDDD;
		border-radius: 5px;
		width: 100%;
		min-height: 80px;
		padding: 10px;
		font-size: 12px;
		line-height: 17px;
	}
	// ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	//   color: #E5E5E5;
	//   opacity: 1; /* Firefox */
	// }

	// :-ms-input-placeholder { /* Internet Explorer 10-11 */
	//   color: #E5E5E5;
	// }

	// ::-ms-input-placeholder { /* Microsoft Edge */
	//   color: #E5E5E5;
	// }
}

.question-list-preview {
	flex: 1;
	padding: 6px 4px;
}

.group-container {
	border: 1px solid #DDDDDD;
	border-radius: 5px;
	padding: 12px 0px 9px;
	margin-bottom: 12px;
	margin-top: 12px;
	.list__question-item {
		padding-left: 6px;
		padding-right: 6px;
	}
}

.group-container-section {
	border: 1px solid #DDDDDD;
	border-radius: 5px;
	padding: 0px 0px 0px;
	margin-bottom: 12px;
	margin-top: 12px;
	.list__question-item {
		padding-left: 6px;
		padding-right: 6px;
	}
}

.button-group-modal {
	border-radius: 5px;
	padding: 9px 36px;
	font-size: 16px;
	&.save-padding {
		padding-left: 10px;
	}
}

.sortableHelper {
	z-index: 10000 !important;
}

.group-button-container {
	padding: 7px 45px 0px;
	border-top: 1px solid #DDDDDD;
}

.wrapper-group-form {
	border: 1px solid #DDDDDD;
	border-radius: 3px;
	padding: 13px;
}

.container-group-drag-drop {
	margin-top: 10px;
	background-color: white;
	.list-group-question {
		padding: 7px;
		border-radius: 5px;
		box-shadow: 0px 0px 6px #00000029;
		.list__question-item {
			padding-bottom: 0;
		}
		.fa-times {
			color: #E65954;
		}
		button {
			background: transparent;
    	border: none;
		}
	}
}

.container-group-drag-drop-section {
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: white;
	.list-group-question {
		padding: 7px;
		border-radius: 5px;
		box-shadow: 0px 0px 6px #00000029;
		.list__question-item {
			padding-bottom: 0;
		}
		.fa-times {
			color: #E65954;
		}
		button {
			background: transparent;
    	border: none;
		}
	}
}

.wrapper-question-invitation {
	height: 781px;
	position: relative;
	padding: 0px;
	border-left: 0;
	margin-right: 15px;
	.footer-preview {
		position: absolute;
		bottom: -76px;
		text-align: right;
		width: 100%;
		padding: 21px 15px;
		height: 75px;
	}
}

.wrapper-question-invitation-preview {
	// height: 185px;
	// position: relative;
	padding: 0px;
	border-left: 0;
	border-bottom: 0;
	margin-right: 15px;
	.footer-preview {
		position: absolute;
		bottom: -76px;
		text-align: right;
		width: 100%;
		padding: 21px 15px;
		height: 75px;
	}
	.list__question-item {
		cursor: default;
	}
}

.section--title {
	h4 {
		font-size: 12pt;
		margin-top: 46px;
		padding: 0 15px;
	}
}

.selected--title {
	height: 73px;
	padding: 35px 15px;
	h4 {
		font-size: 12pt;
		margin: 0;
		padding-top: 10px;
	}
}

.folder--title {
	h4 {
		font-size: 12pt;
		margin: 0;
		padding-top: 10px;
		padding-left: 10px;
	}
}

.custom-setting, .header-setting {
	// border-bottom: 1px solid #D8D8D8;
	margin: 0 -20px;
	position: relative;
	background-color: $white;
}

.label-desc {
	font-size: 10px !important;
	font-weight: normal !important;
	color: #999999;
	margin-bottom: 3px;
	b {
		color: #999999 !important;
	}
}

.custom-setting {
	padding: 0 20px;
	border-top: 0;
	.option-container {
		height: auto;
		width: 100%;
		min-height: 40px;
		border: 1px solid #828282;
		border-bottom: 0;
		border-radius: 4px 4px 0 0;
		padding: 10px 0 0 10px;
		word-wrap: break-word;
		word-break: break-word;
		overflow-wrap: break-word;
		.option-div {
			display: -webkit-inline-flex;
			display: -ms-inline-flexbox;
			display: inline-flex;
			margin-bottom: 10px;
			margin-right: 10px;
			border: 1px solid #564FDB;
			border-radius: 5px;
			padding: 0;
			width: auto;
			.option-div-text {
				display: -webkit-box;
			  display: -ms-flexbox;
			  display: flex;
			  -webkit-box-orient: horizontal;
			  -webkit-box-direction: normal;
			      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
				padding: 0 5px;
				font-size: 14px;
				color: #666666;
			}
			.option-div-close {
				display: -webkit-box;
			  display: -ms-flexbox;
			  display: flex;
			  -webkit-box-orient: horizontal;
			  -webkit-box-direction: normal;
			      -ms-flex-flow: row wrap;
			          flex-flow: row wrap;
				align-items:center;
				padding: 0 7px;
				text-align: center;
				cursor: pointer;
				border-left: 1px solid #564FDB;
				i {
					font-size: 18px;
					color: #999999;
				}
			}
		}
	}
	.div-at-most {
		input.form-control {
			width: 40%;
			display: inline-block;
		}
	}
	p {
		color: #666666;
	}
	.textarea-additional {
		border-radius: 5px;
		width: 100%;
		height: 52px;
		font-size: 12px;
		padding: 10px;
		color: #888888;
	}
	.textarea-compliance {
		border-radius: 5px;
		width: 100%;
		height: 100px;
		font-size: 12px;
		padding: 10px;
		color: #888888;
	}
	.div-word-limits {
		padding-right: 7%;
		input.form-control {
			width: 40%;
			display: inline-block;
			font-size: 12px;
		}
		span {padding-top: 7px;}
	}
	.div-name-add{
		margin-bottom: 10px;
		.form-control {
			border: thin solid #828282;
			border-radius: 5px !important;
			font-size: 14px;
			padding: 4px 10px;
		}
		input.form-control {
			width: 56%;
			margin-right: 10px;
		}
		select.form-control {
			width: 40%;
		}
	}
	.checkbox-addon {
    border: 0;
    padding: 4px 12px 4px 0 !important;
	}
	.checkbox-container {
    display: inline;
    position: relative;
    padding-left: 15px;
    padding-top: 4px;
    cursor: pointer;
    font-size: 13px;
    line-height: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #FFF;
    border: 1px solid #999999;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #eee;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #564FDB;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.div-landing-page-label {
	label {
		font-size: 22px;
		color: #666666;
	}
}

.checkbox-purple-div {
	color: #666666;
	label {
  	font-size: 14px !important;
  	color: #666666 !important;
  }
	.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    padding-top: 2px;
    cursor: pointer;
    font-size: 13px;
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #eee;
    border: 1px solid #C1C7CD;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #eee;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #564FDB;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkbox-purple-div-big {
	color: #666666;
	word-wrap: break-word;
	word-break: break-word;
	overflow-wrap: break-word;
	label {
  	font-size: 14px !important;
  	color: #666666 !important;
  }
	.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    padding-top: 2px;
    cursor: pointer;
    font-size: 13px;
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #FFF;
    border: 1px solid #999999;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #eee;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #564FDB;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
// input[type="number"] {
//   -moz-appearance: textfield;
// }

@media screen and (min-width: 0px) {
	.days-after {
		width: 100% !important;
	}
}

@media screen and (min-width: 720px) {
	.days-after {
		width: 100% !important;
	}
}

@media screen and (min-width: 1500px) {
	.days-after {
		width: 50% !important;
	}
}

.header-setting {
	// border-top: 1px solid #D8D8D8;
	border-bottom: 0;
	padding: 10px 20px;
	.footer-preview {
		position: absolute;
		bottom: -76px;
		text-align: right;
		width: 90%;
		padding: 21px 15px;
		height: 75px;
	}
	.input-group {
		max-width: 100%;
	}
	.form-control {
		height: 40px;
	}
	.react-datepicker-wrapper {
		height: 35px;
		width: 100%;
	}
}

.wrapper-preview {
	.header-preview {
		h5 {
			font-size: 14px;
			text-transform: capitalize;
			padding: 10px 15px;
			background-color: $navy;
			color: $white;
		}
	}
	.body-preview {
		padding: 20px 10px;
	}
}

.box-header {
	margin-left: -22px;
	margin-right: -15px;
	padding: 10px 22px;
	border-bottom: 0px solid #B9B9B9;
	padding-bottom: 1px;
	.btn {
		border: 1px solid;
		padding: 2px 12px;
	}
	h4 {
		font-weight: bold;
		display: inline-block;
		margin-right: 20px;
	}
	&.question-choice {
		h4 {
			margin-right: 10px;
			color: #666666;
			font-weight: normal;
			font-size: 12px;
			cursor: pointer;
			&.active {
				color: #564FDB;
				font-weight: bold;
				border-bottom: 2px solid #564FDB;
			}
		}
	}
	.label-score-true {
		color: #666666;
		font-size: 28px;
		display: inline;
	}
	.label-score-all {
		color: #666666;
		font-size: 16px;
		display: inline;
		font-weight: normal;
	}
}

.box-body {
	padding: 0px 0;
}

.box-button {
	padding: 18px 0;
	text-align: center;
	p {
		color: #666666;
		font-weight: bold;
		font-size: 12px;
		span {
			color: #564FDB;
		}
	}
}

.box-body-history {
	font-size: 13px !important;
	padding-left: 12px;
	padding-right: 12px;
}

.table-head-history {
	background-color: lightgrey;
}

.tab-pane-profile {
	height: 460px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.box-panel {
	> h4 {
		margin-bottom: 20px;
	}
	.panel {
		background: transparent;
		@include rounded($none-rounded);
		border: 0;
		margin-right: 12px;
		.panel-heading {
			padding: 0;
			position: relative;
			display: block;
			margin-bottom: 5px;
			background-color: transparent;
			.wrap-player {
				position:relative;
			}
			h4 {
				padding: 0;
				a {
					padding: 10px;
					padding-right: 37px;
					position: relative;
					display: block;
					font-size: 12px;
					color: #4F4F4F;
					font-weight: normal;
					border: 2px solid #cdcdcd;
					line-height: 20px;
					background: $white;
					@include all-transition(0.3s,0s);
					&:hover, &:focus {
						border-color: $purple;
					}
					span {
						display: block;
						padding-left: 15px;
					}
				}
			}
		}
		.panel-body {
			border: 0;
			padding: 0 0px 15px;
		}
	}
	.status {
		position: absolute;
		float: right;
		top: 15px;
		right: 15px;
		&.dots:before {
			content: ' \25CF';
			font-size: 20px;
			color: $red;
		}
	}
	.wrapper-comments {
		background: #ffffff;
		border-radius: 5px;
		padding: 0 0px;
		h4 {
			margin-top: 0;
		}
		.media-body div {
			word-break: break-word;
		}
	}
	.wrapper-score {
		background: #ffffff;
		border-radius: 5px;
		padding: 15px;
		border: 2px solid #CDCDCD;
		h4 {
			margin-top: 0;
		}
		.media-body div {
			word-break: break-word;
		}
	}
	.scroll {
		height: 440px;
		overflow-y: auto;
	}
	.panel-group .panel-heading + .panel-collapse > {
		.panel-body {
			border-top: 0;
		}
		.list-group {
			border-top: 0;
		}
	}
}

.box-panel-document {
	> h4 {
		margin-bottom: 20px;
	}
	.panel {
		background: transparent;
		@include rounded($none-rounded);
		border: 0;
		margin-right: 12px;
		.panel-heading {
			padding: 0;
			position: relative;
			display: block;
			margin-bottom: 5px;
			background-color: transparent;
			.wrap-player {
				position:relative;
			}
			h4 {
				padding: 0;
				a {
					padding: 13px 40px 13px 16px;
					position: relative;
					display: block;
					font-size: 14px;
					color: $text-color;
					font-weight: normal;
					border: 2px solid #cdcdcd;
					line-height: 20px;
					background: $white;
					@include all-transition(0.3s,0s);
					&:hover, &:focus {
						border-color: $purple;
					}
					span {
						display: block;
						padding-left: 15px;
					}
				}
			}
		}
		.panel-body {
			border: 0;
			padding: 0 10px 15px;
		}
	}
	.status {
		position: absolute;
		float: right;
		top: 15px;
		right: 15px;
		&.dots:before {
			content: ' \25CF';
			font-size: 20px;
			color: $red;
		}
	}
	.wrapper-comments {
		background: #ffffff;
		border-radius: 5px;
		padding: 15px;
		h4 {
			margin-top: 0;
		}
		.media-body div {
			word-break: break-word;
		}
	}
	.wrapper-score {
		background: #ffffff;
		border-radius: 5px;
		padding: 15px;
		border: 2px solid #CDCDCD;
		h4 {
			margin-top: 0;
		}
		.media-body div {
			word-break: break-word;
		}
	}
	.scroll {
		height: 365px;
		overflow-y: auto;
	}
	.panel-group .panel-heading + .panel-collapse > {
		.panel-body {
			border-top: 0;
		}
		.list-group {
			border-top: 0;
		}
	}
}

.wrapper-video {
	padding:20px 30px;
	border-right: 0px solid #B9B9B9;
	.box-video {
		height: 386px;
		border: 1px solid #B9B9B9;
		border-radius: 5px;
		.rh5v-DefaultPlayer_component {
			border-radius: 5px;
		}
		.rh5v-DefaultPlayer_video {
			border-radius: 5px;
		}
		.rh5v-Overlay_component {
			border-radius: 5px;
		}
		.rh5v-DefaultPlayer_controls {
			border-radius: 5px;
		}
	}
	.video-info {
		padding-top: 10px;
	}
}

.wrapper-cv {
	height: 584px;
	border: 1px solid #ddd;
	border-radius: 10px;
}

.wrapper-comments {
	.comment-form {
		clear: both;
		position: relative;
		display: block;
		.comment-form__highlighter {
			padding: 0;
		}
		textarea {
			border: none;
			background-color: white !important;
			box-shadow: none;
			padding: 0;
		}
	}
	h4 {
		margin-top: 20px;
		padding: 10px 0;
	}
}
.swal-wide{
    width: 650px !important;
    left: 46% !important;
}
.sweet-alert p {
    max-width: 100%;
    font-weight: 400;
}
.total-comments {
	clear: both;
	display: block;
	font-weight: bold;
	padding: 0px 15px 0px;
	// border-bottom: 1px solid #cdcdcd;
	margin: 0 -15px 0px;
}

.box-action {
	position: relative;
}

.rc-duration, .rc-break-time {
	&.rc-time-picker {
		display: unset;
		@include rounded(4px);
	}
	.rc-time-picker-input {
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		height: 34px;
	}
}

.rc-max-time {
	&.rc-time-picker {
		display: unset;
		@include rounded(4px);
	}
	.rc-time-picker-input {
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		height: 36px;
		border: 1px solid #828282 !important;
		border-left: 0 !important;
		border-bottom-left-radius: 0 !important;
	  border-top-left-radius: 0 !important;
	}
}

.rc-date-custom {
	width: 100%;
	.rc-time-picker-input {
		height: 34px;
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #999999;
		border-radius: 5px 0px 0px 5px;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
		-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	}
}

.rc-time-picker-panel-select {
	width: 50%;
	overflow-x: hidden;
}

.rc-time-picker-panel-narrow {
	max-width: 115px;
}

/*
* CUSTOM USERS
*/

.profile-content {
	border-top: none !important;
}

.profile-stats {
	margin-right: 10px;
}

.profile-image {
	width: 120px;
	float: left;
	img {
		width: 96px;
		height: 96px;
	}
}

.profile-info {
	margin-left: 120px;
}

.feed-element {
	&:first-child {
		margin-top: 0;
	}
	padding-bottom: 5px;
	margin-top: 0px;
	.media {
		margin-top: 15px;
	}
}


.feed-element {
	> .pull-left {
		margin-right: 10px;
	}
	.user-feed {
		padding: 0 0 10px;
		border-bottom: 1px solid #979797;
		.time {
			font-weight: normal;
		}
	}
	.img-circle {
		width: 38px;
		height: 38px;
	}
	.media-body {
		vertical-align: middle;
		height: 35px;
		width: 425px;
		font-size: 12px;
		word-break: break-all;
		border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 6px 10px 8px 10px;
		.time {
			color: #999999;
		}
		strong {
			color: #666666;
			font-size: 14px;
		}
		.comment-text {
			padding-top: 10px
		}
		&.borderless {
			border: 0;
			padding: 5px 10px 0px 10px;
		}
		&.placeholder-comment {
			color: #999999;
			cursor: text;
		}
		&.public-media-body {
			width: 10000px;
		}
	}
}

.feed-element.list-div:before {
  content: "•";
  float: left;
  padding-top: 6px;
  font-size: 14px;
}

/*
* CUSTOM WIZARD
*/

.wizard-steps {
	list-style: none;
	display: table;
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
	li {
		display: table-cell;
		text-align: center;
		width: 1%;
		position: relative;
		.step {
			border: 2px solid $dark01;
			color: $dark01;
			font-size: 15px;
			border-radius: 100%;
			background-color: $white;
			position: relative;
			z-index: 2;
			display: inline-block;
			width: 40px;
			height: 40px;
			line-height: 34px;
			text-align: center;
		}
		&:not(:first-child) a {
			&:before {
				display: block;
				font-family: FontAwesome;
				content: "\F054";
				width: 100%;
				font-size: 20px;
				overflow: hidden;
				position: absolute;
				top: 10px;
				right: 24px;
				color: $dark01;
			}
		}
		&:before {
			display: block;
			content: "";
			width: 100%;
			font-size: 0;
			overflow: hidden;
			border-top: 4px solid $dark01;
			position: relative;
			top: 21px;
			z-index: 1;
			left: 50%;
		}
		&:last-child:before {
			border-top: 0;
			left:0;
		}
		&:first-child:before {
			max-width: 100%;
		}
		&.active + li a:before {
			color: $purple01;
		}
		&.active:before, &.complete:before, &.active .step {
			border-color: $purple01;
			background-color: $purple01;
			color: $white;
		}
		&.complete {
			.step {
				border-color: $purple01;
				cursor: default;
				color: $white;
				@include transform-transition(0.1s);
				&:before {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					line-height: 30px;
					text-align: center;
					border-radius: 100%;
					content: "\f00c";
					background-color: #FFF;
					z-index: 3;
					font-family: FontAwesome;
					font-size: 17px;
					color: #87ba21;
				}
			}
			&:hover {
				.step {
					-webkit-transform: scale(1.1);
					transform: scale(1.1);
					border-color: #80afd4;
				}
				&:before {
					border-color: #80afd4;
				}
			}
		}
		.title {
			display: block;
			margin-top: 4px;
			max-width: 100%;
			color: $dark01;
			font-size: 14px;
			z-index: 104;
			text-align: center;
			table-layout: fixed;
			word-wrap: break-word;
		}
		&.complete .title, &.active .title {
			color: $purple01;
		}
	}
}

.step-content {
	.step-pane {
		display: none;
		min-height: 200px;
		padding: 4px 8px 12px;
	}
	.active {
		display: block;
	}
}

.report-wizard, .invitation-wizard {
	padding: 0 0 20px;
	margin: 0 -20px;
	li a {
		cursor: default;
	}
}

.report-wizard {
	border-bottom: 1px solid $border-color;
}

.report-list-container {
	.react-datepicker-wrapper {
		width: 100%;
	}
	.react-bs-table {
		margin: 0;
		margin-top: 10px;
	}
	&.support-company-list {
		tr {
			cursor: pointer;
		}
	}
	&.account-action-list {
		tbody {
			tr {
				td:nth-child(2) {
					font-weight: 700;
					font-size: 16px;
					line-height: 24px;
					color: #564FDB;
					&:hover {
						text-decoration: underline;
					}
				}
				td:nth-child(5) {
					text-align: center !important;
					vertical-align: middle;
				}
				// td:nth-child(2) {
				// 	white-space: normal;
				// 	word-wrap: break-word;
				// }
			}
		}
	}
	&.user-list {
		tbody {
			tr:nth-last-child(-n+3) {
				.dropdown-menu {
					top: auto;
				  bottom: 100%;
				  margin-bottom: 2px;
				}
			}
		}
	}
}

.tag-channel {
	display: inline-block;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	padding: 8px 20px;
	border: 1px solid;
	border-radius: 40px;
	&.tag-blue {
		border-color: #4B6FF2;
		background-color: #ECF0FE;
		color: #103EE5;
	}
	&.tag-green {
		border-color: #58D6A8;
		background-color: #DEF7EE;
		color: #2CB582;
	}
	&.tag-purple {
		border-color: #A748E8;
		background-color: #F6EDFD;
		color: #8219C8;
	}
	&.tag-yellow {
		border-color: #F5BC48;
		background-color: #FEF8EC;
		color: #E99F0C;
	}
	&.tag-pink {
		border-color: #BD499B;
		background-color: #FFE9F9;
		color: #BD499B;
	}
	&.tag-cdc {
		border-color: #1D90D7;
		background-color: #EDF7FD;
		color: #1D90D7;
	}

}

.step-content {
	padding: 20px 10px;
	.step-footer {
		margin-top: 40px;
		.btn:first-child {
			margin-right: 15px;
		}
	}
}

.report-form {
	.select-custom, textarea, input[type="text"] {
		display: block;
	}
	textarea, input[type="text"] {
		@include rounded($none-rounded);
	}
	.form-group {
		label {
			font-weight: bold;
			&.radio-check {
				font-weight: normal;
			}
		}
	}
}

.full-width {
	.select2 .Select-control {
		width: 100%;
	}
	&.with-info {
		margin-right: -40px;
		> div:first-child() {
			> div:first-child() {
				width: 94%;
			}
		}
	}
}

.big-panel--title {
	font-weight: 600;
	text-transform: uppercase;
	margin: 0;
}

@media only screen and (max-width: 767px) {
	.wizard-steps li {
		.step {
			width: 30px;
			height: 30px;
			line-height: 24px;
			border-width: 3px;
		}
		&:before, &:after {
			border-width: 3px;
		}
		&.complete .step:before {
			line-height: 24px;
			font-size: 13px;
		}
		&:before {
			top: 16px;
		}
	}
	.step-content .step-pane {
		padding: 4px 4px 6px;
	}
}

/*
* CUSTOM TABLE
*/

.table.addbranding {
	text-align: center;
	.table-cell, .table-head {
		border:$border-color 1px solid;
		padding: 2px 5px;
	}
	.img-wrap {
		img {
			max-height:100px;
			width: auto;
		}
	}
}

/*
* CUSTOM CREATE JOB
*/

.create-job-container {
	margin-top: 40px;
	&.form-custom {
		.form-control {
			@include rounded($medium-rounded);
		}
		.radio-form {
			font-weight: normal;
			input[type="radio"], input[type="checkbox"] {
				vertical-align: sub;
			}
		}
		.select2 .Select-control {
			width: 100%;
		}
		i {
			font-style: normal;
			color: #999999;
		}
		.label-upload-video {
			font-size: 16px;
		}
		a.as-radio{
    color: #999;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
		}

		a.as-radio:hover{
		    color: #666;
		}

		a.as-radio.is-on{
		    color: #564FDB;
		}

		a.as-radio.is-on:hover{
		    color: #564FDB;
		}

		a.as-radio .the-box{
		    display: table-cell;
		    vertical-align: middle;
		    border: 1px solid #999999;
		    border-radius: 20px;
		}

		a.as-radio.is-on .the-box{
		    display: table-cell;
		    vertical-align: middle;
		    border: 1px solid #564FDB;
		    border-radius: 20px;
		}

		a.as-radio .the-box > span{
		    display: block;
		    width: 30px;
		    height: 30px;
		    box-sizing: border-box;
		    margin: 0 0 0 0;
		    background: white;
		    border: 2px solid white;
		    border-radius: 5px;
		    transition: all 200ms ease-in-out;
		    -moz-transition: all 200ms ease-in-out;
		    -webkit-transition: all 200ms ease-in-out;
		}

		a.as-radio.is-on .the-box > span{
		    background: #564FDB;
		    border: 2px solid #999;
		}

		a.as-radio .the-box > span{
		    border-radius: 20px;
		}

		a.as-radio:hover .the-box > span{
		    border: 5px solid white;
		}

		a.as-radio.is-on .the-box > span{
		    border: 5px solid white;
		}

		a.as-radio.is-on:hover .the-box > span{
		    border: 5px solid white;
		}

		a.as-radio > span{
		    font-size: 16px;
		    line-height: 18px;
		    height: 18px;
		    display: table-cell;
		    vertical-align: middle;
		    padding-left: 5px;
		}

		a.as-radio > span em{
		    font-style: normal;
		}
	}
}

a.as-radio-invite{
  color: #999;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}

a.as-radio-invite:hover{
    color: #666;
}

a.as-radio-invite.is-on{
    color: #564FDB;
}

a.as-radio-invite.is-on:hover{
    color: #564FDB;
}

a.as-radio-invite .the-box{
    display: table-cell;
    vertical-align: middle;
    border: 1px solid #999999;
    border-radius: 20px;
}

a.as-radio-invite.is-on .the-box{
    display: table-cell;
    vertical-align: middle;
    border: 1px solid #564FDB;
    border-radius: 20px;
}

a.as-radio-invite .the-box > span{
    display: block;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    background: white;
    border: 2px solid white;
    border-radius: 5px;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

a.as-radio-invite.is-on .the-box > span{
    background: #564FDB;
    border: 2px solid #999;
}

a.as-radio-invite .the-box > span{
    border-radius: 20px;
}

a.as-radio-invite:hover .the-box > span{
    border: 5px solid white;
}

a.as-radio-invite.is-on .the-box > span{
    border: 5px solid white;
}

a.as-radio-invite.is-on:hover .the-box > span{
    border: 5px solid white;
}

a.as-radio-invite > span{
    font-size: 16px;
    line-height: 18px;
    height: 18px;
    display: table-cell;
    vertical-align: middle;
    padding-left: 5px;
}

a.as-radio-invite > span em{
    font-style: normal;
}

/*
* CUSTOM EMPTY JOB
*/

.empty-job {
	padding: 100px 0;
	text-align: center;
	h1 {
		font-size: 20px;
		font-weight: bold;
		color: $purple;
		margin: 30px 0;
		opacity: 0.47;
	}
}

/*
* CUSTOM EMPTY CANDIDATE
*/

.empty-candidate {
	padding: 100px 0;
	text-align: center;
	margin: 0 auto;
	h1 {
		font-size: 20px;
		font-weight: bold;
		color: $purple;
		margin: 30px 0;
		opacity: 0.47;
	}
}


/*
* CUSTOM EMPTY QUESTIONS
*/

.empty-question {
	padding: 100px 0;
	text-align: center;
	margin: 0 auto;
	h1 {
		font-size: 20px;
		font-weight: bold;
		color: $purple;
		margin: 30px 0;
		opacity: 0.47;
	}
}

/*
* CUSTOM EMPTY FOLDER
*/

.empty-folder {
	margin: 25px 0;
	text-align: center;
	width: 100%;
	h1 {
		font-size: 20px;
		font-weight: bold;
		color: #6472c7;
		margin: 20px 0;
		opacity: 0.47;
	}
}

/*
* EMPTY SECTIONS
*/

.empty-section {
	padding: 40px 0;
	text-align: center;
	h1 {
		font-size: 20px;
		font-weight: 600;
		color: #5689D0;
		margin: 30px 0;
	}
	img {
		margin: 20px auto;
	}
}

/*
* CUSTOM EMPTY
*/

.empty-video, .empty-cv, .empty-addinfo {
	padding: 10px;
	text-align: center;
	// background-color: #ddd;
	background-color: #EEEEEE;
	height: 100%;
	border-radius: 5px;
	.placehold {
		border: 0px dashed $grey;
		background-color: #EEEEEE;
		height: 100%;
		h4 {
			font-size: 20px;
			color: #999999;
			font-weight: 600;
		}
		p {
			color: #999999;
			font-size: 14px;
		}
	}
}

.ftq-answer-div {
	// padding: 10px;
	background-color: #EEEEEE;
	height: 100%;
	border-radius: 5px;
	overflow-y: auto;
	word-wrap: break-word;
	word-break: break-word;
	overflow-wrap: break-word;
	p {
		text-align: left;
		color: #666666;
		margin-bottom:0px !important;
	}
}

#candidate_cv {
	height: 100%;
}

.empty-video {
	height: 386px;
	img {margin-left: -30px;}
}

.empty-addinfo {
	height: 440px;
	.placehold h4 {
		font-size: 22px;
	}
}

.empty-member {
	text-align: center;
}

/*
* CUSTOM ADDQUESTION
*/

.question-type {
	border-bottom: 1px solid $border-color;
	margin-bottom: 15px;
}

.result-list {
	border-top: 1px solid $border-color;
	padding-top: 20px;
	margin-top: 30px;
}

/*
* CUSTOM OVERLAY
*/

.wrapper-overlay {
	.overlay-content {
		color: $white;
		position: relative;
		top: 25%;
		width: 100%;
		text-align: center;
		height: 60vh;
		h1 {
			font-size: 22px;
			font-weight: bold;
		}
		a {
			color: $white;
		}
		p {
			font-size: 15px;
		}
		.video-container {
			margin: 20px 0;
		}
	}
}

.wrapper-overlay-import {
	.overlay-content {
		color: $white;
		position: relative;
		top: 25%;
		width: 100%;
		text-align: center;
		height: 25vh;
		h1 {
			font-size: 22px;
			font-weight: bold;
		}
		a {
			color: $white;
		}
		p {
			font-size: 15px;
		}
		.video-container {
			margin: 20px 0;
		}
	}
}

/*
* CUSTOM NOTIFICATIONS
*/

.wrapper-notification-list {
	li {
		a {
			font-size: 12px;
			color: $text-color;
			font-weight: normal;
			&.active, &:hover, &:focus {
				background-color: $purple;
				color: $white;
			}
			.boxes {
				width: 100%;
				min-height: 100px;
				.flex-between {
					height: 100%;
				}
				.flex-item {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					&.left {
						width: 20%;
						> div {
							margin: 0 auto;
						}
					}
					&.right {
						width: 80%;
					}
				}
				.media-body {
					small {
						float: right;
						margin-right: 10px;
					}
				}
			}
		}
		em {
			font-size: 10px;
		}
	}
}

/*
* 404 PAGE
*/

body {
	&.error-page {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.error {
			display: table;
			width: 100%;
			height: 100%;
			padding: 10px 0 20px 0;
			text-align: center;
			color: #fff;
			background: #071522 url(../img/stars.png) no-repeat bottom center scroll;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.error-body {
				display: table-cell;
				vertical-align: middle;
		}
		.error-content {
			position: relative;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.text, a {
				color: $white;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
				font-size: 19px;
				 margin: 10px 0 5px;
			 }
			}
			.img-notfound {
				background:transparent url(../img/404.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}


/*
* FILL PARAMETER PAGE
*/

body {
	&.fill-parameter-page {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.fill-parameter {
			display: table;
			width: 100%;
			height: 100%;
			padding: 10px 0 20px 0;
			text-align: center;
			color: #fff;
			background: #ffffff;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.fill-parameter-body {
				display: table-cell;
				vertical-align: middle;
		}
		.fill-parameter-content {
			position: relative;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.text, a {
				color: #343434;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
				font-size: 19px;
				 margin: 10px 0 5px;
			 }
			}
			.img-fill-parameter {
				background:transparent url(../img/fill-parameter.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}


/*
* JOB NOT FOUND PAGE
*/

body {
	&.error-page-web-sdk-page {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.error-page-web-sdk {
			display: table;
			width: 100%;
			height: 100%;
			padding: 10px 0 20px 0;
			text-align: center;
			color: #fff;
			background: #ffffff;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.error-page-web-sdk-body {
				display: table-cell;
				vertical-align: middle;
		}
		.error-page-web-sdk-content {
			position: relative;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.text, a {
				color: #343434;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
				  font-size: 19px;
					margin: 10px 0 5px;
					padding: 0px 22%;
    			line-height: 24px;
			  }
			}
			.text, h2 {
				margin-bottom: 30px;
			}
			.img-error-page-web-sdk {
				background:transparent url(../img/error.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 120px;
				margin: 0 auto;
			}
		}
	}
}


/*
* SUCCESS TEST PAGE
*/

body {
	&.success-page-test-page {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.test-qna-page {
			display: table;
			width: 100%;
			height: 100%;
			padding: 10px 0 20px 0;
			text-align: center;
			color: #fff;
			background: #ffffff;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.test-qna-page-body {
				display: table-cell;
				vertical-align: middle;
		}
		.test-qna-page-content {
			position: relative;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.text, a {
				color: #343434;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
				  font-size: 19px;
					margin: 10px 0 5px;
					padding: 0px 22%;
    			line-height: 24px;
			  }
			}
			.text, h2 {
				margin-bottom: 30px;
				padding-left: 15%;
				padding-right: 15%;
			}
			.img-test-qna-page {
				background:transparent url(../img/success.png) no-repeat top center;
				background-size: 105px;
				position:relative;
				width: 379px;
				height: 120px;
				margin: 0 auto;
			}
		}
	}
}


/*
* CUSTOM THUMBS
*/

.dropdown {
	&.ratings, &.notifs {
		width: 270px;
		margin-top: 20px;
	}
	&.custom {
		.dropdown-menu {
			margin-top: 10px;
			a {
				padding: 10px 20px;
			}
		}
	}
}

.dropbtn {
	cursor: pointer;
}

.dropdown-content {
	position: absolute;
	background-color: $white;
	width: 265px;
	right: 0;
	top: 100%;
	border: 1px solid #d8d8d8;
	z-index: 1000;
	@include animated(0.5s);
	@extend .fadeIn;
	a {
		display: inline-block;
		padding: 12px 14px;
		position: relative;
		text-align: center;
		.btn {
			min-height: 74px;
		}
		h5 {
			text-align: center;
			width: 103px;
			font-size: 12px;
			margin-bottom: 0;
			color: #4B4F85;
			&:hover, &:focus {
				color: #1A1D3B;
			}
		}
		&.dropdown-toggle {
			&:after {
				content: " ";
				height: 10px;
			}
		}
	}
	.summary, .detail {
		border-right: 1px solid #d8d8d8;
	}
	&.md-content {
		width: 200px;
	}
}

.dropup-content {
	display: none;
	position: absolute;
	top:-165px;
	right: 0;
	height: 160px;
	background-color: rgba(255,255,255,0.90);
	width: 36px;
	border-radius: 40px;
	padding: 6px 4px;
	border: 2px solid #6472C7;
	cursor: default;
	div {
		margin-bottom: 5px;
		small {
			color: #3D3D3D;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.dropup:hover {
	.dropup-content {
		display: block;
	}
}


.react-autosuggest__suggestions-container {
	position: absolute;
	z-index: 1000;
	max-height: 294px;
	overflow-y: auto;
	width: 100%;
	border-top: 0;
	margin-top: -2px;
	.react-autosuggest__suggestions-list {
		border: 1px solid #ddd;
	}
}

.react-autosuggest__container {
	.form-control:focus {
		outline: none;
		border-color: #ddd;
		box-shadow: none;
	}
}

.wrapper-custom-dropdown {
	width: 100%;
	font-size: 12px;
	background-color: $white;
	color: inherit;
	&:hover, &:focus {
		background-color: #f3f6ff;
		color: $body-text;
	}
	.boxes {
		padding: 10px 15px;
		width: 100%;
		h5 {
			margin: 0 0 5px;
			font-weight: bold;
		}
		p {
			margin: 0;
		}
		.flex-between {
			height: 100%;
		}
		.flex-item {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			&.left {
				width: 100%;
				> div {
					margin: 0 auto;
				}
			}
			&.right {
				width: 30%;
				.selection {
				 float: right;
				 a {
					 margin-right: 10px;
					 color: $purple;
				 }
				}
			}
		}
	}
}

/*
* CUSTOM EMAIL INVITATION PREVIEW
*/

.wrapper-preview-email {
	.bg-email {
		background: $text-color;
	}
	.wrapper-email {
		color: #000000;
		text-align: center;
		font-family:'Helvetica';
		width: 100%;
	}
	.company-logos {
		margin: 0 0 20px;
		max-height: 124px;
		width: auto;
		max-width: 190px;
	}
	.company-name {
		margin: 20px 0 15px;
		max-height: 124px;
		width: auto;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 25px;
	}
	.box-email {
		background: #F2F2F2;
		background-size: cover;
		padding: 35px 0;
		text-align: center;
		width: 100%;
		.templates {
			max-width: 600px;
			margin: 0 auto;
			text-align: left;
			.email-invitation {
				padding: 40px 30px 30px;
				margin: 0 auto;
				background: #FFFFFF;
				color: #666666;
				@include rounded($large-rounded);
			}
			.email-greetings {
				font-size: 24px;
				margin: 0 0 10px;
				font-weight: bold;
				font-family: 'Helvetica';
				line-height: 28px;
			}
			.email-content{
				font-size: 16px;
				margin: 0 0 10px;
				line-height: 24px;
			}
			.email-content-about{
				font-size: 16px;
				margin: 0 0 25px;
				line-height: 24px;
			}
			.email-content-header-about {
				font-size: 24px;
    		margin: 30px 0 10px;
				font-weight: bold;
				font-family: 'Helvetica';
				line-height: 28px;
			}
			.content-button-text {
				@extend .email-content;
				width : 410px;
				max-width : 420px;
				text-align: center;
			}
			.email-deeplink {
				text-decoration:none;
				background-color: #564FDB;
				color: #ffffff;
				font-size: 24px;
				font-weight: bold;
				padding: 10px 20px;
				border-radius: 30px;
				position: relative;
				display: inline-block;
				margin: 0 0 40px;
				line-height: 30px;
			}
			.email-content-code-top {
				background-color: #ffffff;
				padding: 1px 20px;
				position: relative;
				display: inline-block;
				z-index:1
			}
			.email-content-code-text-top {
				font-size: 12px;
				line-height: 12px
			}
			.email-content-code-bottom {
				background-color: #ffffff;
				padding: 15px 25px;
				border-radius:5px;
				border: 3px solid #666666;
				position: relative;
				display: inline-block;
				margin: -20px auto;
			}
			.email-content-code-text-bottom {
				font-size: 20px;
				line-height: 24px;
				margin-bottom: 0;
			}
			.email-content-ending-top {
				font-size: 16px;
				margin: 0 0 1px;
				line-height: 24px
			}
			.email-content-ending-bottom {
				font-size: 16px;
				margin: 0 0 30px;
				line-height: 24px
			}
			.main-intro {
				color: #000000;
				margin: 5px 0 20px;
				font-size: 18px;
				line-height: 25px;
			}
			.first-intro {
				font-size: 15px;
				margin: 0;
			}
			.second-intro {
				font-size: 15px;
				margin: 15px 0;
			}
			.box-code {
				.code {
					font-family: "Helvetica";
					font-size: 25px;
				}
			}
			.email-msg {
				font-size: 15px;
				max-width: 527px;
				padding: 0 20px;
				.n-m {
					margin: 0;
				}
				.m-b-40 {
					margin: 0 0 40px;
				}
				b.deadline {
					font-weight: bold;
				}
			}
			.ads {
				display: block;
				margin-bottom: 30px;
				position: relative;
				font-weight: bold;
			}
			.gplay, .appstore {
				max-height: 40px;
				margin:0 5px
			}
			.help {
				font-size: 15px;
				margin-bottom: 70px;
				.intro {
					margin-top: 30px;
				}
			}
			.help-link {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
						-ms-flex-flow: row wrap;
								flex-flow: row wrap;
				-ms-flex-pack: distribute;
						justify-content: space-around;
				font-size: 15px;
				width: 420px;
				margin: 0 auto;
				a {
					text-decoration: none;
					color: #000000;
				}
			}
			.email-footer {
				color: $white;
				font-family: "Helvetica";
				p {
					font-size: 12px;
					a {
						color:#3B7ADB;
						text-decoration:underline;
					}
				}
			}
		}
		.email-showcase__container {
			max-width: 260px;
			margin: 0 auto;
		}
		.email-showcase {
			max-width: 380px;
			display: inline-block;
			margin-bottom: 25px;
			display: flex;
			.text-left {
				max-width: 295px;
			}
		}
		.email-showcase__column {
			max-width: 260px;
			display: inline-block;
			margin-bottom: 40px;
		}
		.email-footer {
			padding: 20px 30px 30px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content:
			space-between;
			padding: 20px 30px 30px;
			text-align: left;
			font-size: 12px;
			color: $white;
			background-color: #564fdb;
			border-radius: 0px 0px 10px 10px;
			ul {
				li {
					float: left;
					a, a:hover, a:focus {
						color: $white;
						font-size: 12px;
					}
				}
			}
			img {
				max-height: 40px;
			}
			.email-footer__left {
				flex: 1;
			}
			.email-footer__right {
				width: 50%;
			}
		}
		.email-footer-first-margin {
			margin-right: 40px;
		}
		.email-footer-left-content {
			font-size: 12px ;
			line-height: 24px ;
			margin-left: 6px;
		}
		.email-footer-right-content {
			display: flex;
			flex-wrap: wrap;
		}
		.email-footer-second-margin {
			margin-right: 35px;
		}
		.email-footer-right-content-top {
			font-size: 12px ;
			margin: 18px 0px 12px;
		}
		.email-footer-right-content-bottom {
			font-size: 12px ;
			margin-top: 4px ;
			line-height: 17px;
		}
	}
}

.first-intro, .second-intro, .third-intro, .four-intro, .form-control-static {
	span.editable-invitation-template {
		border-bottom: 1px dashed #6472C7;
		word-break: break-word;
	}
	input, textarea {
		@include riek(block,100%);
	}
}

.form-control-static {
	span {
		border-bottom: 1px dashed #6472C7;
		word-break: break-all;
		&.non-editable {
			word-break: break-all;
		}
	}
	input, textarea {
		@include riek('inline-block','auto');
	}
}

.dropdown-menu{
	&.dropdown-template {
		border-radius: 0;
		> li > a {
			color: #4B4F85;
			padding: 5px 20px;
		}
	}
}

.template-name {
	color: #4B4F85;
}

.wrapper-modal-upload {
	&.modal-dialog {
		width: 549px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px 25px;
		margin: 0 auto;
		text-align: left;
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
	.box-intro {
		background-color: #ECECEC;
		padding: 10px 20px;
		font-size: 12px;
		h3 {
			font-weight: bold;
		}
		&.errors {
			h3, ul > li {
				color: #EC3333;
			}
		}
	}
}

.wrapper-modal-reason {
	&.modal-dialog {
		width: 400px;
		margin-top: 10%;
	}
	.modal-header {
		padding: 15px 15px 0 15px;
		border-bottom: 0;
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
}

.wrapper-modal-section {
	width: 935px;
	h2 {
		color: #666666;
		font-size: 24px;
		font-weight: bold;
		padding-bottom: 20px;
	}
	.wrapper-add-section {
		padding-bottom: 30px;
	}
	.modal-header {
		padding: 15px 15px 0 15px;
		border-bottom: 0;
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
	.error {
		margin-left: 0;
	}
	.rc-time-picker-input {
		border: 1px solid #828282;
	}
	.input-sm {
		border-radius: 5px;
	}
}

.box-image-preview {
	width: 100%;
	min-height: 150px;
	border: 1px solid $border-color;
	@include rounded(5px);
	margin-bottom: 20px;
	p {
		font-size: 12px;
		color: #BDBDBD;
		margin: 0 auto;
	}
}

.custom-modal-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid $border-color;
}

/*
* CUSTOM SELECT CANDIDATES
*/

.candidate-library {
	padding: 10px;
}

/*
* WRAPPER OPTIONS
*/

.wrapper-enter-question {
	padding: 10px 20px;
	border-bottom: 1px dashed #ded;
	margin-bottom: 10px;
	background: #eee;
}

.wrapper-enter-question-white {
	padding: 0px 20px;
	margin-bottom: 10px;
	background-color: transparent;
}

.wrapper-options {
	label {
		margin: 20px 0 0 0;
	}
	.option {
		padding-bottom: 10px;
		margin-bottom: 20px;
		border-bottom: 1px dashed #ddd;
		.img-container {
			padding: 0 20px;
		}
	}
	.option-list {
		margin-top: 20px;
		margin-bottom: 10px;
	}
}

.option-list {
	.input-group {
		width: 350px;
		.form-control:last-child {
			border: 1px solid #828282;
		}
	}
	div {
		margin-right: 10px;
	}
	.numeric {
		margin: 5px 0;
		width: 15px;
	}
}

/*
* PREVIEW JOB
*/

.wrapper-listing {
	width: 600px;
	margin-right: auto;
	margin-left: auto;
}

.job-listing {
	width: 100%;
	padding: 15px 0 60px;
	.job-detail {
		width: 100%;
		margin-bottom: 40px;
		color: #515353;
		h2 {
			font-size: 17px;
			font-weight: bold;
			margin-bottom: 14px;
		}
		.company-detail {
			padding: 0 30px 10px;
			.logo {
				text-align: left;
				.company-logo {
					max-height: 75px;
					width: auto;
					margin-left: 0;
				}
			}
		}
		.header-detail {
			padding: 0 30px 20px;
			h1 {
				font-weight: 600;
			}
		}
		.body-detail, .requirement-detail, .other-detail {
			padding: 15px 30px 20px;
			.content {
				white-space:pre-wrap;
				ul {
					list-style-type: disc;
					padding-left: 20px;
					margin-bottom: 0;
				}
			}
		}
		.desc-text {
			white-space: pre-wrap;
		}
		.download-box {
			background-color: #F9F9F4;
			width: 355px;
			height: 236px;
			color: #3E3E3E;
			border: 2px solid #D8D8D8;
			padding: 25px;
			text-align: center;
			margin: 28px auto;
			img {
				max-height: 40px;
			}
		}
		.border-line {
			border-top: 18px solid #404B73;
			margin-bottom: 20px;
		}
		.leftbox {
			background-color: #D8D8D8;
			text-align: left;
		}
	}
	.job-footer {
		padding-top: 40px;
		p {
			float: left;
			padding: 10px 0 0 10px;
			a {
				color: $white;
			}
		}
		.copy {
			text-align: right;
			padding: 10px 30px;
			background-color: #282828;
			margin: 0 -15px;
			a {
				color: $white;
			}
		}
	}
}

.react-datepicker, .react-datepicker__current-month {
	font-size: 1rem;
}

.react-datepicker__input-container {
	display: block;
}

/*
* DROPZONE
*/

.wrapper-dropzone {
	display: block;
	width: 100%;
	text-align: center;
	border: 2px dashed #d8d8d8;
	margin-top: 10px;
	padding: 12px;
	cursor: pointer;
	@include rounded($large-rounded);
	div {
		width: 200px;
		margin: 0 auto;
	}
	.img-upload {
		display: block;
		margin: 0 auto;
	}
	p {
		text-align: center;
		margin: 10px auto;
		color: #999999;
	}
}

.wrapper-dropzone-new {
	display: block;
	width: 100%;
	text-align: center;
	border: 2px dashed #999999;
	margin-top: 10px;
	padding: 27px 12px;
	cursor: pointer;
	border-bottom: 0;
	border-radius: 10px 10px 0 0;
	div {
		width: auto;
		margin: 0 auto;
	}
	.img-upload {
		display: block;
		margin: 0 auto;
	}
	i {
		margin-right: 10px;
		font-size: 34px;
		-webkit-transform: scaleX(-1);
  	transform: scaleX(-1);
	}
	p {
		display: inline;
		margin: 0;
		font-size: 12px;
		color: #999999;
	}
}

.record-video-button {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
	background-color: #999999;
	border: 0;
	padding: 9px 0;
	color: white !important;
	font-size: 12px;
	font-weight: bold;
	&:hover {
		background-color: #564FDB;
		color: white;
	}
	i {
		color: white !important;
		font-size: 16px;
	}
	.dot-circle {
		padding: 1px 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 5px;
		border: thin solid;
		border-radius: 15px;
	}
}

/*
* BIG COUNTER
*/

.big-counter {
  position: absolute;
  margin: 0 auto;
  top: 37%;
  text-align: center;
  width: 100%;
  .text {
    font-size: 260px;
    color: rgba(255,255,255,0.8);
  }
}

/*
* Alert
*/

.alert--light .text {
  border: 0px solid #CCCCCC;
  background: #FFFFFFBF;
}

.alert--top {
	position: absolute;
	top: 0;
	width: 100%;
  .text {
		border-radius: 10px;
		padding: 10px 95px;
		min-width: 369px;
		margin: 10px auto;
		text-align: center;
		color: #343A40;
		font-size: 18px;
		font-weight: 600;
  }
}

/*
* CUSTOM TABLE
*/

.custom-table {
	.table {
		margin-top: 10px;
	}
}

.preview-question-table {
	border: 1px solid $border-color;
	li {
		word-break: break-word;
	}
	.w95 {
		width: 95%;
		white-space: normal;
	}
	tr {
		th:not(last-child){
			border-right: 1px solid #D8D8D8;
		}
		td:not(last-child) {
			border-right: 1px solid #D8D8D8;
		}
	}
}

.not-interested-table {
	border: 1px solid $border-color;
	tr {
		th {
			background-color: #DDDDDD;
			&:first-child {
				width: 30%;
			}
			&:not(last-child){
				border-right: 1px solid #D8D8D8;
			}
		}
		td {
			background-color: #F1F1F1;
			&:not(last-child) {
				border-right: 1px solid #D8D8D8;
			}
		}
	}
}

/*
* CUSTOM Company Profile
*/

.logo-preview {
	max-height: 200px;
	width: auto;
	max-width: 260px;
	margin-right: 15px;
	display: block;
}

.cover-preview {
	max-height: 200px;
	width: auto;
	max-width: 400px;
	margin-right: 15px;
	display: block;
}

.question-switcher, .switcher {
	div {
		border-radius: 0 !important;
	}
}

.switcher {
	width: 100px;
	padding: 10px 0;
	p {
		padding: 2px 0;
	}
}


.company-profile-container {
	a.cancel {
		&:hover {
			text-decoration: underline;
		}
	}
}


/*
* CUSTOM JOB DETAIL
*/

.job-detail {
	h5 {
		padding: 0 20px 0 0;
		font-weight: bold;
	}
	.code-here {
		color: #F16C6C;
		font-weight: bold;
	}
}

.code-here {
	color: #F16C6C;
	font-weight: bold;
}

/*
* CUSTOM ADDITIONAL INFO
*/

.addinfo {
	margin-bottom: 10px;
	padding: 0;
	.input-group {
		// max-width: 265px;
	}
	.form-control {
		// max-width: 265px;
		// margin-right: 10px;
	}
	.input-group-addon {
    padding: 4px 12px;
	}
	.input-group-addon-remove {
		border-top: 0;
    border-right: 0;
    border-bottom: 0;
    padding: 4px 5px;
	}
}

/*
* CUSTOM WELL
*/

.well-tab {
	background: #eee;
	padding: 12px 6px;
	border: 1px solid #ddd;
	@include rounded(5px);
}

.well-tab-new {
	background: #eee;
	padding: 6px;
	border: 1px solid #ddd;
	@include rounded(5px);
}


/*
* CUSTOM VIDEO JS
*/
.rh5v-Volume_icon, .rh5v-Fullscreen_icon, .rh5v-PlayPause_icon {
	padding: 0;
}

/*
* CUSTOM PUBLIC PAGE
*/
.public-page {
	background-color: $white;
}

.question__instruction {
	h1 {
		font-size: 36px;
		font-weight: 600;
		color: #30307B;
		text-align: center;
		margin: 40px 0 25px;
	}
	.tips {
		padding: 0;
		> div {
			padding: 60px 20px;
			min-height: 355px;
			div {
				width: 100%;
			}
			i {
				margin: 0 auto;
			}
			h2 {
				margin: 50px auto 0;
				color: rgba(0, 0, 0, 0.54);
				font-size: 22px;
				font-weight: 600;
				text-align: center;
				width: 215px;
				line-height: 25px;
			}
		}
	}
	.tips-left, .tips-right {
		background: #83D8E3;
		h2 {
			color: rgba(0, 0, 0, 0.54);
		}
	}
	.tips-center {
		background: #B2E2E9;
		h2 {
			color: #1C1B6F;
		}
	}
}

.registration-box {
	background-color: #F8F8F8;
	padding: 30px 50px;
	h4 {
		text-align: center;
		font-weight: 600;
	}
	.form-group {
		font-size: 16px;
		line-height: 24px;
		width: 100%;
		height: 72px;
		display: inline-block;
		position: relative;
		background-color: transparent;
		font-family: Roboto, sans-serif;
		transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		margin-bottom: 20px;
		cursor: auto;
		label, .label {
			font-size: 18px;
			position: absolute;
			line-height: 22px;
			top: 30px;
			transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
			z-index: 1;
			transform: scale(0.75) translate(0px, -28px);
			transform-origin: left top 0px;
			pointer-events: none;
			user-select: none;
			color: #878787;
			font-weight: normal;
		}
		input[type="text"] {
			padding: 25px 0 0px;
			position: relative;
			width: 100%;
			border: none;
			outline: none;
			background-color: rgba(0, 0, 0, 0);
			color: rgba(0, 0, 0, 0.87);
			cursor: inherit;
			font-style: inherit;
			font-variant: inherit;
			font-weight: inherit;
			font-stretch: inherit;
			font-size: inherit;
			line-height: inherit;
			font-family: inherit;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			box-sizing: border-box;
			margin-top: 14px;
			-webkit-appearance: textfield;
		}
		textarea {
			width: 100%;
			resize: none;
			font-style: inherit;
			font-variant: inherit;
			font-weight: inherit;
			font-stretch: inherit;
			font-size: inherit;
			line-height: inherit;
			font-family: inherit;
			padding: 20px 0 0;
			cursor: inherit;
			position: relative;
			border: none;
			outline: none;
			background-color: rgba(0, 0, 0, 0);
			color: rgba(0, 0, 0, 0.87);
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			height: 162px;
			margin-top: 35px;
			box-sizing: border-box;
			-webkit-appearance: textfield;
		}
		hr {
			border-top: none #C3C3C3;
			border-left: none #C3C3C3;
			border-right: none #C3C3C3;
			border-bottom: 1px solid #C3C3C3;
			bottom: 8px;
			box-sizing: content-box;
			margin: 0px;
			position: absolute;
			width: 100%;
		}
		&.sm {
			height: 10px;
		}
		.label {
			top: 0;
			padding-left: 0;
		}
	}
}

.visible-box {
	visibility: hidden;
	height: auto;
	position: absolute;
}

.relative {
	position: relative
}

.box-list {
	label {
		font-size: 16px;
	}
	.form-group {
		margin-bottom: 10px;
	}
	.fullname, .description {
		border-bottom: 1px solid $border-color;
		margin-bottom: 20px;
	}
	.question {
		margin-bottom: 20px;
	}
	h1 {
		font-size: 21px;
		font-weight: bold;
		text-transform: capitalize;
	}
	h4 {
		font-weight: normal;
	}
}

.reason-list {
	padding: 20px;
}

.second-modal {
		z-index: 1051;
}

/*
* CUSTOM ADD SECTION
*/

.box-mobile-preview {
	border: 2px solid #ccc;
	@include rounded(5px);
	position: relative;
	.header-mobile {
		 border-bottom: 1px solid #ccc;
		 position: relative;
		 h3 {
			text-align: center;
			margin: 0;
			padding: 20px 0;
			color: $purple;
		 }
	}
	.body-mobile {
		min-height: 280px;
		position: relative;
		.mobile-content {
			text-align: center;
			padding: 0 20px;
			h1 {
				font-size: 20px;
				font-weight: 600;
				word-break: break-all;
			}
			h5 {
				font-size: 16px;
				font-weight: normal;
			}
			.question-img-preview {
				margin: 25px 0;
			}
			p {
				font-size: 14px;
				word-break: break-all;
			}
		}
	}
	.footer-mobile {
		border-top: 1px solid #ccc;
		text-align: center;
		padding: 5px 0 10px 0;
		position: relative;
		h5 {
			text-align: center;
			color: $purple;
			font-size: 14px;
			padding: 10px 0;
		}
		.box-time {
			background-color: #F4F4F4;
			min-height: 40px;
			border-radius: 5px;
			margin: 10px 20px;
			padding: 7px 0;
			text-align: center;
		}
	}
}

.mobile-preview__section {
	border-radius: 10px;
	margin-top: 20px;
	.indicator {
		border-radius: 10px 10px 0 0;
	  width: 100%;
	  height: 48px;
	  color: #FFFFFF;
	  padding: 14px 24px;
		&.info {
		  background-color: $purple01;
		}
	}
}

/*
* WRAPPER MAINTENANCE
*/

.wrapper-maintenance {
	margin-top: 40px;
	line-height: 32px;
	text-align: center;
	img {
		max-height: 40px;
	}
	h1 {
		line-height: 45px;
	}
}
.warning-msg, .success-msg, .import-msg {
	padding: 20px;
	h3, p {
		font-weight: normal;
		margin-top: 20px;
	}
}

/*
* CUSTOM DATEPICKER
*/

.datepicker-sm {
	width: 200px;
	display: inline-block;
}

/*
* RATING VIDEO
*/

.rating-video {
	height: 35px;
}

/*
* CUSTOM CHART
*/

.recharts-default-legend {
	right: -5px !important;
}

.chart-container {
	.recharts-wrapper {
		height: 430px !important;
	}
	.recharts-responsive-container {
		height: 460px !important;
	}
	.recharts-legend-wrapper {
		left: 65px !important;
	}
}


#nprogress {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.1);
	z-index:9999;
	.spinner {
		pointer-events: none;
		position: fixed;
		z-index: 999;
		height: 2em;
		width: 2em;
		overflow: show;
		margin: auto;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.member-list-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: flex-start;
	.member-list {
		flex-basis: 50%;
		margin-bottom: 10px;
	}
}

.duplicate-list-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: flex-start;
	background-color: #FAFAFA;
	padding: 20px 20px;
	text-align: left;
	margin: 0 auto;
	.duplicate-list {
		flex-basis: 100%;
		margin-bottom: 10px;
		height: 37px;
		border-bottom: 1px solid #E5E5E5;
	}
	.duplicate-list-all {
		flex-basis: 100%;
		margin-bottom: 10px;
		height: 37px;
		border-bottom: 1px solid #CCCCCC;
	}
}

a[aria-expanded="true"] {
	border-color: $purple01 !important;
}

#theme-purple {
	.page-title {
		margin-bottom: 24px;
	}
}

#public {
	.intro-signup {
	  padding-top: 76px;
	  padding-bottom: 76px;
	}
}

.text-center{
	text-align: center;
}

.question-display-input input[type=number]::-webkit-outer-spin-button,
.question-display-input input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
}

.automated-div {
	height: 36px;
  padding: 0 1px;
  border-radius: 5px;
  span {
  	font-size: 13px;
  }
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-top: 8px;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 28px;
    width: 28px;
    border-radius: 3px;
    background-color: white;
    border: 1px solid #999999;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: white;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #FFF;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 9px;
    top: 2px;
    width: 10px;
    height: 18px;
    border: solid #564FDB;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

//Quil border
.ql-container.ql-snow {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;

}

/* Snow Theme */
.ql-toolbar.ql-snow {
  display: block;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.div-separator-or {
	width: 100%;
  height: 10px;
  border-bottom: 1px solid #CCCCCC;
  text-align: center;
  margin-top: 14px;
}

.span-separator-or {
	font-size: 14px;
  background-color: white;
  color: #999999;
  font-weight: bold;
  padding: 0 10px;
}

.upload-img-bg {
	background-position: right;
	background-repeat: no-repeat;
	background-size: 30px 20px;
	margin-bottom: 0;
	background-origin: content-box;
}

.upload-img-bg input{
	opacity: 0;
	margin-top: -10px;
}


.img-bg-file {
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	margin-bottom: 0;
	width: 175px;
	font-size: 15px;
	color: lightgrey;
}

.uploaded-img {
	display: none;
}

.input-group-addon-color {
	padding: 6px 12px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  color: #564FDB;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #828282;
  border-radius: 4px;
}

.input-group-addon-up-image {
	padding: 3px 12px;
  color: #999999;
}

.input-group-addon-up-image-close {
	padding: 3px 0px;
  color: red;
  border-left: 0;
  border-right: 0;
  cursor: pointer;
  font-size: 18px;
}

.input-group-addon-search-job {
  padding: 1px 8px 0px;
  color: #999999;
  border: 0px solid #999999;
}

.input-group-addon-search-job-close {
	padding: 3px 0px;
	border: 0px solid #999999;
  color: red;
  border-left: 0;
  border-right: 0;
  cursor: pointer;
  font-size: 18px;
}

.input-group-addon-up-reset-color {
	padding: 3px 10px;
  color: #564FDB;
  text-decoration: underline;
  border-left: 0;
  border-right: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.social-media-div {
	display: inline-block;
	height: auto;
	width: 32%;
  color: #666666;
  padding: 0;
  margin-right: 10px;
  margin-top: 7px;
  border-radius: 5px;
  label {
  	font-size: 14px !important;
  	color: #666666 !important;
  }
  span {
  	font-size: 13px;
  }
  &.email {
		background-color: #564FDB;
  }
  &.facebook {
		background-color: #3B5998;
  }
  &.twitter {
		background-color: #1DA1F2;
  }
  &.linkedin {
		background-color: #0077B5;
  }
  &.whatsapp {
		background-color: #25D366;
  }
  .checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    padding-top: 2px;
    cursor: pointer;
    font-size: 13px;
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #FFF;
    border: 1px solid #999999;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #eee;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #564FDB;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

}

.upload-cv {
	background-image: url('../img/Icon-Upload-Init.svg');
	background-position: right;
	background-repeat: no-repeat;
	background-size: 30px 20px;
	margin-top: 5px;
	margin-bottom: 0;
	background-origin: content-box;
	height: 52px;
}

.upload-cv input{
	opacity: 0;
	margin-top: -10px;
}


.cv-file {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	margin-bottom: 0;
	margin-top: 10px;
	width: 175px;
	font-size: 16px !important;
	color: #666666;
}

.uploaded-cv {
	display: none;
}

.div-soc-container {
	height: 36px;
	font-size: 13px;
	color: #999999;
	font-weight: bold;
	.div-soc-med {
		width: 110px;
		height: 36px;
		border-radius: 5px;
		padding: 9px;
		color: white;
		font-weight: normal;
		margin-left: 10px;
		&.email {
			background-color: #564FDB;
		}
		&.facebook {
			background-color: #3B5998;
		}
		&.twitter {
			background-color: #1DA1F2;
		}
		&.linkedin {
			background-color: #0077B5;
		}
		&.whatsapp {
			background-color: #25D366;
		}
	}
	.div-soc-med-small {
		width: 36px;
		height: 36px;
		border-radius: 5px;
		padding: 6px;
		color: white;
		font-size: 18px;
		font-weight: normal;
		margin-left: 10px;
		&.email {
			background-color: #564FDB;
		}
		&.facebook {
			background-color: #3B5998;
		}
		&.twitter {
			background-color: #1DA1F2;
		}
		&.linkedin {
			background-color: #0077B5;
		}
		&.whatsapp {
			background-color: #25D366;
		}
	}
}

.form-control-cv {
	height: 52px;
}

.div-wisiwig {
	word-wrap: break-word;
	word-break: break-word;
	overflow-wrap: break-word;
	font-size: 12px;
	font-family: Arial, Helvetica, sans-serif;
	p {
		margin: 0;
	}
	img {
		max-width: 100%;
		max-height: 100%;
	}
	a {
		color: #564fdb;
	}
	ol {
		display: block;
    list-style-type: decimal;
    list-style: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
		li {
			list-style: decimal;
			display: list-item;
			list-style-position: inside;
    	text-align: -webkit-match-parent;
		}
	}
	ul {
		display: block;
    list-style-type: disc;
    list-style: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
		li {
			list-style: disc;
			display: list-item;
			list-style-position: inside;
    	text-align: -webkit-match-parent;
		}
	}
}

.ql-editor {
	a {
		text-decoration: unset !important;
	}
}

#job-landing-page-preview {
	.rh5v-DefaultPlayer_controls {
		border-radius: 0px 0px 10px 10px !important;
	}
}

.progress-upload-header-image {
	background-color: white;
	margin-top: -2px;
  margin-bottom: 0px;
  height: 7px;
  margin-left: 0px;
  margin-right: 1px;
  border-radius: 0px 0px 4px 4px;
}

.div-apply-now-button {
	margin-bottom: 40px;
}

.div-input-score {
	height: 100%;
	font-size: 16px;
	display:flex;
	justify-content:flex-end;
	align-items:center;
}

.input-score {
	width: 115px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #999999;
  margin-right: 5px;
  padding: 6px 10px;
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
}

.pointer-none{
	pointer-events: none;
}

.audio-playback-container {
  width: 100%;
  height: auto;
  background-color: transparent;
  border-radius: 5px;
  margin: 0 auto;
  color: #333333;
  padding: 0 10px;
  justify-content: center;
  .fa-play {
		font-size: 27px;
		margin-left: 6px;
  }
  i {
    font-size: 12px;
  }
  p {
  	font-size: 18px;
  	font-weight: normal;
  }
  span {
    padding: 0 10px;
    font-size: 18px;
    font-weight: bold;
    i {
    	margin-right: 10px;
    }
  }
  .div-timeline-audio {
    width: 285px;
    height: 5px;
    background-color: #3E3E3E;
    // border-radius: 10px;
    .div-timeline-audio-progress {
      // width: 30%;
      height: 5px;
      background-color: #5A5A5A;
      border-radius: 10px;
    }
  }
}

.p-video-control {
	margin-top: -5px;
}

.video-playback-container {
  width: 100%;
  height: 37px;
  background-color: #000000;
  border-radius: 0 0 4px 4px;
  margin: 0 auto;
  color: white;
  padding: 0 10px;
  justify-content: center;
  i {
    font-size: 12px;
    cursor: pointer;
  }
  span {
    padding: 0 10px;
    font-size: 12px;
    font-weight: bold;
  }
  .div-timeline-audio {
    width: 74%;
    height: 5px;
    background-color: #3E3E3E;
    // border-radius: 10px;
    .div-timeline-audio-progress {
      width: 30%;
      height: 5px;
      background-color: #5A5A5A;
      border-radius: 10px;
    }
  }
}

.video-question-container {
	video::-webkit-media-controls {
	  display: none;
	}

	/* Could Use thise as well for Individual Controls */
	video::-webkit-media-controls-play-button {}

	video::-webkit-media-controls-volume-slider {}

	video::-webkit-media-controls-mute-button {}

	video::-webkit-media-controls-timeline {}

	video::-webkit-media-controls-current-time-display {}

	border-radius: 4px 4px 0 0;
	background-color: black;
	.video-question {
		max-height: 210px;
		width: 100%;
		height: auto;
		outline: none;
		border-radius: 4px 4px 0 0;
	}
}

.progress-upload {
	background-color: #C7C7CC;
	margin-top: 0px;
  margin-bottom: 10px;
  height: 6px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 5px;
  .progress-bar-upload {
		float: left;
		width: 0%;
		height: 100%;
		font-size: 14px;
		line-height: 22px;
		color: #ffffff;;
		text-align: center;
		&.loading {
			background-color: #7B9A74;
		}
		&.completed {
			background-color: #2F80ED;
		}
		&.failed {
			background-color: #EA4D4D;
		}
	}
}

.label-filename-material {
	font-size: 12px;
	font-weight: 400;
	color: #000000;
	margin-bottom: 10px;
	i {
		color: #5A52D3;
		font-size: 14px;
		margin-right: 8px;
	}
}

.label-loading-container-material {
	label {
		font-size: 12px;
		font-weight: 400;
		color: rgba(0,0,0,0.5);
	}
	span {
		font-size: 10px;
		font-weight: 400;
		color: rgba(0,0,0,0.5);
		i {
			color: #EF4B50;
			margin-left: 6px;
			cursor: pointer;
		}
	}
}

.label-loading-container-material-error {
	label {
		font-size: 12px;
		font-weight: 400;
		color: #EC3333;
	}
	span {
		font-size: 10px;
		font-weight: 400;
		color: #EC3333;
		i {
			color: #EF4B50;
			margin-left: 6px;
			cursor: pointer;
		}
	}
}
.label-loading-container {
	i {
		font-size: 18px;
		margin-right: 10px;
		&.fa-cloud-upload {
			color: #7C9A76;
		}
		&.fa-check {
			color: #2F80ED;
		}
		&.fa-close {
			color: #EA4D4D;
		}
	}
	font-size: 12px;
}

.file-loading-container {
	padding: 12px;
	text-align: center;
	i {
		font-size: 60px;
		color: #564FDB !important;
	}
	p {
		margin-top: 20px;
	}
}

.file-uploaded-container {
	border: 1px solid #999999;
	color: #666666;
	border-radius: 5px;
	padding: 7px 10px;
	width: 93%;
	display: inline-block;
	overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  i {
  	margin-right: 8px;
  	font-size: 15px;
  	color: #5A52D3;
  }
  span {
  	font-size: 12px;
  }
}

.file-uploaded-container-small {
	border: 1px solid #999999;
	color: #666666;
	border-radius: 5px;
	padding: 7px 10px;
	width: 84%;
	display: inline-block;
	overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  i {
  	margin-right: 8px;
  	font-size: 15px;
  }
  span {
  	font-size: 12px;
  }
}

.wrapper-candidate-video-new {
	.container-fluid {
		margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
    background-color: #f0f0f0;
    .job-listing {
	    padding: 0;
	    .wrapper-joblist {
	    	background-color: #FFFFFF;
	    	padding: 20px;
	    	.divider-line {
	    		border-right: 1px solid #BDBDBD;
	    		padding-right: 20px;
	    	}
	    	&.present {
	    		padding: 30px;
	    	}
	    }
	    .wrapper-button {
	    	margin-top: 20px;
	    	text-align: right;
	    	button {
	    		font-weight: 400 !important;
	    		font-size: 16px !important;
	    	}
	    }
    }
    .header-wrapper {
    	background-color: #564FDB;
    	.company-detail {
    		padding: 20px 0;
    		.company-logo {
    			max-height: 60px;
    		}
    	}
    }
	}
	/* width */
	::-webkit-scrollbar {
	  width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: #EEEEEE;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #999999;
	  border-radius: 3px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #999999;
	}
	.track-vertical {
    position: absolute;
    width: 4px !important;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
	}
	.thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: #999999;
    height: 107px;
    transform: translateY(174px);
	}
	.company-title {
		font-size: 22px !important;
		font-weight: bold !important;
		color: #FFFFFF !important;
	}
	.rh5v-DefaultPlayer_component, .rh5v-DefaultPlayer_video {
		border-radius: 5px;
	}
	.rh5v-DefaultPlayer_controls {border-radius: 0 0 5px 5px}
	.rh5v-Overlay_inner {
		margin-left: -50px;
	}
	.button-back {
		padding: 10px 20px !important;
		font-weight: normal !important;
		font-size: 18px !important;
		color: #564FDB !important;
		min-width: 96px !important;
		border: 1px solid #564FDB !important;
		border-radius: 100px !important;
		i {
			color: #564FDB !important;
		}
		&:hover, &:focus, &:active {
			color: #564FDB !important;
		}
	}
	.collapsed {
		color: #4F4F4F !important;
		font-size: 16px !important;
	}
	.profile-detail-container {
		.detail-container {
			margin-top: 10px;
		}
		.button-download-cv {
			padding: 5px 10px !important;
			font-weight: bold !important;
			font-size: 12px !important;
			min-width: 100px !important;
		}
		.label-detail {
			display: inline-block;
			width: 26%;
		}
		.text-detail {
			display: inline-block;
			width: 74%;
		}
		label {
			color: #666666;
			font-weight: bold;
			font-size: 16px;
		}
		p {
			display: inline;
			color: #666666;
			font-weight: normal !important;
			font-size: 16px !important;
		}
	}
	.container-review-expired {
		height: 600px;
		// text-align: center;
		display:flex;
		justify-content:center;
		align-items:center;
		img {
			max-height: 138px;
		}
		p {
			font-size: 20px !important;
			font-weight: bold !important;
			color: #B7BDE5;
			line-height: 27px !important;
			margin-bottom: 0;
		}
	}
	.container-review-unsubscribed {
		height: 600px;
		// text-align: center;
		display:flex;
		justify-content:center;
		align-items:center;
		img {
			max-height: 200px;
		}
		.box-purple {
			padding: 20px 100px;
			border: 2px solid #645EDC;
		}
		p {
			font-weight: bold !important;
			color: #645EDC;
			margin-bottom: 0;
		}
		span {
			color: #666666;
			font-weight: normal;
		}
		a {
			font-weight: bold;
			color: #645EDC;
			text-decoration: underline;
		}
	}
	.container-review-finish {
		height: 600px;
		display:flex;
		justify-content:center;
		align-items:center;
		img {
			max-height: 200px;
		}
		.box-purple {
			padding: 20px 100px;
			border: 2px solid #645EDC;
		}
		p {
			font-weight: bold !important;
			color: #333333;
			margin-bottom: 0;
		}
		span {
			color: #404040;
			font-weight: normal;
		}
		a {
			font-weight: normal;
			color: #1D90D7 !important;
			text-decoration: underline;
		}
	}
	.container-review-list-candidate {
		height: 618px;
		// text-align: center;
		img {
			max-height: 200px;
		}
		p {
			font-size: 20px !important;
			font-weight: bold !important;
			color: #B7BDE5;
			line-height: 27px !important;
			margin-bottom: 0;
		}
		.candidate-public-list-container {
			display: inline-block;
			margin-right: 30px;
			margin-bottom: 30px;
			cursor: pointer;
			a {
				color: #6B97E2 !important;
				font-size: 14px;
				font-weight: bold;
				margin-top: 5px;
			}
    	.public-list {
    		position: relative;
    		.reviewed-box {
    			position: absolute;
				  top: 50%;
				  left: 50%;
				  transform: translate(-50%, -50%);
				  border: 1px solid white;
				  border-radius: 20px;
				  font-size: 12px;
				  color: white;
				  padding: 4px 16px;
    		}
    		&.reviewed {
    			img {
    				-webkit-filter: brightness(50%);
				       -moz-filter: brightness(50%);
				         -o-filter: brightness(50%);
				        -ms-filter: brightness(50%);
				            filter: brightness(50%);
    			}
    		}
    	}
    }
	}
}

.share-vote-container {
	label {
		font-size: 14px;
		line-height: 21px;
		font-weight: 600;
	}
	button {
		background: transparent;
		border: 1px solid #CFCFCF;
		border-radius: 6px;
		padding: 9px 17px;
		font-size: 12px;
		color: #808080;
		&.button-yes {
			&.active {
				border-color: #2CB582;
				color: #2CB582;
			}
		}
		&.button-no {
			margin: 0 8px;
			&.active {
				border-color: #ED2453;
				color: #ED2453;
			}
		}
		&.button-maybe {
			&.active {
				border-color: #F5BC48;
				color: #F5BC48;
			}
		}
		i {
			margin-right: 5px;
			font-size: 14px;
			&.fa-thumbs-o-down {
				transform: scaleX(-1);
			}
			&.fa-question {
				font-size: 17px;
			}
		}
		span {
			margin-left: 10px;
		}
	}
}

.navbar-support-dashboard {
	background-color: white;
	color: #333333;
	border-bottom: 1px solid #E2E5E6;
	padding: 24px 28px;
	.navbar:before, .navbar:after {
		content: none;
	}
	.navbar-header {
		label {
			color: #333333;
			font-size: 24px;
			font-weight: bold;
			margin: 0;
		}
	}
	.navbar-top-links {
		a {
			color: #373F41 !important;
			font-size: 14px;
		}
	}
	.dropdown-toggle {
		font-weight: normal !important;
	}
	.dropdown-menu {
		padding: 20px 8px !important;
		width: 210px !important;
		a {
			color: #404040 !important;
			padding: 7px 8px 12px !important;
		}
		a:hover {
			color: #404040 !important;
			background-color: #F6EDFD !important;
		}
	}
	.dropdown-user li:last-child {
    margin-right: 0;
	}
	.button-goto-dashboard {
		border: 1px solid #CFCFCF;
		border-radius: 6px;
		padding: 15px 36px !important;
		font-weight: bold !important;
		font-size: 14px !important;
		color: #404040 !important;
	}
	.fa-angle-down {
		margin-left: 12px;
		font-size: 16px;
	}
	.username-support {
		font-weight: 600;
	}
}

.div-support-container {
	padding-top: 120px;
	background-color: white;
	.main-content {
		background-color: white;
		min-height: 87vh;
	}
	.title-heading {
		font-size: 32px;
		text-transform: none;
		margin-bottom: 50px;
		max-width: 50%;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
	.react-bs-table {
		border-radius: 0 !important;
		border: 0 !important;
	}
	th, td {
		border-left: none !important;
		border-right: none !important;
	}
	.caret {
		color: #404040 !important;
		margin: 0 !important;
	}
	.order {
		margin-top: 8px;
	}
	th {
		font-size: 14px;
		span {float: right;}
		background-color: #E8E8E8;
	}
	.select-status-company {
		border: 2px solid #E8E8E8;
		border-radius: 6px;
		background: #FFFFFF;
		padding: 3px;
	}
	.select-status-user {
		margin: 0 14px;
		border: 2px solid #E8E8E8;
		border-radius: 6px;
		background: #FFFFFF;
		padding: 12px 16px;
		max-height: 48px;
	}
	.select-account-manager {
		margin: 0;
		width: 100%;
		border: 2px solid #E8E8E8;
		border-radius: 6px;
		background: #FFFFFF;
		padding: 11px 6px;
		max-height: 48px;
	}
	.select-status-payment {
		border: 2px solid #E8E8E8;
		border-radius: 6px;
		background: #FFFFFF;
		padding: 12px 6px;
		max-height: 48px;
		font-size: 14px;
		width: 100%;
	}
	.search-support-container {
		height: 48px;
		width: 324px;
		border-radius: 6px;
		padding: 12px 16px;
		border: 2px solid #E8E8E8;
	}
	.search-support {
		border: 0;
		background: transparent;
		font-size: 14px;
		width: 90%;
	}
	.fa-search {
		color: #404040;
		font-size: 20px;
	}
	.dropdown-user-support {
		overflow: inherit !important;
	}
	.react-bs-container-body{
    overflow: inherit !important;
	}
	.text-green {color: #2CB582;}
	.text-red {color: #ED2453;}
}

.div-support-create-user-container {
	padding: 12px 24px 60px;
	.back-button {
		background: #E8E8E8;
		border-radius: 6px;
		color: #404040;
		padding: 15px;
		font-weight: bold;
		font-size: 14px;
		border: 0;
		i{margin-right: 5px;font-weight: bold;}
	}
	hr {
		border-top: 1px solid #D8D8D8;
		margin: 19px -24px 40px;
	}
	.input-container {
		margin-bottom: 18px;
		div:first-child {
			padding: 0 16px;
		}
		label {
			color: #333333;
			font-size: 14px;
			font-weight: bold;
		}
		input, select {
			width: 100%;
			height: 56px;
			background: #FFFFFF;
			border: 1px solid #D8D8D8;
			border-radius: 5px;
			color: #404040;
			font-size: 14px;
			padding: 0 16px;
		}
		textarea {
			width: 100%;
			height: 200px;
			background: #FFFFFF;
			border: 1px solid #D8D8D8;
			border-radius: 5px;
			color: #404040;
			font-size: 14px;
			padding: 16px;
		}
	}
	.submit-button {
		background: #E8E8E8;
		border-radius: 6px;
		color: #404040;
		padding: 15px 32px;
		font-weight: bold;
		font-size: 14px;
		border: 0;
	}
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.div-support-materials {
	margin-top: 5px;
	.empty-file {
		border: 1px dashed #BDBDBD;
		border-radius: 5px;
		font-size: 12px;
		padding: 10px 12px;
	}
	.ada-file {
		font-size: 12px;
		margin-bottom: 15px;
		.titik-titik {
			opacity: 0.1;
			border-bottom: 1px dashed #000000;
			flex-grow: 1;
			margin-bottom: -8px;
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	.download-file {
		border-top: 1px solid rgba(0,0,0,.1);
		text-align: center;
		margin-left: -24px;
		margin-right: -24px;
		padding-top: 10px;
		a {
			font-size: 12px;
			font-weight: bold;
			color: #5A52D3;
			i {
				margin-right: 5px;
			}
		}
	}
}

.company-detail-card {
	border: 1px solid #D8D8D8;
	border-radius: 5px;
	padding: 12px 16px;
	box-sizing: border-box;
	label {color: #404040;}
	&.detail-card {
		height: 100%;
		display: flex;
		flex-direction: column;
		.logo-desc-container {
			flex: 1;
			.logo-container {
				width: 164px;
				height: 100%;
				display: inline-block;
				.logo-div {
					height: 174px;
				}
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
			.desc-container {
				display: block;
				padding-left: 16px;
				label {
					font-size: 18px;
					max-width: 39vw;
					white-space: nowrap;
					overflow: hidden !important;
					text-overflow: ellipsis;
				}
				span {
					display: block;
					margin-bottom: 11px;
					font-size: 16px;
					max-width: 39vw;
					white-space: nowrap;
					overflow: hidden !important;
					text-overflow: ellipsis;
				}
				p {
					font-size: 16px;
				}
				.p-desc {
					margin: 0;
					overflow-y: auto;
					height: 267px;
				}
				/* width */
				::-webkit-scrollbar {
				  width: 4px;
				}

				/* Track */
				::-webkit-scrollbar-track {
				  background: #EEEEEE;
				}

				/* Handle */
				::-webkit-scrollbar-thumb {
				  background: #999999;
				  border-radius: 3px;
				}

				/* Handle on hover */
				::-webkit-scrollbar-thumb:hover {
				  background: #999999;
				}
				.track-vertical, .track-horizontal {
			    position: absolute;
			    width: 4px !important;
			    right: 2px;
			    bottom: 2px;
			    top: 2px;
			    border-radius: 3px;
				}
				.thumb-vertical, .thumb-horizontal {
			    position: relative;
			    display: block;
			    width: 100%;
			    cursor: pointer;
			    border-radius: inherit;
			    background-color: #999999;
			    height: 107px;
			    transform: translateY(174px);
				}
			}
		}
	}
	&.admin-card {
		max-height: 111px;
		span {
			color: #404040;
			font-size: 16px;
			font-weight: 600;
		}
		p {
			font-size: 14px;
			color: #808080;
			margin-bottom: 0;
		}
	}
	&.account-manager-card {
		max-height: auto;
		background: #EFF0F6;
		border: 0;
		span {
			color: #404040;
			font-size: 16px;
			font-weight: 600;
		}
		p {
			font-size: 14px;
			color: #808080;
			margin-bottom: 0;
		}
	}
	&.credit-card {
		max-height: 156px;
		label {margin-bottom: 0;}
		a {
			font-weight: 600;
		}
		span {
			font-weight: normal;
			font-size: 16px;
			&.green {color: #2CB582;font-weight: 600;font-size: 18px;}
			&.red {color: #ED2453;font-weight: 600;font-size: 18px;}
		}
		p {
			font-weight: normal;
			font-size: 14px;
			line-height: 21px;
			color: #808080;
		}
		.not-applied {
			display: block;
			width: 100%;
			text-align: center;
			box-sizing: border-box;
			border: 1px dashed #CFCFCF;
			margin-top: 10px;
			padding: 25px;
			background-color: #F7F7FC;
			border-radius: 6px;
			p {
				text-align: center;
				margin: 10px auto;
				color: #404040;
				size: 14px;
			}
		}
	}
}

.voucher-detail-info {
	margin: 0 0 16px;
	label, p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		margin: 0;
	}
	label {
		font-weight: 600;
		width: 20%;
		display: inline-block;
		vertical-align: top;
	}
	p {
		display: inline-block;
		width: 80%;
	}
}

.company-edit-card {
	border: 0;
	padding: 12px 16px;
	box-sizing: border-box;
	.sub-heading {
		font-weight: bold;
		font-size: 24px;
		line-height: 36px;
		color: #333333;
	}
	label {color: #404040;}
	&.edit-card {
		height: auto;
		display: flex;
		flex-direction: column;
		.logo-desc-container {
			flex: 1;
			.logo-container {
				width: 300px;
				height: 100%;
				display: inline-block;
				.logo-div {
					height: 200px;
				}
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
			.desc-container {
				display: block;
				padding-left: 32px;
				label {
					font-size: 18px;
				}
				p {
					font-size: 16px;
				}
			}
		}
		.input-container {
			margin-bottom: 18px;
			label {
				color: #333333;
				font-size: 14px;
				font-weight: bold;
			}
			input, select {
				width: 100%;
				height: 56px;
				background: #FFFFFF;
				border: 1px solid #D8D8D8;
				border-radius: 5px;
				color: #404040;
				font-size: 14px;
				padding: 0 16px;
			}
			textarea {
				width: 100%;
				height: 200px;
				background: #FFFFFF;
				border: 1px solid #D8D8D8;
				border-radius: 5px;
				color: #404040;
				font-size: 14px;
				padding: 16px;
			}
		}
	}
}

.company-update-credit-card {
	border: 0;
	padding: 12px 16px;
	box-sizing: border-box;
	.sub-heading {
		font-weight: bold;
		font-size: 32px;
		line-height: 48px;
		color: #404040;
		margin: 0;
	}
	.sub-title {
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		color: #808080;
	}
	label {color: #404040;}
	&.edit-card {
		height: auto;
		display: flex;
		flex-direction: column;
		.div-credit-container {
			flex: 1;
			.credit-container {
				width: 49.5%;
				height: 100%;
				display: inline-block;
				background: rgba(86, 79, 219, 0.1);
				padding: 36px 24px 16px;
				border-radius: 6px;
				.simulation-container {
					height: 130px;
					.coin-div {}
					.credit-div {
						text-align: right;
						label:first-child {
							font-weight: bold;
							font-size: 24px;
							line-height: 36px;
							color: #404040;
						}
						label:last-child {
							font-weight: bold;
							font-size: 48px;
							line-height: 72px;
							color: #564FDB;
						}
					}
				}
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
			.action-container {
				label {
					font-size: 18px;
					line-height: 27px;
					font-weight: 600;
					color: #404040;
					margin: 0;
				}
				&.simulation {
					button {
						width: 36px;
						height: 36px;
						border-radius: 50%;
						background: #564FDB;
						border: 0;
						color: white;
					}
					input {
						width: 120px;
						height: 48px;
						text-align: center;
						border-radius: 6px;
						border: 2px solid #E8E8E8;
						margin: 0 14px;
						font-weight: bold;
						font-size: 16px;
						line-height: 24px;
						color: #404040;
					}
				}
				&.current {
					.datepick-container {
						padding: 12px 16px;
						border-radius: 6px;
						border: 2px solid #E8E8E8;
						background-color: #FFFFFF;
						input {
							font-weight: normal;
							font-size: 16px;
							line-height: 24px;
							color: #404040;
							border: 0;
						}
						.fa {font-size: 18px;}
					}
				}
			}
		}
		.input-container {
			margin-bottom: 18px;
			label {
				color: #333333;
				font-size: 14px;
				font-weight: bold;
			}
			input, select {
				width: 100%;
				height: 56px;
				background: #FFFFFF;
				border: 1px solid #D8D8D8;
				border-radius: 5px;
				color: #404040;
				font-size: 14px;
				padding: 0 16px;
			}
			textarea {
				width: 100%;
				height: 200px;
				background: #FFFFFF;
				border: 1px solid #D8D8D8;
				border-radius: 5px;
				color: #404040;
				font-size: 14px;
				padding: 16px;
			}
		}
	}
}

.metrics-container {
	margin-top: 24px;
	> div {
		width: 20%;
		padding: 0 12px;
	}
	.datepick-container-new {
		padding: 11px 16px;
	}
	.react-datepicker-wrapper {width: 60%;}
	.react-datepicker__input-container {
		display: inline-block;
		width: 100%;
		input {width: 100%;}
	}
	.input-credits-value {
		width: 100%;
		padding: 12px 16px;
		border-radius: 6px;
		border: 2px solid #E8E8E8;
		background-color: #FFFFFF;
	}
}

.table-credit-container {
	padding: 24px;
	margin: 16px 8px;
	border: 1px solid #D8D8D8;
	border-radius: 5px;
	box-sizing: border-box;
	label {
		font-size: 16px;
		font-weight: 700;
		color: #333333;
		margin: 0;
	}
}

.dropdown-upgrade {
	padding: 8px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;;
	border-radius: 6px !important;;
	a {
		padding: 0 8px !important;
		font-weight: normal !important;
		font-size: 18px !important;
		line-height: 43px !important;
		color: #404040 !important;
	}
	a:hover {background-color: #F6EDFD !important;}
}

.badge-company {
	padding: 8px 24px;
	border-radius: 40px;
	box-sizing: border-box;
	max-height: 34px;
	font-size: 12px;
	font-weight: 600;
	border: 0;
	&.paid {
		background: #DEF7EE;
		color: #2CB582;
	}
	&.trial {
		background: #F6EDFD;
		color: #8219C8;
	}
	&.api {
		background: #ECF0FE;
		color: #103EE5;
	}
	&.testing {
		background: #FEF8EC;
		color: #E99F0C;
	}
	&.suspended {
		background: #FDECF0;
		color: #BD0F38;
	}
	&.expired {
		background: #FDECF0;
		color: #BD0F38;
	}
	&.university {
		background: #EDF7FD;
		color: #1D90D7;
	}
}

.hr-detail {
	border-top: 1px solid #E8E8E8;
	margin: 11px -16px 17px;
}

.hr-credit {
	border-top: 1px solid #E8E8E8;
	margin: 8px 0;
}

.hr-credit-purple {
	border-top: 1px solid #9D99EA;
	margin: 8px 0;
}

.input-search-container {
	height: 56px;
	width: 100%;
	border-radius: 5px;
	padding: 0 16px !important;
	border: 1px solid #D8D8D8;
	background: #FFFFFF;
  color: #404040;
	.input-search{
		border: 0 !important;
		padding: 0 !important;
		background: transparent !important;
		font-size: 14px !important;
		width: 90% !important;
		color: #404040 !important;
	}
	.fa-search {
		color: #404040;
		font-size: 20px;
	}
}

.review-finish-container {
	label {
		font-size: 24px;
		margin-bottom: 12px;
		color: #333333;
	}
	p {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 0;
		color: #404040;
	}
	.swal2-modal {
		width: 834px;
		height: 550px;
		border-radius: 10px;
	}
	img {
		margin-bottom: 24px;
	}
	.swal2-confirm {
		background-color: #564FDB;
		border-radius: 100px;
		border: 0;
		padding: 9px 56px;
		font-size: 16px;
		color: white;
	}
	.swal2-actions {
		margin-top: 36px;
	}
}
  
.input-container{
	.react-autosuggest__container {
		position: relative;
		margin-bottom: 18px;
	}
	  
	.react-autosuggest__input {
		width: 100%;
		height: 56px;
		background: #FFFFFF;
		border: 1px solid #D8D8D8;
		border-radius: 5px;
		color: #404040;
		font-size: 14px;
		padding: 0 16px;
		:before {
			content: "\f002";
		}
	}
	  
	.react-autosuggest__input--focused {
		outline: none;
	}
	  
	//   .react-autosuggest__input--open {
	// 	// border-bottom-left-radius: 0;
	// 	// border-bottom-right-radius: 0;
	//   }
	  
	.react-autosuggest__suggestions-container {
		display: none;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;
		border: medium none;
		// border-radius: 5px !important;
		// box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
		float: left;
		font-size: 12px;
		left: 0;
		text-shadow: none;
		width: 100%;
	}
	
	.react-autosuggest__suggestions-container::-webkit-scrollbar {
		display: none;
	}
	  
	.react-autosuggest__suggestions-container--open {
		display: block;
		position: absolute;
		top: 56px;
		border: 1px solid #D8D8D8;
		background-color: #fff;
		color: #404040;
		z-index: 2;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	  
	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
	}
	  
	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 8px 15px;
	}
	  
	.react-autosuggest__suggestion--highlighted {
		background-color: #007bff;
		color: #fff;
	}
	.input-group{
		input{
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}
		.input-group-addon{
			border: 1px solid #D8D8D8;
			color: #555;
			text-align: center;
			background-color: #eee;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			border-left: 0;
			margin-left: -5px;
		}
	}
}

.modal-preview_job{
	position: fixed;
	width: 100% !important;
	height: 100% !important;
	top:0px;
	bottom: 0px;
	margin-top: 0px !important;
	// margin-top: 0px;
	.modal-content{
		border-radius: 0px !important;
		overflow:hidden !important;
		height: 100%;
		.modal-body{
			padding: 0px !important;
			overflow:hidden;
			height: 100%;
			// padding-bottom: 30px !important;
			iframe {
				width:100%;
				height:100%;
				border: 0px;
				overflow:hidden;
			}
		}
		
	}

	
}

.div-support-create-user-container .input-container div:first-child {
    padding: 0px;
}

.Select-menu-outer{
	max-height: 100px !important;
	overflow-x: auto;
}

.tabs {
	display: flex;
	border-bottom: 2px solid #ddd;
	margin-bottom: 20px;
}
.tab {
	padding: 8px 0px 6px;
	cursor: pointer;
	font-weight: bold;
	margin-right: 28px;
}
.tab.active {
	color: #333333;
	border-bottom: 2px solid #333333;
}

.input-advance-filter {
	width: 100%;
	height: 36px;
	background: #FFFFFF;
	color: #404040;
	padding: 0 16px;
	border: 2px solid #E8E8E8;
    border-radius: 6px;
    font-size: 12px;
}

#ModalVoucherAdvancedFilter{
	label{
		font-weight: bold;
	}
	.datepicker-container{
		span{
			font-weight: 400;
			font-size: 12px;
		}
	}
}